export const stringReplaceBetween = (
  str: string,
  replace = "...",
  start = 10,
  end = 10
) => {
  return str.substring(0, start) + replace + str.substring(end);
};

export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const trimWhiteSpace = (value: string) => {
  return value?.replace(/\s+/g, "");
};

export const addingHttpsPrefix = (str: string) => {
  let result = str;

  if (result?.length === 0 || !result) return result;
  if ("https://".includes(result) || "http://".includes(result)) {
    return result;
  }
  if (str.substring(0, 7) === "http://")
    result = "https://" + str.slice(7, str.length);
  else if (str.substring(0, 8) !== "https://") result = "https://" + str;
  return trimWhiteSpace(result);
};

export const IsJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const convertToUrl = (str = "") => {
  return str
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^a-zA-Z0-9. ]/g, "") // allow . | space
    ?.replace(/\s+/g, "-"); // replace space = -
};

export const convertKeyToText = (str = "") => {
  return str.replace("_", " ").toLowerCase();
};

export function convertHTMLEntity(text: string) {
  const entities = [
    ["amp", "&"],
    ["apos", "'"],
    ["#x27", "'"],
    ["#x2F", "/"],
    ["#39", "'"],
    ["#47", "/"],
    ["lt", "<"],
    ["gt", ">"],
    ["nbsp", " "],
    ["quot", '"'],
  ];

  for (let i = 0, max = entities.length; i < max; ++i)
    text = text.replace(
      new RegExp("&" + entities[i][0] + ";", "g"),
      entities[i][1]
    );

  return text;
}
