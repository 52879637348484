import config from "config";

interface ServicePortMap extends Record<string, number> {
  development: number;
  production: number;
  staging: number;
}

export const environment = config.service.env || "development";

export const komiRootDomain = config.ci.build
  ? config.ci.domain
  : config.service.domain;

export enum KomiService {
  admin = "admin",
  consumer = "consumer",
  analytics = "analytics",
  api = "api",
  shopifyStore = "shopify-store",
  talent = "talent",
}

// Port mapping
//   This is a holdover until we get the local proxy working
const getEnvPort = (portMap: ServicePortMap): number => portMap[environment];
const getApiPort = (): number =>
  getEnvPort({
    development: 3000,
    production: 3000,
    staging: 3000,
    test: 3000,
  });

const getConsumerPort = (): number =>
  getEnvPort({
    development: 8080,
    production: 8082,
    staging: 8081,
    test: 8080,
  });

const getLocalPort = (serviceName: KomiService | string) => {
  switch (serviceName) {
    case "admin":
    case KomiService.admin:
      return 4000;

    case "api":
    case KomiService.api:
      return getApiPort();

    case "talent":
    case KomiService.talent:
      return 2000;

    case "consumer":
    case KomiService.consumer:
    default:
      return getConsumerPort();
  }
};

// Environment mapping
export const envSubdomainMap: Record<string, string> = {
  development: "develop",
  staging: "staging",
  production: "",
};
export const envDomainExtensionMap: Record<string, string> = {
  development: "dev",
  staging: "staging",
  production: "",
};

export const isLocalhost = config.service.local === "true";
export const komiProtocol = isLocalhost ? "http" : "https";

/**
 * Creates an environment-specific domain for a specified service
 *
 * @param {string} komiServiceName The service name for which the URL is being created for, e.g., api
 * @returns {string} An environment-specific domain
 */
export const createEnvDomain = (
  komiServiceName: KomiService | string
): string => {
  return komiServiceName === KomiService.consumer
    ? komiRootDomain
    : `${komiServiceName}.${komiRootDomain}`;
};

const createLocalDomain = (komiServiceName: KomiService | string): string => {
  const serviceAppendage =
    komiServiceName in KomiService ? "" : `${komiServiceName}.`;
  return `${serviceAppendage}localhost:${getLocalPort(komiServiceName)}`;
};

/**
 * Creates a Komi service URL, including the environment
 *
 * @param komiServiceName The Komi service's subdomain
 * @param path The komi service's root path
 * @returns The built Komi service URL
 */
export const createKomiDomain = (
  komiServiceName: KomiService | string,
  path = ""
): string => {
  const localCompliantRoot = isLocalhost
    ? createLocalDomain(komiServiceName)
    : createEnvDomain(komiServiceName);

  return `${komiProtocol}://${localCompliantRoot}${path}`;
};

// These need to be functions so that the URLs can lazy load once window is defined
export const komiConsumerUrl = createKomiDomain(KomiService.consumer);
// SERVER_API_URL
export const komiApiUrl = createKomiDomain(KomiService.api, "/api");
