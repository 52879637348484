import url from "url";
import { getNumberFromString } from "./number";
import config from "config";

export function roomUrlFromPageUrl() {
  const match = window.location.search.match(/roomUrl=([^&]+)/i);
  return match && match[1] ? decodeURIComponent(match[1]) : null;
}

export function pageUrlFromRoomUrl(roomUrl: string) {
  return (
    window.location.href.split("?")[0] +
    (roomUrl ? `?roomUrl=${encodeURIComponent(roomUrl)}` : "")
  );
}

export function getCurrentPath(location: Location) {
  return `${location.pathname}${location.search}`;
}
export function toParams(query: any) {
  const q = query.replace(/^\??\//, "");

  return q
    .split("&")
    .reduce(
      (
        values: { [x: string]: any },
        param: { split: (arg0: string) => [any, any] }
      ) => {
        const [key, value] = param.split("=");

        values[key] = value;

        return values;
      },
      {}
    );
}

export function toQuery(params: { [x: string]: any }, delimiter = "&") {
  const keys = Object.keys(params);

  return keys.reduce((str, key, index) => {
    let query = `${str}${key}=${params[key]}`;

    if (index < keys.length - 1) {
      query += delimiter;
    }

    return query;
  }, "");
}
export const convertToUrl = (str = "") => {
  return str
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^a-zA-Z0-9 ]/g, "")
    ?.replace(/\s+/g, "-");
};

export const bandsintownParseUri = (uri: string) => {
  const { hostname, pathname = "/" } = url.parse(uri);
  if (!hostname?.includes("bandsintown.com") || !pathname) {
    return null;
  }

  const id = getNumberFromString(pathname);
  if (pathname?.includes("/a/")) {
    return { type: "artist", id };
  }
  if (pathname?.includes("/e/")) {
    return { type: "event", id };
  }
  if (pathname?.includes("/t/")) {
    return { type: "ticket", id };
  }
  if (pathname?.includes("/l/")) {
    return { type: "live_show", id };
  }
};
export const getSlugFromUrl = (url: string) => {
  return url.substring(url.lastIndexOf("/") + 1);
};

const {
  protocol,
  hostname
} = window.location;

export const profileUrl = `${protocol}//${hostname}` + (config.service.env === 'local'
? ':8080' // TODO: config
: '');
