import { Action, handleActions } from "redux-actions";
import {
  updateCloseExpandModuleType,
  dataFormCountAction,
  exportDataCaptureFormAction,
  exportFanClubAction,
  fanClubCountAction,
  getListDataFormAction,
  resetVerifyOtpValueType,
  setActiveModuleType,
  verifyBecomeTalentOtpActions,
  exportPreReleaseAction,
  getListPreReleaseAction,
  preReleaseCountAction
} from "./actions";
import { TalentState } from "./types";

const handlers = {
  // get room token
  [verifyBecomeTalentOtpActions.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      error: null,
      verifyOtp: null,
      loading: true,
    };
  },
  [verifyBecomeTalentOtpActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: null,
      loading: false,
      verifyOtp: payload,
    };
  },
  [verifyBecomeTalentOtpActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
      loading: false,
    };
  },
  // get fanClub Count
  [fanClubCountAction.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      error: null,
    };
  },
  [fanClubCountAction.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: null,
      fanClubCount: payload.count,
    };
  },
  [fanClubCountAction.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
    };
  },
  // get data form Count
  [dataFormCountAction.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      error: null,
    };
  },
  [dataFormCountAction.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: null,
      dataFormCount: payload.totalSubmissions,
    };
  },
  [dataFormCountAction.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
    };
  },
  // get data form list
  [getListDataFormAction.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      loading: true,
      error: null,
      forms: [],
    };
  },
  [getListDataFormAction.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: null,
      loading: false,
      forms: payload,
    };
  },
  [getListDataFormAction.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
      loading: false,
    };
  },

  /* Get Data from Music */
  [getListPreReleaseAction.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [getListPreReleaseAction.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: null,
      loading: false,
      preRelease: payload,
    };
  },
  [getListPreReleaseAction.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
      loading: false,
    };
  },

  [preReleaseCountAction.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      error: null,
    };
  },
  [preReleaseCountAction.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: null,
      preReleaseDownloads: payload.preReleaseDownloads,
    };
  },
  [preReleaseCountAction.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
    };
  },

  [exportPreReleaseAction.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      error: null,
    };
  },

  [exportPreReleaseAction.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
    };
  },

  // export Fan Club
  [exportFanClubAction.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      error: null,
    };
  },

  [exportFanClubAction.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
    };
  },
  // export Data Capture
  [exportDataCaptureFormAction.REQUEST]: (state: TalentState): TalentState => {
    return {
      ...state,
      error: null,
    };
  },

  [exportDataCaptureFormAction.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      error: payload,
    };
  },
  [resetVerifyOtpValueType]: (state: TalentState): TalentState => {
    return {
      ...state,
      error: null,
      verifyOtp: null,
    };
  },
  [setActiveModuleType]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      activeModule: payload,
    };
  },
  [updateCloseExpandModuleType]: (
    state: TalentState,
    { payload }: Action<any>
  ): TalentState => {
    return {
      ...state,
      closeExpand: payload,
    };
  },
};

const defaultState: TalentState = {
  loading: false,
  error: null,
  verifyOtp: null,
  fanClubCount: 0,
  dataFormCount: 0,
  activeModule: undefined,
  forms: [],
  closeExpand: [],
  preRelease: [],
};

export const talentReducer = handleActions<TalentState>(handlers, defaultState);
