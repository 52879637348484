import { Api } from "../api";
import { EXPERIENCE_TYPE } from "redux/Experience/types";
import Experience1To1Service from "./Experience1To1Service";
import ExperienceLiveClassService from "./ExperienceLiveClassService";
import ExperienceService from "./ExperienceService";
import ExclusiveContentService from "./ExclusiveContentService";
import ExperienceCourseService from "./ExperienceCourseService";
import ExperienceInteractiveLiveClassService from "./ExperienceInteractiveLiveClassService";
import ExperienceBundleSerivce from "./ExperienceBundleService";

export default class ExperienceFactorService {
  static createService(api: Api, type: EXPERIENCE_TYPE): ExperienceService {
    switch (type) {
      case EXPERIENCE_TYPE.ONE_TO_ONE:
        return new Experience1To1Service(api);
      case EXPERIENCE_TYPE.LIVE_CLASS:
        return new ExperienceLiveClassService(api);
      case EXPERIENCE_TYPE.EXCLUSIVE_CONTENT:
        return new ExclusiveContentService(api);
      case EXPERIENCE_TYPE.COURSE:
        return new ExperienceCourseService(api);
      case EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS:
        return new ExperienceInteractiveLiveClassService(api);
      case EXPERIENCE_TYPE.BUNDLE:
        return new ExperienceBundleSerivce(api);
      default:
        return new Experience1To1Service(api);
    }
  }
}
