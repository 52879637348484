import { Middleware, AnyAction, Dispatch } from "redux";
import { FAILURE, getActionBaseName, SUCCESS } from "utils/createAction";
import { REQUEST_API_SUCCESS, REQUEST_API_ERROR } from "./LogRequest/actions";
import { LogRequestState } from "./LogRequest/types";

export default class CustomMiddleware {
  public static ErrorRequestHandling: Middleware =
    () =>
    (next: Dispatch) =>
    (action: AnyAction): any => {
      if (action?.type?.includes(FAILURE)) {
        /*
        next({
          type: REQUEST_API_ERROR,
          payload: {
            ...action.payload,
            type: `REQUEST API ERROR: ${getActionBaseName(action.type)}`,
          },
        } as { type: string; payload: LogRequestState });
        */
        next(action);
      } else next(action);
    };

  public static SuccessRequestHandling: Middleware =
    () =>
    (next: Dispatch) =>
    (action: AnyAction): any => {
      if (action?.type?.includes(SUCCESS)) {
        /*
        next({
          type: REQUEST_API_SUCCESS,
          payload: {
            ...action.payload,
            type: `REQUSET API SUCCESS: ${getActionBaseName(action.type)}`,
          },
        } as { type: string; payload: LogRequestState });
        */
        next(action);
      } else next(action);
    };
}
