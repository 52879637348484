import { RootState } from "./../rootReducer";

export const selectPayoutError = (rootState: RootState) =>
  rootState.payoutState?.error;

export const selectPayoutIsLoading = (rootState: RootState) =>
  rootState.payoutState?.isLoading;

export const selectPayoutItem = (rootState: RootState) =>
  rootState.payoutState?.item;

export const selectPayoutSchedule = (rootState: RootState) =>
  rootState.payoutState?.payoutSchedule;

export const selectPayoutScheduleError = (rootState: RootState) =>
  rootState.payoutState?.error;

export const selectPayoutScheduleLoading = (rootState: RootState) =>
  rootState.payoutState?.isLoading;
