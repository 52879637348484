import { Response, Pagination } from "./../redux/Common/types";
import { Api } from "./api";
import { Category } from "redux/Category/types";

interface ICategoryService {
  getCategories(): Promise<Response<Pagination<Category[] | null>>>;
}

export default class CategoryService implements ICategoryService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getCategories = (): Promise<Response<Pagination<Category[] | null>>> => {
    return this.api.getCategories();
  };
}
