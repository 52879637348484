export const ExchangeRateDefault = {
  GBPExchangeRate: "1",
  decimalDigits: 2,
  localCurrency: "USD",
  symbol: "$",
};
export const ExchangeRateDefaultAnalytics = {
  GBPExchangeRate: "1",
  decimalDigits: 2,
  localCurrency: "GBP",
  symbol: "£",
};
