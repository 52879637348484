import Layout from "antd/lib/layout";
import { Content, Header } from "antd/lib/layout/layout";
import classnames from "classnames";
import Loading from "components/Loading";
import PreviewLayoutHeader from "components/PreviewLayoutHeader";
import { MINUTES } from "constants/date";

import { useWindowSize } from "hooks/useWindowSize";
import moment from "moment";
import React, {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTypedSelector } from "redux/rootReducer";
import {
  selectCollaborator,
  selectIsCollaborator,
  selectTalentProfile,
  selectUserData,
} from "redux/User/selector";
import { firebaseService } from "services";
import "./PreviewLayout.scss";
import { useHistory } from "react-router-dom";
import PreviewLayoutBanner from "./PreviewLayoutBanner";

interface IProps {
  children?: ReactNode;
  className?: string;
}

const PreviewLayout: React.FC<IProps> = ({ children, className = "" }) => {
  const { height } = useWindowSize();
  const history = useHistory();
  const talentProfile = useTypedSelector(selectTalentProfile);
  const isCollaborator = useTypedSelector(selectIsCollaborator);
  const collaborator = useTypedSelector(selectCollaborator);
  const user = useTypedSelector(selectUserData);

  const attributeKey = useMemo(
    () => (talentProfile?.id ? `talent/${talentProfile?.id}` : null),
    [talentProfile?.id]
  );

  // Refresh the page after 15 minutes
  useEffect(() => {
    localStorage.setItem(`EXPIRED_TIME`, `${Date.now() + MINUTES["15"]}`);

    const interval = setInterval(() => {
      const expiredTime = localStorage.getItem(`EXPIRED_TIME`);
      if (expiredTime && parseInt(expiredTime, 10) < Date.now()) {
        window.location.reload();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Write last connection times to Firebase
  const updateAttributesData = useCallback(
    async (data = {}) => {
      if (attributeKey) {
        try {
          const currentAttrs =
            (await firebaseService.getOnce(attributeKey)).val() || {};

          let newAttrs = {
            ...currentAttrs,
            ...data,
            [`${collaborator?.id ?? user?.id}`]: {
              lastConnection: moment().toISOString(),
            },
          };
          if (currentAttrs?.startedAt) {
            newAttrs = {
              ...currentAttrs,
              talentLastConnection: moment().toISOString(),
            };
          }
          await firebaseService.write(attributeKey, newAttrs);
        } catch (error) {
          console.error("error: ", error);
        }
      }
    },
    [attributeKey, collaborator?.id, user?.id, isCollaborator]
  );

  // Perform the above attributes writing every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      updateAttributesData();
    }, 5000);
    return () => clearInterval(interval);
  }, [updateAttributesData]);

  const layoutClass = classnames({
    "preview-layout": true,
    [className]: true,
  });

  const onInvalidPage = () =>
    ["/account-setting", "/data-export", "/analytics"].includes(
      history.location.pathname
    ); // TODO: "/data-export" can be removed after DCA is deployed

  const [bannerDisplayed, setBannerDisplayed] = useState(true);

  const pageContentClass = classnames({
    "preview-layout__page-content": true,
    "preview-layout__page-content--trial-banner":
      bannerDisplayed && !onInvalidPage(),
    "preview-layout__page-content--banner-dismissed": !bannerDisplayed,
  });

  const url = history.location.pathname;

  // TODO: update PreviewLayoutBanner prop for isSubscribed with GET request on /subscriptions/dca
  const isSubscribed = true;

  // TODO: update PreviewLayoutBanner prop for isExceedingContacts with logic to check if user has exceeded contacts allowance
  const isExceedingContacts = true;

  return (
    <>
      <Layout
        className={layoutClass}
        style={{
          minHeight: `${height}px`,
        }}
      >
        <Header className="preview-layout__header fluid-container">
          <PreviewLayoutHeader />
        </Header>
        <Content>
          <Layout className="preview-layout__content">
            <div className="fluid-container d--flex flex--1 flex__direction--column">
              {!onInvalidPage() && (
                <PreviewLayoutBanner
                  url={url}
                  setBannerDisplayed={setBannerDisplayed}
                  isSubscribed={isSubscribed}
                  isExceedingContacts={isExceedingContacts}
                />
              )}
              <Content className={pageContentClass}>
                <Suspense fallback={<Loading />}>{children}</Suspense>
              </Content>
            </div>
          </Layout>
        </Content>
      </Layout>
    </>
  );
};

export default PreviewLayout;
