import React from "react";
import { Row, Spin } from "antd";
import "./Loading.scss";
import classNames from "classnames";

interface IProps {
  size?: "large" | "default" | "small";
  showFull?: boolean;
  className?: string;
  blur?: boolean;
}

const Loading = ({
  size = "default",
  blur,
  showFull = false,
  className = "",
}: IProps) => {
  return (
    <Row
      className={classNames({
        loading: true,
        "loading--full": showFull,
        "loading--blur": blur,
        [className]: true,
      })}
      justify="center"
      align="middle"
    >
      <Spin size={size} />
    </Row>
  );
};

export default Loading;
