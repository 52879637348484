import React from "react";
import "./SubscriptionBanner.scss";
import notification from "utils/notification";

import { useHistory } from "react-router";
import { getSubscriptionOverviewData } from "utils/subscription";
import { HeaderMessage } from "@komi-app/components";
import { SEGMENT_EVENTS } from "constants/segment";
import { useUserAgentContext } from "context/user-agent";
import { useAnalytics } from "hooks/useAnalytics";
import { OverviewStatus } from "types/sales";
import { useTypedSelector } from "redux/rootReducer";
import {
  selectCollaborator,
  selectProfileBuilderSubscription,
  selectUserData,
} from "redux/User/selector";

export type InlineMessageType = "info" | "warning" | "error";

export interface SubscriptionBannerProps {
  messageText: string;
  level: InlineMessageType;
  closeable: boolean;
  setBannerDisplayed: (value: boolean) => void;
}

const SubscriptionBanner = ({
  messageText,
  level,
  closeable,
  setBannerDisplayed,
}: SubscriptionBannerProps) => {
  const history = useHistory();
  const uri =
    process.env.REACT_APP_TRIAL_BANNER_CTA ||
    "/account-setting/subscription/select-plan";
  const { sendSegmentEvent } = useAnalytics();
  const ua = useUserAgentContext();
  const subscription = useTypedSelector(selectProfileBuilderSubscription);

  // check if user is owner of talentProfile
  const talent = useTypedSelector(selectUserData);
  const collaborator = useTypedSelector(selectCollaborator);
  const isOwner =
    talent && collaborator && talent?.createdById === collaborator?.id;

  const onClick = () => {
    const { trialRemainingDays, status } =
      getSubscriptionOverviewData(subscription);
    sendSegmentEvent(SEGMENT_EVENTS.CLICK_SUBSCRIBE, {
      "Button placement": "Profile editor",
      "Free trial days remaining": trialRemainingDays,
      "Operating System": ua.os.name,
      "Profile Creation Method": "Self Sign Up",
      "Subscription status": status
        ? {
            [OverviewStatus.ACTIVE]: "Is subscribed" as const,
            [OverviewStatus.FREE_TRIAL]: "On trial" as const,
            [OverviewStatus.FREE_TRIAL_EXPIRED]: "Trial expired" as const,
            [OverviewStatus.CANCELLED]: "Cancelled" as const,
          }[status]
        : undefined,
    });
    isOwner
      ? history.push(uri)
      : notification.error({
          message:
            " Sorry, subscriptions can only be managed by the account holder",
        });
  };

  return (
    <div>
      <div className="ssx-subscription-banner__info">
        <HeaderMessage
          level={level}
          messageText={messageText}
          buttonText={"Subscribe"}
          onButtonClick={onClick}
          show={true}
          closeable={closeable}
          onClose={() => {
            setBannerDisplayed(false);
          }}
        />
      </div>
    </div>
  );
};

export default SubscriptionBanner;
