import { ReactComponent as AddBlue } from "icons/add-fill.svg";
import { ReactComponent as AddBlueFullWidth } from "icons/add-fill-full-width.svg";
import { ReactComponent as AddWhite } from "icons/add-white.svg";
import { ReactComponent as ArrowDownWhite } from "icons/arrow-down-white.svg";
import { ReactComponent as Analytic } from "icons/analytic.svg";
import { ReactComponent as ArrowDown } from "icons/arrow/down.svg";
import { ReactComponent as ArrowUpInactive } from "icons/arrow/up-inactive.svg";
import { ReactComponent as ArrowUp } from "icons/arrow/up.svg";
import { ReactComponent as ArrowLeft } from "icons/arrow/sLeft.svg";
import { ReactComponent as ArrowRight } from "icons/arrow/sRight.svg";
import { ReactComponent as CloseBlack } from "icons/close-fill.svg";
import { ReactComponent as CloseOutline } from "icons/close-outline.svg";
import { ReactComponent as Close } from "icons/close.svg";
import { ReactComponent as View } from "icons/view.svg";
import { ReactComponent as Add } from "icons/dark/add.svg";
import { ReactComponent as CloseWhite } from "icons/dark/close-white.svg";
import { ReactComponent as Drag } from "icons/dark/drag.svg";
import { ReactComponent as Error } from "icons/dark/error.svg";
import { ReactComponent as RadioActive } from "icons/dark/radio-active.svg";
import { ReactComponent as RadioActiveFull } from "icons/dark/radio-active-full.svg";
import { ReactComponent as RadioInActive } from "icons/dark/radio-inactive.svg";
import { ReactComponent as Success } from "icons/dark/success.svg";
import { ReactComponent as Warning } from "icons/dark/warning.svg";
import { ReactComponent as YoutubeVideo } from "icons/dark/youtube-video.svg";
import { ReactComponent as DragHorizontal } from "icons/drag-horizontal.svg";
import { ReactComponent as DragVerticalInactive } from "icons/drag-vertical-inactive.svg";
import { ReactComponent as DragVertical } from "icons/drag-vertical.svg";
import { ReactComponent as EmptyModule } from "icons/empty-module.svg";
import { ReactComponent as Komi } from "icons/komi.svg";
import { ReactComponent as Category } from "icons/modules/category.svg";
import { ReactComponent as Link } from "icons/modules/link.svg";
import { ReactComponent as Music } from "icons/modules/music.svg";
import { ReactComponent as Shop } from "icons/modules/shop.svg";
import { ReactComponent as VideoPlayer } from "icons/modules/video-player.svg";
import { ReactComponent as More } from "icons/more.svg";
import { ReactComponent as NotiError } from "icons/notification/error.svg";
import { ReactComponent as NotiSuccess } from "icons/notification/success.svg";
import { ReactComponent as NotiWarning } from "icons/notification/warning.svg";
import { ReactComponent as ShareLink } from "icons/share-link.svg";
import { ReactComponent as Share } from "icons/share.svg";
import { ReactComponent as AmazonMusic } from "icons/socials/amazon-music.svg";
import { ReactComponent as AppleMusic } from "icons/socials/apple-music.svg";
import { ReactComponent as Deezer } from "icons/socials/deezer.svg";
import { ReactComponent as Facebook } from "icons/socials/facebook.svg";
import { ReactComponent as Discord } from "icons/socials/discord.svg";
import { ReactComponent as Email } from "icons/socials/email.svg";
import { ReactComponent as Bandsintown } from "icons/socials/bandsintown.svg";
import { ReactComponent as Instagram } from "icons/socials/instagram.svg";
import { ReactComponent as Linkedin } from "icons/socials/linkedin.svg";
import { ReactComponent as Medium } from "icons/socials/medium.svg";
import { ReactComponent as Pinterest } from "icons/socials/pinterest.svg";
import { ReactComponent as Reddit } from "icons/socials/reddit.svg";
import { ReactComponent as Snapchat } from "icons/socials/snapchat.svg";
import { ReactComponent as SoundCloud } from "icons/socials/soundcloud_01.svg";
import { ReactComponent as SoundCloudMusic } from "icons/socials/soundcloud.svg";
import { ReactComponent as Spotify } from "icons/socials/spotify.svg";
import { ReactComponent as Tiktok } from "icons/socials/tiktok.svg";
import { ReactComponent as Twitch } from "icons/socials/twitch.svg";
import { ReactComponent as Twitter } from "icons/socials/twitter.svg";
import { ReactComponent as Website } from "icons/socials/website.svg";
import { ReactComponent as YoutubeMusic } from "icons/socials/youtube-music.svg";
import { ReactComponent as Youtube } from "icons/socials/youtube.svg";
import { ReactComponent as Amazone } from "icons/socials/amazone.svg";
import { ReactComponent as Anghami } from "icons/socials/anghami.svg";
import { ReactComponent as Audiomack } from "icons/socials/audiomack.svg";
import { ReactComponent as Bandcamp } from "icons/socials/bandcamp.svg";
import { ReactComponent as Beatport } from "icons/socials/beatport.svg";
import { ReactComponent as Gaana } from "icons/socials/gaana.svg";
import { ReactComponent as Hmv } from "icons/socials/hmv.svg";
import { ReactComponent as Iheartradio } from "icons/socials/iheartradio.svg";
import { ReactComponent as ItunesStore } from "icons/socials/itunes-store.svg";
import { ReactComponent as Napster } from "icons/socials/napster.svg";
import { ReactComponent as Pandora } from "icons/socials/pandora.svg";
import { ReactComponent as Qobuz } from "icons/socials/qobuz.svg";
import { ReactComponent as RecordStore } from "icons/socials/record-store.svg";
import { ReactComponent as Siriusxm } from "icons/socials/siriusxm.svg";
import { ReactComponent as Target } from "icons/socials/target.svg";
import { ReactComponent as Tidal } from "icons/socials/tidal.svg";
import { ReactComponent as UrbanOutfitters } from "icons/socials/urban-outfitters.svg";
import { ReactComponent as Walmart } from "icons/socials/walmart.svg";
import { ReactComponent as TwitterPreview } from "icons/socials-preview/twitter.svg";
import { ReactComponent as InstagramPreview } from "icons/socials-preview/instagram.svg";
import { ReactComponent as FacebookPreview } from "icons/socials-preview/facebook.svg";
import { ReactComponent as PiccadillyRecords } from "icons/socials/piccadilly-records.svg";
import { ReactComponent as BanquetRecords } from "icons/socials/banquet-records.svg";
import { ReactComponent as Bleep } from "icons/socials/bleep.svg";
import { ReactComponent as DeejayDe } from "icons/socials/deejay-de.svg";
import { ReactComponent as Juno } from "icons/socials/juno.svg";
import { ReactComponent as RoughTrade } from "icons/socials/rough-trade.svg";
import { ReactComponent as Vinilo } from "icons/socials/vinilo.svg";
import { ReactComponent as Resident } from "icons/socials/resident.svg";
import { ReactComponent as Redeye } from "icons/socials/redeye.svg";
import { ReactComponent as NormanRecords } from "icons/socials/norman-records.svg";
import { ReactComponent as Drift } from "icons/socials/drift.svg";
import { ReactComponent as CrashRecords } from "icons/socials/crash-records.svg";
import { ReactComponent as BearTreeRecords } from "icons/socials/bear-tree-records.svg";

import { ReactComponent as Mobile } from "icons/mobile.svg";
import { ReactComponent as Tablet } from "icons/tablet.svg";
import { ReactComponent as Desktop } from "icons/desktop.svg";
import { ReactComponent as Team } from "icons/team.svg";
import { ReactComponent as AudioEmpty } from "icons/dark/audio-empty.svg";
import { ReactComponent as Connection } from "icons/dark/connection.svg";
import { ReactComponent as SettingsWhite } from "icons/dark/settings-white.svg";
import { ReactComponent as Speaker } from "icons/dark/speaker.svg";
import { ReactComponent as SpeakerActive } from "icons/dark/speaker-active.svg";
import { ReactComponent as SmallCamera } from "icons/dark/small-camera.svg";
import { ReactComponent as SmallConnection } from "icons/dark/small-connection.svg";
import { ReactComponent as SmallMicrophone } from "icons/dark/small-microphone.svg";
import { ReactComponent as SmallSound } from "icons/dark/small-sound.svg";
import { ReactComponent as SmallSuccess } from "icons/dark/small-success.svg";
import { ReactComponent as Podcast } from "icons/dark/podcast.svg";
import { ReactComponent as ApplePodcast } from "icons/dark/apple-podcast.svg";
import { ReactComponent as Audible } from "icons/dark/audible.svg";
import { ReactComponent as Overcast } from "icons/dark/overcast.svg";
import { ReactComponent as Stitcher } from "icons/dark/stitcher.svg";
import { ReactComponent as ReportIcon } from "icons/report.svg";
import { ReactComponent as PDFFileIcon } from "icons/files/pdf.svg";
import { ReactComponent as SmartLink } from "icons/dark/smart-link.svg";
import { ReactComponent as PreRelease } from "icons/dark/pre-release.svg";
import { ReactComponent as CustomPreRelease } from "icons/dark/pre-release-custom.svg";
import { ReactComponent as FanClub } from "icons/dark/fan-club.svg";
import { ReactComponent as Question } from "icons/dark/question.svg";
import { ReactComponent as Search } from "icons/search.svg";
import { ReactComponent as Delete } from "icons/dark/trash.svg";
import { ReactComponent as ArrowDownBlue } from "icons/dark/arrow-down-blue.svg";
import { ReactComponent as ArrowUpBlue } from "icons/dark/arrow-up-blue.svg";
import { ReactComponent as ArrowDownCircleBlue } from "icons/arrow-down-blue.svg";
import { ReactComponent as ArrowUpCircleBlue } from "icons/arrow-up-blue.svg";
import { ReactComponent as OverlayDark } from "icons/overlay-dark.svg";
import { ReactComponent as OverlayLight } from "icons/overlay-light.svg";
import { ReactComponent as ThemeCustom } from "icons/theme-custom.svg";
import { ReactComponent as Sound } from "icons/dark/sound.svg";
import { ReactComponent as Shopify } from "icons/dark/shopify.svg";
import { ReactComponent as Bandintown } from "icons/dark/bandintown.svg";
import { ReactComponent as Seated } from "icons/dark/seated.svg";
import { ReactComponent as EmptyProduct } from "icons/dark/empty-product.svg";
import { ReactComponent as SearchGrey } from "icons/dark/search-grey.svg";
import { ReactComponent as ShopifyNoProduct } from "icons/shopify-no-product.svg";
import { ReactComponent as ShoppingBag } from "icons/shopping-bag.svg";
import { ReactComponent as ShoppingBagGrey } from "icons/shopping-bag-grey.svg";
import { ReactComponent as ShoppingBags } from "icons/shopping-bags.svg";
import { ReactComponent as ShoppingEmpty } from "icons/dark/shopping-empty.svg";
import { ReactComponent as Info } from "icons/dark/info.svg";
import { ReactComponent as TextImg } from "icons/text.svg";
import { ReactComponent as Image } from "icons/image.svg";
import { ReactComponent as SwitchOn } from "icons/switch-on.svg";
import { ReactComponent as SwitchOff } from "icons/switch-off.svg";
import { ReactComponent as Back } from "icons/dark/back.svg";
import { ReactComponent as DesktopIllustration } from "icons/dark/desktop-illustration.svg";
import { ReactComponent as DesktopIllustration2 } from "icons/dark/desktop-illustration-2.svg";
import { ReactComponent as Ticket } from "icons/ticket.svg";
import { ReactComponent as DataForm } from "icons/data-form.svg";
import { ReactComponent as DataCaptureLayoutLeft } from "icons/data-capture-form/data-capture-img-left.svg";
import { ReactComponent as DataCaptureLayoutCenter } from "icons/data-capture-form/data-capture-img-center.svg";
import { ReactComponent as CaptureCalendar } from "icons/data-capture-form/calendar.svg";
import { ReactComponent as CaptureContactInfo } from "icons/data-capture-form/contact-info.svg";
import { ReactComponent as CaptureEmail } from "icons/data-capture-form/email.svg";
import { ReactComponent as CaptureGlobal } from "icons/data-capture-form/global.svg";
import { ReactComponent as CaptureLegals } from "icons/data-capture-form/legals.svg";
import { ReactComponent as CaptureMutilChoice } from "icons/data-capture-form/mutil-choice.svg";
import { ReactComponent as CaptureParagraph } from "icons/data-capture-form/paragraph.svg";
import { ReactComponent as CapturePhone } from "icons/data-capture-form/phone.svg";
import { ReactComponent as CaptureSelection } from "icons/data-capture-form/selection.svg";
import { ReactComponent as CaptureSingleChoice } from "icons/data-capture-form/single-choice.svg";
import { ReactComponent as CaptureTextField } from "icons/data-capture-form/text-field.svg";
import { ReactComponent as CaptureRadio } from "icons/data-capture-form/radio.svg";
import { ReactComponent as CaptureCheckbox } from "icons/data-capture-form/checkbox.svg";
import { ReactComponent as CaptureCheckboxChecked } from "icons/data-capture-form/checkbox-checked.svg";
import { ReactComponent as Darken } from "icons/darken.svg";
import { ReactComponent as Lighten } from "icons/lighten.svg";
import { ReactComponent as SearchDisabled } from "icons/search-disabled.svg";
import { ReactComponent as PlusIcon } from "icons/dark/plus.svg";
import { ReactComponent as SearchActive } from "icons/search-active.svg";
import { ReactComponent as KomiLogoFooter } from "icons/komi-logo-footer.svg";
import { ReactComponent as Grouping } from "icons/grouping.svg";
import { ReactComponent as UnGrouping } from "icons/un-grouping.svg";
import { ReactComponent as MoveTo } from "icons/move-to.svg";
import { ReactComponent as UnGroup } from "icons/ungroup.svg";
import { ReactComponent as UnCheckGroup } from "icons/un-check-group.svg";
import { ReactComponent as ShopMyShelf } from "icons/shop-my-shelf.svg";
import { ReactComponent as ShopList } from "icons/shop-list.svg";
import { ReactComponent as NoPhoto } from "icons/no-photo.svg";
import { ReactComponent as YoutubeCollection } from "icons/youtube-collection.svg";
import { ReactComponent as YoutubeIndividual } from "icons/youtube-individual.svg";
import { ReactComponent as InputPlus } from "icons/input-plus.svg";
import { ReactComponent as InputMinus } from "icons/input-minus.svg";
import { ReactComponent as SMInfo } from "icons/sm-info.svg";
import { ReactComponent as SMInfoFilled } from "icons/sm-info-filled.svg";
import { ReactComponent as Rotation } from "icons/rotation.svg";
import { ReactComponent as Microphone } from "icons/microphone.svg";
import { ReactComponent as CopyLink } from "icons/copy-link.svg";
import { ReactComponent as AddGrey } from "icons/dark/add-grey.svg";
import { ReactComponent as OpenEye } from "icons/open-eye.svg";
import { ReactComponent as ClosedEye } from "icons/closed-eye.svg";
import { ReactComponent as OpenEyeInactive } from "icons/open-eye-inactive.svg";
import { ReactComponent as ClosedEyeInactive } from "icons/closed-eye-inactive.svg";
import { ReactComponent as MasterCard } from "icons/visa-master-card.svg";

export const icons = {
  mastercard: MasterCard,
  "amazon-music": AmazonMusic,
  "apple-music": AppleMusic,
  deezer: Deezer,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  "sound-cloud": SoundCloud,
  "sound-cloud-music": SoundCloudMusic,
  spotify: Spotify,
  twitter: Twitter,
  website: Website,
  "youtube-music": YoutubeMusic,
  youtube: Youtube,
  medium: Medium,
  pinterest: Pinterest,
  reddit: Reddit,
  tiktok: Tiktok,
  twitch: Twitch,
  close: Close,
  success: Success,
  error: Error,
  warning: Warning,
  add: Add,
  "add-blue": AddBlue,
  "add-blue-full-width": AddBlueFullWidth,
  "add-white": AddWhite,
  "arrow-down-white": ArrowDownWhite,
  "close-white": CloseWhite,
  "close-black": CloseBlack,
  "close-outline": CloseOutline,
  "empty-module": EmptyModule,
  shop: Shop,
  link: Link,
  "video-player": VideoPlayer,
  category: Category,
  music: Music,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-down": ArrowDown,
  "arrow-up": ArrowUp,
  "arrow-up-inactive": ArrowUpInactive,
  "arrow-up-circle-blue": ArrowUpCircleBlue,
  "arrow-down-circle-blue": ArrowDownCircleBlue,
  more: More,
  "drag-vertical": DragVertical,
  "drag-vertical-inactive": DragVerticalInactive,
  "drag-horizontal": DragHorizontal,
  komi: Komi,
  share: Share,
  "share-link": ShareLink,
  drag: Drag,
  "noti-success": NotiSuccess,
  "noti-warning": NotiWarning,
  "noti-error": NotiError,
  "youtube-video": YoutubeVideo,
  team: Team,
  snapchat: Snapchat,
  analytic: Analytic,
  "radio-active": RadioActive,
  "radio-active-full": RadioActiveFull,
  "radio-inactive": RadioInActive,
  "audio-empty": AudioEmpty,
  "settings-white": SettingsWhite,
  connection: Connection,
  speaker: Speaker,
  "speaker-active": SpeakerActive,
  "small-camera": SmallCamera,
  "small-connection": SmallConnection,
  "small-microphone": SmallMicrophone,
  "small-sound": SmallSound,
  "small-success": SmallSuccess,
  report: ReportIcon,
  "file-pdf": PDFFileIcon,
  podcast: Podcast,
  "apple-podcast": ApplePodcast,
  audible: Audible,
  overcast: Overcast,
  stitcher: Stitcher,
  "smart-link": SmartLink,
  "pre-release": PreRelease,
  "pre-release-custom": CustomPreRelease,
  "fan-club": FanClub,
  question: Question,
  search: Search,
  trash: Delete,
  "arrow-down-blue": ArrowDownBlue,
  "arrow-up-blue": ArrowUpBlue,
  amazone: Amazone,
  anghami: Anghami,
  audiomack: Audiomack,
  bandcamp: Bandcamp,
  beatport: Beatport,
  gaana: Gaana,
  hmv: Hmv,
  iheartradio: Iheartradio,
  "itunes-store": ItunesStore,
  napster: Napster,
  pandora: Pandora,
  qobuz: Qobuz,
  "record-store": RecordStore,
  siriusxm: Siriusxm,
  target: Target,
  tidal: Tidal,
  "urban-outfitters": UrbanOutfitters,
  walmart: Walmart,
  "overlay-dark": OverlayDark,
  "overlay-light": OverlayLight,
  "theme-custom": ThemeCustom,
  "instagram-preview": InstagramPreview,
  "facebook-preview": FacebookPreview,
  "twitter-preview": TwitterPreview,
  sound: Sound,
  shopify: Shopify,
  bandintown: Bandintown,
  seated: Seated,
  "empty-product": EmptyProduct,
  "search-grey": SearchGrey,
  "shopify-no-product": ShopifyNoProduct,
  "shopping-bag": ShoppingBag,
  "shopping-bag-grey": ShoppingBagGrey,
  "shopping-bags": ShoppingBags,
  "shopping-empty": ShoppingEmpty,
  info: Info,
  view: View,
  "text-img": TextImg,
  image: Image,
  mobile: Mobile,
  tablet: Tablet,
  desktop: Desktop,
  back: Back,
  "desktop-illustration": DesktopIllustration,
  "desktop-illustration-2": DesktopIllustration2,
  "switch-on": SwitchOn,
  "switch-off": SwitchOff,
  ticket: Ticket,
  "data-form": DataForm,
  "data-capture-layout-left": DataCaptureLayoutLeft,
  "data-capture-layout-center": DataCaptureLayoutCenter,
  "capture-calendar": CaptureCalendar,
  "capture-contact-info": CaptureContactInfo,
  "capture-email": CaptureEmail,
  "capture-global": CaptureGlobal,
  "capture-legals": CaptureLegals,
  "capture-mutil-choice": CaptureMutilChoice,
  "capture-paragraph": CaptureParagraph,
  "capture-phone": CapturePhone,
  "capture-selection": CaptureSelection,
  "capture-single-choice": CaptureSingleChoice,
  "capture-text-field": CaptureTextField,
  "capture-radio": CaptureRadio,
  "capture-checkbox": CaptureCheckbox,
  "capture-checkbox-checked": CaptureCheckboxChecked,
  darken: Darken,
  lighten: Lighten,
  "search-disabled": SearchDisabled,
  plus: PlusIcon,
  "search-active": SearchActive,
  "komi-logo-footer": KomiLogoFooter,
  "piccadilly-records": PiccadillyRecords,
  "banquet-records": BanquetRecords,
  bleep: Bleep,
  "deejay-de": DeejayDe,
  juno: Juno,
  "rough-trade": RoughTrade,
  vinilo: Vinilo,
  resident: Resident,
  redeye: Redeye,
  "norman-records": NormanRecords,
  drift: Drift,
  "crash-records": CrashRecords,
  "bear-tree-records": BearTreeRecords,
  grouping: Grouping,
  "un-grouping": UnGrouping,
  "move-to": MoveTo,
  ungroup: UnGroup,
  "un-check-group": UnCheckGroup,
  "shop-my-shelf": ShopMyShelf,
  "shop-list": ShopList,
  "no-photo": NoPhoto,
  "youtube-collection": YoutubeCollection,
  "youtube-individual": YoutubeIndividual,
  "input-plus": InputPlus,
  "input-minus": InputMinus,
  discord: Discord,
  email: Email,
  bandsintown: Bandsintown,
  "sm-info": SMInfo,
  "sm-info-filled": SMInfoFilled,
  rotation: Rotation,
  microphone: Microphone,
  "copy-link": CopyLink,
  "add-grey": AddGrey,
  "open-eye": OpenEye,
  "closed-eye": ClosedEye,
  "open-eye-inactive": OpenEyeInactive,
  "closed-eye-inactive": ClosedEyeInactive,
};

export type IconName = keyof typeof icons;
