import {
  Contents1To1Request,
  Experience,
  EXPERIENCE_TYPE,
  UpdateExperience1To1Request,
  GetExperienceByIdRequest,
} from "redux/Experience/types";
import { ListQueryParams, Response } from "../../redux/Common/types";
import ExperienceService from "./ExperienceService";

export default class ExperienceCourseService extends ExperienceService {
  getExperiences = (
    userId: string,
    params: ListQueryParams
  ): Promise<Response<Experience[]>> => {
    return this.api.getExperiences(EXPERIENCE_TYPE.COURSE, userId, params);
  };

  getExperienceById = (
    payload: GetExperienceByIdRequest
  ): Promise<Response<Experience>> => {
    return this.api.getExperienceById(payload);
  };

  createExperience = (
    payload: Contents1To1Request
  ): Promise<Response<Experience>> => {
    return this.api.createCourse(payload);
  };

  updateExperience = (
    payload: UpdateExperience1To1Request
  ): Promise<Response<Experience>> => {
    return this.api.updateCourse(payload);
  };

  publishExperience = (payload: string): Promise<Response<any>> => {
    return this.api.publishCourse(payload);
  };
}
