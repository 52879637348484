import { Booking } from "redux/Booking/types";
import { GetSpecialRequests, SpecialRequest } from "redux/SpecialRequest/types";
import { Pagination, Response } from "../redux/Common/types";
import { Api } from "./api";

export interface ISpecialRequestService {
  getList(
    params: GetSpecialRequests
  ): Promise<Response<Pagination<SpecialRequest[]>>>;
  getById(id: string): Promise<Response<SpecialRequest>>;
  accept(bookingId: Booking["id"]): Promise<Response<SpecialRequest>>;
  reject(payload: {
    bookingId: number;
    notes: string;
  }): Promise<Response<SpecialRequest>>;
}
export default class SpecialRequestService implements ISpecialRequestService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getList = (
    params: GetSpecialRequests
  ): Promise<Response<Pagination<SpecialRequest[]>>> => {
    return this.api.getListSpecialRequests(params);
  };

  getById = (id: string): Promise<Response<SpecialRequest>> => {
    return this.api.getSpecialRequestById(id);
  };

  accept = (bookingId: Booking["id"]): Promise<Response<SpecialRequest>> => {
    return this.api.acceptBooking(bookingId);
  };

  complete = (bookingId: Booking["id"]): Promise<Response<SpecialRequest>> => {
    return this.api.completeBooking({ bookingId, notes: "" });
  };

  reject = (payload: {
    bookingId: Booking["id"];
    notes: string;
  }): Promise<Response<SpecialRequest>> => {
    return this.api.rejectBooking({
      bookingId: payload.bookingId,
      notes: payload.notes,
    });
  };
}
