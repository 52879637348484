import { Skeleton, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import "./ImageSkeleton.scss";
import { getAvatarName } from "utils/experience";

interface IProps {
  src: string;
  imageClassName?: string;
  skeletonClassName?: string;
  shape?: "square" | "circle";
  size?: number | "small" | "large" | "default" | undefined;
  imgWidth?: string | number;
  name?: string;
}

const ImageSkeleton = ({
  src,
  imageClassName = "",
  skeletonClassName = "",
  shape = "square",
  size = "large",
  imgWidth = "24",
  name,
}: IProps) => {
  const [isLoaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);
  const imgEl: any = React.useRef(null);

  useEffect(() => {
    if (imgEl.current?.complete) {
      setLoaded(true);
    }
  }, []);

  return (
    <div className={`image-skeleton image-skeleton--${shape}`}>
      {src && (
        <img
          ref={imgEl}
          className={isLoaded ? imageClassName : "d--none"}
          src={src}
          alt="avatar"
          onLoad={() => setLoaded(true)}
          onError={() => {
            setLoaded(false);
            setError(true);
          }}
          style={{
            width: `${imgWidth}px`,
            height: `${imgWidth}px`,
          }}
        />
      )}
      {isError && name ? (
        <Avatar className="avatar-profile__text" shape={shape} size={size}>
          {getAvatarName(name)}
        </Avatar>
      ) : (
        <Skeleton.Avatar
          className={isLoaded && !!src ? "d--none" : skeletonClassName}
          active={!isLoaded && !!src}
          shape={shape}
          size={size}
        ></Skeleton.Avatar>
      )}
    </div>
  );
};

export default ImageSkeleton;
