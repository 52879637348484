import { Pagination, Response } from "./../redux/Common/types";
import { Api } from "./api";
import { Product, ProductRequest } from "redux/Product/types";

interface IProductService {
  getProducts(payload: ProductRequest): Promise<Response<Pagination<Product>>>;
  createProduct(payload: Product): Promise<Response<any>>;
  updateProduct(productId: string, payload: Product): Promise<Response<any>>;
  getProductById(productId: string): Promise<Response<any>>;
  deleteProductById(productId: string): Promise<Response<any>>;
}

export default class ProductService implements IProductService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getProducts = (
    payload: ProductRequest
  ): Promise<Response<Pagination<Product>>> => {
    return this.api.getProducts(payload);
  };

  createProduct = (payload: Product): Promise<Response<any>> => {
    return this.api.createProduct(payload);
  };

  updateProduct = (
    productId: string,
    payload: Product
  ): Promise<Response<any>> => {
    return this.api.updateProduct(productId, payload);
  };

  getProductById = (productId: string): Promise<Response<any>> => {
    return this.api.getProductById(productId);
  };

  deleteProductById = (productId: string): Promise<Response<any>> => {
    return this.api.deleteProductById(productId);
  };
}
