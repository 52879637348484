import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { reorderShopifyStoreActions } from "./actions";
import { ReorderShopifyStoreRequest } from "models/talent/talent-profile-module.model";
import { shopifyService } from "services";

function* reorderShopifyStores(action?: Action<ReorderShopifyStoreRequest[]>) {
  const result: any = yield* call(
    shopifyService.reorderStore,
    action?.payload as ReorderShopifyStoreRequest[]
  );
  if (result.ok) {
    yield* put(reorderShopifyStoreActions.SUCCESS(result.response));
  } else {
    yield* put(reorderShopifyStoreActions.FAILURE(result.message));
  }
}

export default function* shopifySagas(): SagaIterator {
  yield* takeLatest(reorderShopifyStoreActions.REQUEST, reorderShopifyStores);
}
