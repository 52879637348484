import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { purchasingOptionsService } from "services";
import { ListQueryParams } from "./../Common/types";
import {
  createPurchasingOptionsActions,
  getPurchasingOptionsActions,
  getPurchasingOptionsByIdActions,
  updatePurchasingOptionsActions,
} from "./actions";
import { CreatePurchasingOptionsRequest } from "./types";

function* createPurchasingOptions({
  payload,
}: Action<CreatePurchasingOptionsRequest>) {
  const result: any = yield* call(
    purchasingOptionsService.createPurchasingOptions,
    payload
  );
  if (result.ok) {
    yield* put(createPurchasingOptionsActions.SUCCESS(result.response));
  } else {
    yield* put(createPurchasingOptionsActions.FAILURE(result.message));
  }
}

function* getPurchasingOptions({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(
    purchasingOptionsService.getPurchasingOptions,
    payload
  );
  if (result.ok) {
    yield* put(getPurchasingOptionsActions.SUCCESS(result.response));
  } else {
    yield* put(getPurchasingOptionsActions.FAILURE(result.message));
  }
}

function* updatePurchasingOptions({ payload }: Action<any>) {
  const result: any = yield* call(
    purchasingOptionsService.updatePurchasingOptions,
    payload
  );
  if (result.ok) {
    yield* put(updatePurchasingOptionsActions.SUCCESS(result.response));
  } else {
    yield* put(updatePurchasingOptionsActions.FAILURE(result.message));
  }
}

function* getPurchasingOptionsById({ payload }: Action<string>) {
  const result: any = yield* call(
    purchasingOptionsService.getPurchasingOptionsById,
    payload
  );
  if (result.ok) {
    yield* put(getPurchasingOptionsByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getPurchasingOptionsByIdActions.FAILURE(result.message));
  }
}

export default function* purchasingOptionsSagas(): SagaIterator {
  yield* takeLatest(
    createPurchasingOptionsActions.REQUEST,
    createPurchasingOptions
  );
  yield* takeLatest(getPurchasingOptionsActions.REQUEST, getPurchasingOptions);
  yield* takeLatest(
    updatePurchasingOptionsActions.REQUEST,
    updatePurchasingOptions
  );
  yield* takeLatest(
    getPurchasingOptionsByIdActions.REQUEST,
    getPurchasingOptionsById
  );
}
