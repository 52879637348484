export enum SSXSubscriptionStage {
  None = "NONE",
  Early = "EARLY",
  Late = "LATE",
  Expired = "EXPIRED",
  Inactive = "INACTIVE",
}

export const SUBSCRIPTION_TRIAL_LENGTH_DAYS = 30;

