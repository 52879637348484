import { Action, handleActions } from "redux-actions";
import { setShopifyStoreType, triggerCheckCollectionType } from "./actions";
import { ShopifyState } from "./types";

const handlers = {
  [setShopifyStoreType]: (
    state: ShopifyState,
    { payload }: Action<any>
  ): ShopifyState => {
    return {
      ...state,
      error: null,
      stores: payload,
    };
  },
  [triggerCheckCollectionType]: (
    state: ShopifyState,
    { payload }: Action<any>
  ): ShopifyState => {
    return {
      ...state,
      error: null,
      trigger: payload,
    };
  },
};

const defaultState: ShopifyState = {
  loading: false,
  error: null,
  stores: [],
};

export const shopifyReducer = handleActions<ShopifyState>(
  handlers,
  defaultState
);
