export const presets = {
  regular10: "text--regular10",
  regular12: "text--regular12",
  regular14: "text--regular14",
  regular15: "text--regular15",
  regular16: "text--regular16",
  regular18: "text--regular18",
  regular20: "text--regular20",
  regular22: "text--regular22",
  regular24: "text--regular24",
  medium12: "text--medium12",
  medium14: "text--medium14",
  medium16: "text--medium16",
  medium18: "text--medium18",
  medium20: "text--medium20",
  medium22: "text--medium22",
  medium24: "text--medium24",
  semibold12: "text--semibold12",
  semibold14: "text--semibold14",
  semibold16: "text--semibold16",
  semibold18: "text--semibold18",
  semibold20: "text--semibold20",
  semibold24: "text--semibold24",
  bold14: "text--bold14",
  bold16: "text--bold16",
  bold18: "text--bold18",
  bold20: "text--bold20",
  bold24: "text--bold24",
  bold28: "text--bold28",
  bold30: "text--bold30",
  bold32: "text--bold32",
  bold36: "text--bold36",
  bold40: "text--bold40",
  bold48: "text--bold48",
  bold56: "text--bold56",
  bold72: "text--bold72",
};
export type TypographyPresets = keyof typeof presets;
