import { all } from "typed-redux-saga";
import userSagas from "./User/sagas";
import experienceSagas from "./Experience/sagas";
import purchasingOptionsSagas from "./PurchasingOptions/sagas";
import bookingSagas from "./Booking/sagas";
import timeRangeSagas from "./TimeRange/sagas";
import roomSagas from "./Room/sagas";
import categorySagas from "./Category/sagas";
import talentSagas from "./Talent/sagas";
import tagSagas from "./Tags/sagas";
import payoutSags from "./Payout/sagas";
import productSagas from "./Product/sagas";
import specialRequestSagas from "./SpecialRequest/sagas";
import availabilitySagas from "./Availability/sagas";
import experienceScheduledSagas from "./ExperiencesScheduled/sagas";
import shopifySagas from "./Shopify/sagas";

export default function* rootSaga(): IterableIterator<any> {
  yield* all([
    userSagas(),
    experienceSagas(),
    purchasingOptionsSagas(),
    bookingSagas(),
    timeRangeSagas(),
    roomSagas(),
    categorySagas(),
    talentSagas(),
    tagSagas(),
    payoutSags(),
    productSagas(),
    specialRequestSagas(),
    availabilitySagas(),
    experienceScheduledSagas(),
    shopifySagas(),
  ]);
}
