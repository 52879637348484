import { Layout, Menu } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Sider from "antd/lib/layout/Sider";
import classnames from "classnames";
import Loading from "components/Loading";
import MainHeader from "components/MainHeader";
import React, { ReactNode, Suspense } from "react";
import { isMobile } from "react-device-detect";
import { ContainerProvider } from "utils/container";
import "./ExperienceLayout.scss";

interface IProps {
  children?: ReactNode;
  showHeader?: boolean;
  showSidebar?: boolean;
  isExperienceFlow?: boolean;
  isLivestreamFLow?: boolean;
  className?: string;
  timerComponent?: ReactNode;
  finishComponent?: ReactNode;
}

const { Content, Header } = Layout;

const ExperienceLayout = ({
  children,
  showHeader = true,
  showSidebar,
  isExperienceFlow = false,
  isLivestreamFLow = false,
  className = "",
  timerComponent,
  finishComponent,
}: IProps) => {
  const screens = useBreakpoint();
  const mobile = isMobile || screens["xs"];
  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);
  const layoutClass = classnames({
    "experience-layout": true,
    [className]: true,
    "experience-layout--experience": isExperienceFlow,
    "experience-layout--livestream": isLivestreamFLow,
    "experience-layout--noheader": showHeader === false,
  });

  const containerClass = classnames({
    container: true,
  });
  const toggleMobileMenu = React.useCallback(() => {
    setMobileMenuVisible(!mobileMenuVisible);
  }, [mobileMenuVisible]);

  return (
    <>
      <Layout className={layoutClass}>
        {showHeader && !mobile && (
          <Header className="experience-layout__header">
            <MainHeader
              isDarkMode={isLivestreamFLow}
              isExperienceFlow={isExperienceFlow}
              isLiveStreamFlow={isLivestreamFLow}
              timerComponent={timerComponent}
              finishComponent={finishComponent}
              isVisibleMobileMenu={mobileMenuVisible}
              toggleMobileMenu={toggleMobileMenu}
            />
          </Header>
        )}
        <Layout className="experience-layout__content" id="scrollableTarget">
          {mobile && (
            <Sider
              collapsible
              collapsed={!mobileMenuVisible}
              collapsedWidth="0"
              className="mobile-sider"
              width={232}
              trigger={null}
            >
              <Menu mode="inline" className="m__t--24" selectable={false}>
                <>
                  {/* <Menu.Item key="logout" onClick={handleLogout}>
                    <Text className="text--red">Logout</Text>
                  </Menu.Item> */}
                </>
              </Menu>
            </Sider>
          )}
          <ContainerProvider>
            <Content className={containerClass}>
              <Suspense fallback={<Loading showFull={isExperienceFlow} />}>
                {children}
              </Suspense>
            </Content>
          </ContainerProvider>
        </Layout>
      </Layout>
    </>
  );
};

export default ExperienceLayout;
