import { Button, Col, Popover, Row, Spin, Typography } from "antd";
import classNames from "classnames";
import DragDropButton from "components/Button/DragDropButton";
import ImageSkeleton from "components/ImageSkeleton";
import LabelTooltip from "components/LabelTooltip/LabelTooltip";
import WarningModal from "components/WarningModal";
import { ContentStatusTitle } from "constants/experiences";
import { useWindowSize } from "hooks";
import { ReactComponent as ExpIncludeIcon } from "icons/experience-include.svg";
import { ReactComponent as LessonIcon } from "icons/lesson.svg";
import { ReactComponent as TimeIcon } from "icons/time-bold.svg";
import React, { useMemo, useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
  Experience,
  EXPERIENCE_STATUS,
  EXPERIENCE_TYPE,
} from "redux/Experience/types";
import { selectExchangeRates } from "redux/User/selector";
import {
  formatMinuteSecondWithUnit,
  formatPriceWithExchangeRate,
  formatterCurrencyEXRate,
} from "utils";
import { getFirstAvatar } from "utils/experience";
import {
  selectLoadingUpdateExperience,
  selectUpdatingExperience,
} from "../../redux/Experience/selector";
import { useTypedSelector } from "../../redux/rootReducer";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import ExperienceTypeTag from "../ExperienceTypeTag";
import { Icon } from "../Icon";
import ExperienceCardModuleAction from "./ExperienceCardModuleAction";
import "./ExperienceContentCard.scss";

const { Text } = Typography;

interface IProps {
  content: Experience;
  showPrice?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  disablePreview?: boolean;
  disableDragDrop?: boolean;
  disableUpdateStatus?: boolean;
  titleShortcut?: boolean;
  cardClass?: string;
  onEdit?: (item: Experience) => void;
  onUpdateStatus?: (item: Experience, status: string) => void;
  onDelete?: (item: Experience) => void;
  onPreview?: (item: Experience) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

const ExperienceContentCard: React.FC<IProps> = ({
  showPrice = true,
  content,
  disableDragDrop = false,
  titleShortcut = false,
  cardClass,
  dragHandleProps,
  onEdit,
  onUpdateStatus,
  onDelete,
  onPreview,
}) => {
  const size = useWindowSize();
  const loading = useTypedSelector(selectLoadingUpdateExperience);
  const editingId = useTypedSelector(selectUpdatingExperience);
  const exchangeRate = useTypedSelector(selectExchangeRates);

  const isMobile = useMemo(() => (size.width || 0) <= 768, [size]);
  const [isShowModal, toggleModal] = useState(false);
  const [showConfirmModal, toggleConfirmModal] = useState(false);
  const [visibleAction, setVisibleAction] = useState(false);

  const experienceCardClass = classNames({
    "experience-content-card border-box border__radius--8": true,
    [cardClass || ""]: true,
  });

  const experienceStatusClassName = useMemo(() => {
    switch (content.status) {
      case EXPERIENCE_STATUS.PUBLISHED:
        return "experience-content-card__header__status experience-content-card__header__status--active";
      case EXPERIENCE_STATUS.UNPUBLISHED:
        return "experience-content-card__header__status experience-content-card__header__status--unpublished";
      case EXPERIENCE_STATUS.DRAFT:
        return "experience-content-card__header__status experience-content-card__header__status--draft";
      default:
        return "experience-content-card__header__status";
    }
  }, [content]);

  const experiencePrice = useMemo(() => {
    if (content.experiencePrice === 0 && content.gbpExperiencePrice === 0)
      return "Free";
    if (
      content.experienceCreationCurrency?.toLowerCase() !==
      exchangeRate?.localCurrency.toLowerCase()
    ) {
      return formatPriceWithExchangeRate(
        content.gbpExperiencePrice || 0,
        exchangeRate
      );
    }
    return formatterCurrencyEXRate(exchangeRate).format(
      content.experiencePrice || 0
    );
  }, [content, exchangeRate]);

  const updateStatus = (status: string) => {
    typeof onUpdateStatus === "function" && onUpdateStatus(content, status);
    toggleConfirmModal(false);
  };

  const deleteExp = () => {
    typeof onDelete === "function" && onDelete(content);
    toggleConfirmModal(false);
  };

  return (
    <div
      className={experienceCardClass}
      onClick={() => {
        isMobile && typeof onEdit === "function" && onEdit(content);
      }}
    >
      <Spin spinning={loading && editingId === content.id}>
        <Row gutter={{ xs: 16, sm: 16, md: 16 }} align="middle">
          <Col className="experience-content-card__profile-picture">
            <ImageSkeleton src={getFirstAvatar(content)} />
          </Col>
          <Col className="experience-content-card__info">
            <Row
              className="experience-content-card__header p__b--8"
              align="top"
            >
              <div className="fullwidth">
                <Row align="middle">
                  <Col flex={"1"}>
                    <ExperienceTypeTag
                      className="text--semibold14"
                      experience={content}
                    />
                  </Col>
                  <Col>
                    <Row gutter={16} align="middle">
                      <Col>
                        <Popover
                          visible={visibleAction}
                          placement="bottomRight"
                          overlayClassName="module-editor-overlay"
                          arrowContent={null}
                          content={
                            <ExperienceCardModuleAction
                              status={content.status}
                              isUpdateStatus={
                                typeof onUpdateStatus === "function"
                              }
                              isPreview={typeof onPreview === "function"}
                              isEdit={
                                content.status !== EXPERIENCE_STATUS.ARCHIVED &&
                                typeof onEdit === "function"
                              }
                              isDelete={typeof onDelete === "function"}
                              onEdit={() => onEdit?.(content)}
                              onDelete={() => toggleConfirmModal(true)}
                              onPreview={() => onPreview?.(content)}
                              toggleVisible={setVisibleAction}
                              onUpdateStatus={updateStatus}
                            />
                          }
                          trigger={["click"]}
                          onVisibleChange={setVisibleAction}
                        >
                          <Button
                            className="p--0 d--flex align__items--center height--24 min-h-auto"
                            type="link"
                            onClick={() => setVisibleAction(true)}
                          >
                            <Icon name="more" />
                          </Button>
                        </Popover>
                      </Col>
                      <Col>
                        <DragDropButton
                          disabled={disableDragDrop}
                          className="p__x--0 p__y--0"
                          dragType="vertical"
                          dragHandleProps={dragHandleProps}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <LabelTooltip
                preset="semibold16"
                className="font__size--medium m__t--4"
                label={content.name as string}
                ellipsis={true}
                maxLength={
                  !titleShortcut ? (content.name as string).length : 32
                }
              />
              {showPrice && (
                <Text className="m__t--4 experience-content-card__header__price leading-22">
                  {experiencePrice}
                </Text>
              )}
            </Row>
            <Row align="middle" justify="space-between">
              <Row align="middle" className="text--default">
                {content.type === EXPERIENCE_TYPE.BUNDLE ? (
                  <React.Fragment>
                    <ExpIncludeIcon />
                    <Text>
                      {`${
                        content?.experiences?.filter(
                          (e) => e.status !== EXPERIENCE_STATUS.ARCHIVED
                        ).length || "-"
                      } experiences included`}
                    </Text>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {content.type === EXPERIENCE_TYPE.COURSE ? (
                      <LessonIcon />
                    ) : (
                      <TimeIcon opacity={1} width={24} height={24} />
                    )}
                    <Text>
                      {content?.duration
                        ? content.type === EXPERIENCE_TYPE.EXCLUSIVE_CONTENT
                          ? formatMinuteSecondWithUnit(content.duration)
                          : `${content?.duration / 60} mins`
                        : content.type === EXPERIENCE_TYPE.COURSE
                        ? `${content.lessons?.length || "-"} video lesson(s)`
                        : "--"}
                    </Text>
                  </React.Fragment>
                )}
              </Row>
              <Text className={experienceStatusClassName}>
                {ContentStatusTitle[content?.status || ""]}
              </Text>
            </Row>

            <ConfirmDeleteModal
              show={showConfirmModal}
              toggleModal={toggleConfirmModal}
              cancelLabel="No Thanks!"
              confirmLabel="Delete"
              title="Are you sure you want to delete this experience?"
              description="This experience will be deleted and you can't undo this action. Don't worry, deleting this experience won't effect any past or upcoming bookings."
              handleConfirm={() => deleteExp()}
            />
          </Col>
        </Row>
      </Spin>
      {isShowModal && (
        <WarningModal
          show={isShowModal}
          toggleModal={toggleModal}
          text="You cannot update the experience because someone has booked this experience."
        />
      )}
    </div>
  );
};

export default ExperienceContentCard;
