export enum ROOM_CONNECTION_STATUS {
  Disconnected = "disconnected",
  Connected = "connected",
  Reconnecting = "reconnecting",
}

export enum ROOM_KICK_USER_STATUS {
  Pending = "pending",
  Completed = "completed",
}
export enum ROOM_KICK_USER_TYPE {
  during = "during",
  before = "before",
}
