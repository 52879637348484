import { createGenericTypes, createGenericActions } from "utils";

export const getTimeRangesInMonthTypes = createGenericTypes(
  "GET_TIME_RANGES_IN_A_MONTH"
);
export const getTimeRangesInMonthActions = createGenericActions(
  getTimeRangesInMonthTypes
);

export const createDateOffTypes = createGenericTypes("CREATE_DATE_OFF");
export const createDateOffActions = createGenericActions(createDateOffTypes);

export const createTimeRangeTypes = createGenericTypes("CREATE_TIME_RANGE");
export const createTimeRangeActions =
  createGenericActions(createTimeRangeTypes);

export const updateTimeRangeTypes = createGenericTypes("UPDATE_TIME_RANGE");
export const updateTimeRangeActions =
  createGenericActions(updateTimeRangeTypes);

export const deleteTimeRangeTypes = createGenericTypes("DELETE_TIME_RANGE");
export const deleteTimeRangeActions =
  createGenericActions(deleteTimeRangeTypes);

export const deleteDateOffTypes = createGenericTypes("DELETE_DATE_OFF");
export const deleteDateOffActions = createGenericActions(deleteDateOffTypes);

export const deleteWeekDayTypes = createGenericTypes("DELETE_WEEK_DAY");
export const deleteWeekDayActions = createGenericActions(deleteWeekDayTypes);

export const copyTimeRangeTypes = createGenericTypes("COPY_TIME_RANGE");
export const copyTimeRangeActions = createGenericActions(copyTimeRangeTypes);

export const setRepeatTimeRangeTypes = createGenericTypes(
  "SET_REPEAT_TIME_RANGE"
);
export const setRepeatTimeRangeActions = createGenericActions(
  setRepeatTimeRangeTypes
);

export const removeRepeatTimeRangeTypes = createGenericTypes(
  "REMOVE_REPEAT_TIME_RANGE"
);
export const removeRepeatTimeRangeActions = createGenericActions(
  removeRepeatTimeRangeTypes
);
