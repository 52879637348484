import React from "react";
import { countryFlags, FlagIconName } from "./CountryFlag";
import { icons, IconName } from "./Icons";

export type IconSVGProps = {
  className?: string;
  name: IconName | FlagIconName;
  stroke?: string;
  fill?: string;
  width?: number;
  height?: number;
  opacity?: number;
  style?: any;
  title?: string;
};

export function Icon(props: IconSVGProps) {
  const { name } = props;
  const data = { ...icons, ...countryFlags };
  const IconSVG = data[name];
  return <IconSVG {...props} />;
}
