import { Response } from "redux/Common/types";
import { DspLink, DspStatus } from "types/dsp";
import { DspResource } from "types/dsp";
import { GeneralApiProblem } from "./api/apiProblem";
import { Api } from "./api/komi.api";

const isGeneralApiError = <T>(response: Response<T>): response is GeneralApiProblem => {
  return response === null || response.hasOwnProperty('error');
}

export default class DspService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  async getResourceLinks (resource: DspResource): Promise<DspLink[]> {
    const payload = await this.api.getDspResourceLinks(resource);

    if (payload === null || isGeneralApiError(payload)) {
      throw new Error(`Could not fetch resource links for ${resource.name}`);
    }

    const linksDTO = payload.response;

    const successfulLinkDTOs = linksDTO.filter(linkDTO => (
      linkDTO.status === DspStatus.Success
        && linkDTO.items
        && linkDTO.items.length > 0
    ));

    const links = successfulLinkDTOs.map<DspLink>(({ type, items = [] }) => {
      const [firstItem] = items;
      const {previewUrl, resourceUrl} = firstItem;

      return {
        type,
        url: resourceUrl,
        previewUrl,
        isVisible: true,
      };
    });

    return links;
  }
}
