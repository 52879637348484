import {
  requestOTPRequest,
  TFAVerificationRequest,
} from "./../models/talent/talent-profile-module.model";
import { Response } from "redux/Common/types";
import {
  CheckUserExistedRequest,
  CheckUserExistedResult,
  ExchangeRate,
  PhoneOTP,
  SendEmailOTPRequest,
  SendPhoneOTPRequest,
  SpotifyConnectRequest,
  SpotifyConnectResponse,
  SpotifyUser,
  StripeConnectRequest,
  StripeConnectResponse,
  UpdatePasswordRequest,
  User,
} from "redux/User/types";
import { Api } from "./api";

interface IUserService {
  // getUserProfile(): Promise<Response<User>>;
  updateConsumerProfile(payload: User): Promise<Response<User>>;
  updatePassword(payload: UpdatePasswordRequest): Promise<Response<User>>;
  sendPhoneOTP(payload: SendPhoneOTPRequest): Promise<Response<PhoneOTP>>;
  sendEmailOTP(payload: SendEmailOTPRequest): Promise<Response<boolean>>;
  checkUserExisted(
    payload: CheckUserExistedRequest
  ): Promise<Response<CheckUserExistedResult>>;
  stripeConnect(
    payload: StripeConnectRequest
  ): Promise<Response<StripeConnectResponse>>;
  getLocation(): Promise<Response<any>>;
  getExchangeRates(localCurrency: string): Promise<Response<ExchangeRate>>;
}

export const KOMI_TALENT_CURRENCY = "KOMI_TALENT_CURRENCY";
export default class UserService implements IUserService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  // getUserProfile = (): Promise<Response<User>> => {
  //   return this.api.getUserProfile();
  // };

  updateConsumerProfile = (payload: User): Promise<Response<User>> => {
    return this.api.updateConsumerProfile(payload);
  };

  updatePassword = (
    payload: UpdatePasswordRequest
  ): Promise<Response<User>> => {
    return this.api.updatePassword(payload);
  };

  sendPhoneOTP = (
    payload: SendPhoneOTPRequest
  ): Promise<Response<PhoneOTP>> => {
    return this.api.sendPhoneOTP(payload);
  };

  sendEmailOTP = (payload: SendEmailOTPRequest): Promise<Response<boolean>> => {
    return this.api.sendEmailOTP(payload);
  };

  checkUserExisted = (
    params: CheckUserExistedRequest
  ): Promise<Response<CheckUserExistedResult>> => {
    return this.api.checkUserExisted(params);
  };

  stripeConnect = (
    params: StripeConnectRequest
  ): Promise<Response<StripeConnectResponse>> => {
    return this.api.stripeConnect(params);
  };

  spotifyConnect = (
    params: SpotifyConnectRequest
  ): Promise<Response<SpotifyConnectResponse>> => {
    return this.api.spotifyConnect(params);
  };

  spotifyRefreshToken = (): Promise<Response<SpotifyUser>> => {
    return this.api.spotifyRefreshToken();
  };

  getLocation = (): Promise<Response<any>> => {
    return this.api.getLocation();
  };

  getExchangeRates = (
    localCurrency: string
  ): Promise<Response<ExchangeRate>> => {
    return this.api.getExchangeRates(localCurrency);
  };
  // requestOtp
  // TFAVerification
  requestOtp = (data: requestOTPRequest): Promise<Response<any>> => {
    return this.api.requestOtp(data);
  };
  TFAVerification = (data: TFAVerificationRequest): Promise<Response<any>> => {
    return this.api.TFAVerification(data);
  };
  deleteTFAVerification = (
    data: TFAVerificationRequest
  ): Promise<Response<any>> => {
    return this.api.deleteTFAVerification(data);
  };
}
