import { Button, Col, Row } from "antd";
import Modal from "components/Modal";
import "./ConfirmDeleteModal.scss";
import React, { useCallback } from "react";
import { Text } from "components/Typography";
import classNames from "classnames";

export interface ConfirmDeleteModalProps {
  show?: boolean;
  title?: string;
  description?: string | React.ReactNode;
  cancelLabel?: string;
  confirmLabel?: string;
  toggleModal?(show: boolean): void;
  handleConfirm?(): void;
  reverse?: boolean;
  className?: string;
  classNameAction?: string;
  classNameContent?: string;
  width?: number;
  loading?: boolean;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  title,
  description,
  show,
  cancelLabel,
  confirmLabel,
  toggleModal,
  handleConfirm,
  reverse = false,
  className,
  classNameAction,
  width = 544,
  classNameContent,
  loading,
}) => {
  const handleSubmit = useCallback(() => {
    handleConfirm?.();
  }, [handleConfirm]);

  return (
    <Modal
      closable={false}
      centered
      className={classNames("confirm-delete-modal", className)}
      visible={show}
      title={<Text preset="semibold20">{title}</Text>}
      onCancel={() => toggleModal?.(false)}
      width={width}
    >
      <Row className={classNameContent} justify="start">
        <Text preset="regular16">{description}</Text>
      </Row>
      <Row
        align="middle"
        justify="center"
        gutter={[24, 16]}
        className={classNames("m__t--24", classNameAction)}
      >
        <Col xs={24} md={8} lg={8} />
        <Col xs={24} md={8} lg={8}>
          <Button
            size="large"
            type={reverse ? "default" : "primary"}
            onClick={() => toggleModal?.(false)}
          >
            {cancelLabel || "Cancel"}
          </Button>
        </Col>
        <Col xs={24} md={8} lg={8}>
          <Button
            loading={loading}
            size="large"
            type={reverse ? "primary" : "default"}
            onClick={handleSubmit}
            className="uppercase"
            data-testid="confirm-delete--button"
          >
            {confirmLabel || "Confirm"}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmDeleteModal;
