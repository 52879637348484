// tslint:disable

import Cookies from "js-cookie";
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { defaultState, reducer } from "./agoraStore";

const StateContext: any = createContext({});
const MutationContext: any = createContext({});

export const ContainerProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  (window as any).rootState = state;

  const [toasts, updateToasts] = useState<any>([]);

  const methods = {
    startLoading() {
      dispatch({ type: "loading", payload: true });
    },
    stopLoading() {
      dispatch({ type: "loading", payload: false });
    },
    updateConfig(params: any) {
      dispatch({ type: "config", payload: { ...state.config, ...params } });
    },
    setClient(clientInstance: any) {
      dispatch({ type: "client", payload: clientInstance });
    },
    setCodec(param: any) {
      dispatch({ type: "codec", payload: param });
    },
    setVideo(param: any) {
      dispatch({ type: "video", payload: param });
    },
    setAudio(param: any) {
      dispatch({ type: "audio", payload: param });
    },
    setScreen(param: any) {
      dispatch({ type: "screen", payload: param });
    },
    setProfile(param: any) {
      dispatch({ type: "profile", payload: param });
    },
    toastSuccess(message: any) {
      updateToasts([
        ...toasts,
        {
          variant: "success",
          message,
        },
      ]);
    },
    toastInfo(message: any) {
      updateToasts([
        ...toasts,
        {
          variant: "info",
          message,
        },
      ]);
    },
    toastError(message: any) {
      updateToasts([
        ...toasts,
        {
          variant: "error",
          message,
        },
      ]);
    },
    removeTop() {
      const items = toasts.filter((e: any, idx: any) => idx > 0);
      updateToasts([...items]);
    },
    setLocalStream(param: any) {
      dispatch({ type: "localStream", payload: param });
    },
    setCurrentStream(param: any) {
      dispatch({ type: "currentStream", payload: param });
    },
    setDevicesList(param: any) {
      dispatch({ type: "devicesList", payload: param });
    },
    clearAllStream() {
      dispatch({ type: "clearAllStream" });
    },
    addLocal(evt: any) {
      const { stream } = evt;
      methods.setLocalStream(stream);
      methods.setCurrentStream(stream);
    },
    addStream(evt: any) {
      const { stream } = evt;
      dispatch({ type: "addStream", payload: stream });
    },
    removeStream(evt: any) {
      const { stream } = evt;
      dispatch({ type: "removeStream", stream: stream });
    },
    removeStreamById(evt: any) {
      const { uid } = evt;
      dispatch({ type: "removeStream", uid: uid });
    },
    connectionStateChanged(evt: any) {
      methods.toastInfo(`${evt.curState}`);
    },
    // enableBeauty(enable) {
    //   dispatch({type: 'enableBeauty', enable});
    // }
  };

  useEffect(() => {
    Cookies.set(
      "custom_storage",
      JSON.stringify({
        uid: state.config.uid,
        host: state.config.host,
        channelName: state.config.channelName,
        token: state.config.token,
        resolution: state.config.resolution,
      })
    );
  }, [state]);

  return (
    <StateContext.Provider value={state}>
      <MutationContext.Provider value={methods}>
        {children}
      </MutationContext.Provider>
    </StateContext.Provider>
  );
};

export function useGlobalState() {
  return useContext(StateContext);
}

export function useGlobalMutation() {
  return useContext(MutationContext);
}
