import { purchasingOptionsReducer } from "./PurchasingOptions/reducer";
import { PurchasingOptionsState } from "./PurchasingOptions/types";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { combineReducers } from "redux";
import { userReducer } from "./User/reducer";
import { UserState } from "./User/types";
import { experienceReducer } from "./Experience/reducer";
import { ExperienceState } from "./Experience/types";
import { BookingState } from "./Booking/types";
import { bookingReducer } from "./Booking/reducer";
import { TimeRangeState } from "./TimeRange/types";
import { timeRangeReducer } from "./TimeRange/reducer";
import { LogRequestState } from "./LogRequest/types";
import { logRequestReducer } from "./LogRequest/reducer";
import { RoomState } from "./Room/types";
import { roomReducer } from "./Room/reducer";
import { CategoryState } from "./Category/types";
import { categoryReducer } from "./Category/reducer";
import { tagReducer } from "./Tags/reducer";
import { TagState } from "./Tags/types";
import { PayoutState } from "./Payout/types";
import { payoutReducer } from "./Payout/reducer";
import { ProductState } from "./Product/types";
import { productReducer } from "./Product/reducer";
import { specialRequestReducer } from "./SpecialRequest/reducer";
import { SpecialRequestState } from "./SpecialRequest/types";
import { AvailabilityRuleState } from "./Availability/types";
import { availabilityRuleReducer } from "./Availability/reducer";
import { TalentState } from "./Talent/types";
import { talentReducer } from "./Talent/reducer";
import { experiencesScheduledReducer } from "./ExperiencesScheduled/reducer";
import { ExperiencesScheduledState } from "./ExperiencesScheduled/types";
import { shopifyReducer } from "./Shopify/reducer";
import { ShopifyState } from "./Shopify/types";

type IRootState = {
  userState: UserState;
  experienceState: ExperienceState;
  purchasingOptionsState: PurchasingOptionsState;
  bookingState: BookingState;
  timeRangeState: TimeRangeState;
  logRequestState: LogRequestState;
  roomState: RoomState;
  categoryState: CategoryState;
  tagState: TagState;
  payoutState: PayoutState;
  productState: ProductState;
  specialRequestState: SpecialRequestState;
  availabilityRuleState: AvailabilityRuleState;
  talentState: TalentState;
  experiencesScheduledState: ExperiencesScheduledState;
  shopifyState: ShopifyState;
};

// Add all reducers here.
const rootReducer = combineReducers<IRootState>({
  userState: userReducer,
  experienceState: experienceReducer,
  purchasingOptionsState: purchasingOptionsReducer,
  bookingState: bookingReducer,
  timeRangeState: timeRangeReducer,
  logRequestState: logRequestReducer,
  roomState: roomReducer,
  categoryState: categoryReducer,
  tagState: tagReducer,
  payoutState: payoutReducer,
  productState: productReducer,
  specialRequestState: specialRequestReducer,
  availabilityRuleState: availabilityRuleReducer,
  talentState: talentReducer,
  experiencesScheduledState: experiencesScheduledReducer,
  shopifyState: shopifyReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
