import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import config from "config";

interface IFirebaseService {
  write(key: string, value: any): any;
  getOnce(key: string): any;
  getDataRef(key: string): any;
}

export default class FirebaseService implements IFirebaseService {
  private database: firebase.database.Database;

  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: config.firebase.apiKey,
        databaseURL: config.firebase.databaseUrl,
      });
    } else {
      firebase.app(); // if already initialized, use that one
    }

    // Get a reference to the database service
    this.database = firebase.database();
  }

  write = (key: string, value: any) => {
    return this.database.ref(key).set(value);
  };

  getOnce = (key: string) => {
    return firebase.database().ref(key).once("value");
  };

  getDataRef = (key: string) => {
    return firebase.database().ref(key);
  };
}
