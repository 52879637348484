import { Response } from "./../redux/Common/types";
import { Api } from "./api";
import { AddPayoutMethodRequest, PayoutSchedule } from "redux/Payout/types";

interface ITalentService {
  createPayoutMethod(payload: AddPayoutMethodRequest): Promise<Response<any>>;
  getPayoutSchedule(): Promise<Response<PayoutSchedule>>;
}

export default class PayoutService implements ITalentService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  createPayoutMethod = (
    payload: AddPayoutMethodRequest
  ): Promise<Response<any>> => {
    return this.api.createPayoutMethod(payload);
  };

  getPayoutSchedule = (): Promise<Response<PayoutSchedule>> => {
    return this.api.getPayoutSchedule();
  };
}
