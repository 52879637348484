import { Category } from "redux/Category/types";
import { Pagination, ListQueryParams } from "redux/Common/types";
import { User } from "redux/User/types";
import { MUSIC_ITEM_TYPE } from "./../../constants/social";

export type Talent = User & {
  talentProfile?: {
    avatar?: string;
    background?: string;
    bio?: string;
    createdAt?: string;
    createdVia?: string;
    id?: string;
    manager?: string;
    managerEmail?: string;
    nationality?: string;
    photos?: string[];
    popularity?: string;
    profession?: string;
    socialAccount?: string;
    status?: string;
    updatedAt?: string;
  };
  talentInCategories?: {
    id: number;
    category: {
      id: number;
      name: string;
    };
    price: number;
  }[];
};

export type TalentProfileByIdRequest = {
  id: string;
};

export type TalentProfileRequest = {
  bio: string;
  username: string;
  firstName: string;
  lastName: string;
  instagram: string;
  youtube: string;
  website: string;
  categories: number[];
  accomplishments: string[];
  activationCode: string;
  phone: string;
  fanClubName?: string;
  fanClubBenefits?: string;
  onboardSteps: {
    shareProfile: boolean;
  };
  country?: string;
  id?: string;
  localizationActive?: boolean;
  localizations?: {
    id: string | null;
    name: string;
    countries: string[];
    active: boolean;
    copyFrom?: string | null;
    isFirstTimePublished?: boolean;
    isPublished?: boolean;
  }[];
  onNetworkError?: () => void;
  onSuccess?: (response?: any) => void;
  onError?: () => void;
};

export type FinishTalentProfileRequest = {
  avatar: string;
  covers: string[];
};

export enum CreateState {
  CREATE,
  FINISH,
}

export type TalentCategory = {
  talent: Talent;
  id: number;
  price: number;
  category: Category;
};

export type DataForm = {
  moduleId: string;
  formId: string;
  title: string;
  totalSubmission: number;
  createdAt?: string;
  deletedAt?: string;
  active?: boolean;
};

export type GetTalentsByCategoryRequest = ListQueryParams & {
  id: number;
};

export type GetAnalyticRequest = {
  from: string;
  to: string;
};

export type Analytics = {
  totalExperiences: number;
  totalBookings: number;
  totalEarning: number;
  nextPayout: {
    amount: number;
    currency: string;
  };
};

export type VerifyBecomeTalentOtpRequest = {
  phoneOTP: "string";
  verificationSid: "string";
  phone: "string";
};

interface MusicItemLink {
  url: string;
  type: MUSIC_ITEM_TYPE;
}

export type PreRelease = {
  moduleId: string;
  musicId: string;
  title: string;
  type: MUSIC_ITEM_TYPE,
  links: MusicItemLink[];
  createdAt?: string;
  deletedAt?: string;
  active?: boolean;
}

export type TalentState = {
  talents?: Pagination<Talent[]> | null;
  talentsTrending?: Talent[] | null;
  talentsByCategory?: Pagination<TalentCategory[]> | null;
  talent?: Talent | null;
  error?: string | null;
  loading: boolean;
  verifyOtp?: Talent | null;
  fanClubCount?: number;
  dataFormCount?: number;
  forms: DataForm[];
  activeModule?: string;
  closeExpand?: string[];
  preRelease: PreRelease[];
  preReleaseDownloads?: number;
};
