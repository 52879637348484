import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { authService, booking1To1Service } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import {
  completeBookingActions,
  getBooking1To1ByIdActions,
  getBookings1To1Actions,
  getBookingSetupActions,
  getLastestPurchaseActions,
  getUpcomingBookingsActions,
  rejectBookingActions,
  updateBookingAttachmentsActions,
  updateBookingSetupActions,
} from "./actions";
import {
  CompleteBookingRequest,
  GetBookingDetailRequest,
  GetBookingRequest,
  RejectBookingRequest,
  UpdateBookingAttachmentsRequest,
} from "./types";

function* getBookings1To1({ payload }: Action<GetBookingRequest>) {
  const result: any = yield* call(booking1To1Service.getBooking, payload);
  if (result.ok) {
    yield* put(getBookings1To1Actions.SUCCESS(result.response));
  } else {
    yield* put(getBookings1To1Actions.FAILURE(result.message));
  }
}
function* getBookingSetup() {
  const result: any = yield* call(authService.getBookingSetup);
  yield* put(getBookingSetupActions.SUCCESS(result.list));
}
function* setBookingSetup({ payload }: Action<number[]>) {
  authService.setBookingSetup(payload);
  yield* put(updateBookingSetupActions.SUCCESS(payload));
}

function* getUpcomingBookings({ payload }: Action<GetBookingRequest>) {
  const result: any = yield* call(booking1To1Service.getBooking, payload);
  if (result.ok) {
    yield* put(getUpcomingBookingsActions.SUCCESS(result.response));
  } else {
    yield* put(getUpcomingBookingsActions.FAILURE(result.message));
  }
}

function* getLatestPurchases({ payload }: Action<GetBookingRequest>) {
  const result: any = yield* call(booking1To1Service.getBooking, payload);
  if (result.ok) {
    yield* put(getLastestPurchaseActions.SUCCESS(result.response));
  } else {
    yield* put(getLastestPurchaseActions.FAILURE(result.message));
  }
}

function* getBooking1To1ById({ payload }: Action<GetBookingDetailRequest>) {
  const result: any = yield* call(booking1To1Service.getBookingById, payload);
  if (result.ok) {
    yield* put(getBooking1To1ByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getBooking1To1ByIdActions.FAILURE(result.message));
  }
}

function* rejectBooking({ payload }: Action<RejectBookingRequest>) {
  const result: any = yield* call(booking1To1Service.rejectBooking, payload);
  if (result.ok) {
    yield* put(rejectBookingActions.SUCCESS(result.response));
  } else {
    yield* put(rejectBookingActions.FAILURE(result.message));
  }
}

function* completeBooking({ payload }: Action<CompleteBookingRequest>) {
  const result: any = yield* call(booking1To1Service.completeBooking, payload);
  if (result.ok) {
    yield* put(completeBookingActions.SUCCESS(result.response));
  } else {
    yield* put(completeBookingActions.FAILURE(result.message));
  }
}

function* updateBookingAttachments({
  payload,
}: Action<UpdateBookingAttachmentsRequest>) {
  const result: any = yield* call(
    booking1To1Service.updateBookingAttachments,
    payload
  );
  if (result.ok) {
    yield* put(updateBookingAttachmentsActions.SUCCESS(result.response));
  } else {
    yield* put(updateBookingAttachmentsActions.FAILURE(result.message));
  }
}

export default function* bookingSagas(): SagaIterator {
  yield* takeLatest(getBookings1To1Actions.REQUEST, getBookings1To1);
  yield* takeLatest(getBooking1To1ByIdActions.REQUEST, getBooking1To1ById);
  yield* takeLatest(rejectBookingActions.REQUEST, rejectBooking);
  yield* takeLatest(completeBookingActions.REQUEST, completeBooking);
  yield* takeLatest(getUpcomingBookingsActions.REQUEST, getUpcomingBookings);
  yield* takeLatest(getLastestPurchaseActions.REQUEST, getLatestPurchases);
  yield* takeLatest(getBookingSetupActions.REQUEST, getBookingSetup);
  yield* takeLatest(updateBookingSetupActions.REQUEST, setBookingSetup);
  yield* takeLatest(
    updateBookingAttachmentsActions.REQUEST,
    updateBookingAttachments
  );
}
