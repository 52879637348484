import { EXPERIENCE_STATUS, EXPERIENCE_TYPE } from "redux/Experience/types";

export const NUMBER_OF_ITEMS_STEP_1: { [key in EXPERIENCE_TYPE]: number } = {
  [EXPERIENCE_TYPE.ONE_TO_ONE]: 4,
  [EXPERIENCE_TYPE.LIVE_CLASS]: 5,
  [EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS]: 5,
  [EXPERIENCE_TYPE.EXCLUSIVE_CONTENT]: 4,
  [EXPERIENCE_TYPE.COURSE]: 4,
  [EXPERIENCE_TYPE.E_COMMERCE]: 4,
  [EXPERIENCE_TYPE.SPECIAL_REQUEST]: 0,
  [EXPERIENCE_TYPE.BUNDLE]: 3,
};

export const NUMBER_OF_ITEMS_STEP_2 = {
  [EXPERIENCE_TYPE.ONE_TO_ONE]: 1,
  [EXPERIENCE_TYPE.LIVE_CLASS]: 1,
  [EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS]: 1,
  [EXPERIENCE_TYPE.EXCLUSIVE_CONTENT]: 1,
  [EXPERIENCE_TYPE.COURSE]: 1,
  [EXPERIENCE_TYPE.E_COMMERCE]: 0,
  [EXPERIENCE_TYPE.SPECIAL_REQUEST]: 0,
  [EXPERIENCE_TYPE.BUNDLE]: 1,
};

export const NUMBER_OF_ITEMS_STEP_3 = {
  [EXPERIENCE_TYPE.ONE_TO_ONE]: 1,
  [EXPERIENCE_TYPE.LIVE_CLASS]: 2,
  [EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS]: 2,
  [EXPERIENCE_TYPE.EXCLUSIVE_CONTENT]: 1,
  [EXPERIENCE_TYPE.COURSE]: 1,
  [EXPERIENCE_TYPE.E_COMMERCE]: 0,
  [EXPERIENCE_TYPE.SPECIAL_REQUEST]: 0,
  [EXPERIENCE_TYPE.BUNDLE]: 0,
};

export const NUMBER_OF_ITEMS_STEP_4 = {
  [EXPERIENCE_TYPE.ONE_TO_ONE]: 0,
  [EXPERIENCE_TYPE.LIVE_CLASS]: 0,
  [EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS]: 0,
  [EXPERIENCE_TYPE.EXCLUSIVE_CONTENT]: 0,
  [EXPERIENCE_TYPE.COURSE]: 0,
  [EXPERIENCE_TYPE.E_COMMERCE]: 0,
  [EXPERIENCE_TYPE.SPECIAL_REQUEST]: 0,
  [EXPERIENCE_TYPE.BUNDLE]: 0,
};

export const NUMBER_OF_ITEMS = (contentType: EXPERIENCE_TYPE): number => {
  return (
    NUMBER_OF_ITEMS_STEP_1[contentType] +
    NUMBER_OF_ITEMS_STEP_2[contentType] +
    NUMBER_OF_ITEMS_STEP_3[contentType] +
    NUMBER_OF_ITEMS_STEP_4[contentType]
  );
};
export const NUMBER_MEDIA_ITEMS: { [key in EXPERIENCE_TYPE]: number } = {
  [EXPERIENCE_TYPE.ONE_TO_ONE]: 5,
  [EXPERIENCE_TYPE.LIVE_CLASS]: 5,
  [EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS]: 5,
  [EXPERIENCE_TYPE.EXCLUSIVE_CONTENT]: 5,
  [EXPERIENCE_TYPE.COURSE]: 5,
  [EXPERIENCE_TYPE.E_COMMERCE]: 0,
  [EXPERIENCE_TYPE.SPECIAL_REQUEST]: 0,
  [EXPERIENCE_TYPE.BUNDLE]: 5,
};

export const ContentStatusTitle: { [string: string]: string } = {
  [EXPERIENCE_STATUS.PUBLISHED]: "Active",
  [EXPERIENCE_STATUS.DRAFT]: "Draft",
  [EXPERIENCE_STATUS.UNPUBLISHED]: "Unpublished",
};
