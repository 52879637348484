import List from "antd/lib/list";
import { Text } from "components/Typography";
import React from "react";
import { EXPERIENCE_STATUS } from "../../redux/Experience/types";

interface ExperienceCardModuleAction {
  onDelete: () => void;
  onEdit: () => void;
  onPreview: () => void;
  toggleVisible: (value: boolean) => void;
  status?: string;
  isUpdateStatus?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
  isDelete?: boolean;
  onUpdateStatus?: (status: string) => void;
}

const ExperienceCardModuleAction: React.FC<ExperienceCardModuleAction> = ({
  onEdit,
  onDelete,
  toggleVisible,
  status,
  isUpdateStatus,
  onUpdateStatus,
  onPreview,
  isPreview,
  isEdit,
  isDelete,
}) => {
  const onClose = () => {
    toggleVisible && toggleVisible(false);
  };

  const onEditModule = () => {
    onEdit && onEdit();
    onClose();
  };

  const onDeleteModule = () => {
    onDelete && onDelete();
    onClose();
  };
  const handlePreview = () => {
    onPreview?.();
    onClose();
  };
  const onPublish = () => {
    onUpdateStatus?.(EXPERIENCE_STATUS.PUBLISHED);
    onClose();
  };
  const onUnPublish = () => {
    onUpdateStatus?.(EXPERIENCE_STATUS.UNPUBLISHED);
    onClose();
  };

  return (
    <List className="module-editor__acitons">
      {isEdit && (
        <List.Item onClick={onEditModule}>
          <Text preset="semibold14">
            {status === EXPERIENCE_STATUS.DRAFT ? "Continue" : "Edit"}
          </Text>
        </List.Item>
      )}
      {isUpdateStatus && status === EXPERIENCE_STATUS.UNPUBLISHED && (
        <List.Item onClick={onPublish}>
          <Text preset="semibold14">{"Publish"}</Text>
        </List.Item>
      )}
      {isUpdateStatus && status === EXPERIENCE_STATUS.PUBLISHED && (
        <List.Item onClick={onUnPublish}>
          <Text preset="semibold14">{"Unpublish"}</Text>
        </List.Item>
      )}
      {isPreview && status === EXPERIENCE_STATUS.PUBLISHED && (
        <List.Item onClick={handlePreview}>
          <Text preset="semibold14">{"Preview"}</Text>
        </List.Item>
      )}
      {isDelete && (
        <List.Item onClick={onDeleteModule}>
          <Text preset="semibold14" className="text--red">
            Delete
          </Text>
        </List.Item>
      )}
    </List>
  );
};

export default ExperienceCardModuleAction;
