import { CurrencyCodes } from "constants/currency-code";
import { ExchangeRateDefault } from "constants/exchange-rate";
import { ExchangeRate } from "redux/User/types";

export const getSymbolFromCurrency = (currencyCode: string) => {
  const code = currencyCode.toUpperCase();
  if (CurrencyCodes.hasOwnProperty(code)) {
    return CurrencyCodes[code].symbol;
  }
  return ExchangeRateDefault.symbol;
};

export const formatToK = (num: number, digits = 2) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const formatterUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const formatterCurrency = (
  localCurrency: string = ExchangeRateDefault.localCurrency,
  minFractionDigits = 0
) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: localCurrency,
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2,
  });

export const formatterCurrencyEXRate = (
  exchangeRate: ExchangeRate = ExchangeRateDefault
) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: exchangeRate.localCurrency,
    minimumFractionDigits: exchangeRate.decimalDigits,
    maximumFractionDigits: exchangeRate.decimalDigits,
  });

export const getPriceWithExchangeRate = (
  price: number,
  exchangeRate: ExchangeRate = ExchangeRateDefault
) => {
  const priceWithRate = price * Number(exchangeRate?.GBPExchangeRate);
  return Number(priceWithRate.toFixed(exchangeRate?.decimalDigits));
};

export const formatPriceWithExchangeRate = (
  price: number,
  exchangeRate: ExchangeRate = ExchangeRateDefault
) => {
  const priceExchange = getPriceWithExchangeRate(price, exchangeRate);
  return formatterCurrencyEXRate(exchangeRate).format(priceExchange);
};

export const formatTeenPriceWithExchangeRate = (
  price: number,
  exchangeRate: ExchangeRate = ExchangeRateDefault
) => {
  const priceExchange = getPriceWithExchangeRate(price, exchangeRate);
  const priceFormatK = formatToK(priceExchange, exchangeRate.decimalDigits);
  return `${exchangeRate.symbol}${priceFormatK}`;
};

export const parseCurrencyToInt = (value: string | number) => {
  if (typeof value !== "string") return value;
  return parseInt(value.replace(/,/g, ""));
};

export const parseCurrencyToFloat = (value: string | number) => {
  if (typeof value !== "string") return value;
  return parseFloat(value.replace(/,/g, "."));
};

export const getCurrencySymbol = (currency: string) =>
  (0)
    .toLocaleString("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
