import { handleActions, Action } from "redux-actions";
import { addPayoutMethodActions, getPayoutScheduleActions } from "./actions";
import { PayoutState } from "./types";

const handlers = {
  // create purchasing options
  [addPayoutMethodActions.REQUEST]: (state: PayoutState): any => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [addPayoutMethodActions.SUCCESS]: (
    state: PayoutState,
    { payload }: Action<any>
  ): any => ({
    ...state,
    item: payload,
    error: null,
    isLoading: false,
  }),
  [addPayoutMethodActions.FAILURE]: (
    state: PayoutState,
    { payload }: Action<any>
  ): any => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  // get payout schedule
  [getPayoutScheduleActions.REQUEST]: (state: PayoutState): any => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [getPayoutScheduleActions.SUCCESS]: (
    state: PayoutState,
    { payload }: Action<any>
  ): any => ({
    ...state,
    payoutSchedule: payload,
    error: null,
    isLoading: false,
  }),
  [getPayoutScheduleActions.FAILURE]: (
    state: PayoutState,
    { payload }: Action<any>
  ): any => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
};

export const payoutReducer = handleActions<PayoutState>(handlers, {
  item: null,
  isLoading: false,
  error: null,
  payoutSchedule: null,
});
