import { Button, Col, Row, Typography } from "antd";
import Modal from "components/Modal";
import React from "react";
import "./WarningModal.scss";

interface IProps {
  show: boolean;
  toggleModal(show: boolean): void;
  text: string;
}

const { Text } = Typography;

const WarningModal = ({ show, toggleModal, text }: IProps) => {
  return (
    <Modal
      closable={false}
      centered
      className="warning-modal"
      visible={show}
      onCancel={() => toggleModal(false)}
    >
      <Row justify="center">
        <Text className="text__align--center">{text}</Text>
      </Row>
      <Row align="middle" justify="center" gutter={24} className="m__t--24">
        <Col className="flex--1">
          <Button type="primary" onClick={() => toggleModal(false)}>
            OK
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default WarningModal;
