import config from "config";
const candidate = location.hostname.split('.')[0];

export const username = (
    candidate !== config.service.env
      && candidate !== 'komi'
  )
    ? candidate
    : '';

export default function () {
  const isUpcoming = location.pathname.startsWith("/admin") // TODO: if username
  
  return {
    username,
    isUpcoming
  }
}

export function adjust(path: string, isUpcoming: boolean) {
  return isUpcoming
    ? `/admin${path}`
    : path || "/";
}
