import {
  AnalyticChartData,
  AnalyticsTalentClickRequest,
  AnalyticsTalentModuleRequest,
  AnalyticsTalentViewRequest,
} from "models/analytic-chart/analytic-chart.model";
import { Api } from "./api";
import { Response } from "../redux/Common/types";
import { CancelToken } from "axios";

interface IAnalyticsChartService {
  getTalentProfileView(
    params: AnalyticsTalentViewRequest
  ): Promise<Response<AnalyticChartData>>;
  getTalentProfileClick(
    params: AnalyticsTalentClickRequest
  ): Promise<Response<AnalyticChartData>>;
  getTalentProfileEarning(
    params: AnalyticsTalentModuleRequest
  ): Promise<Response<AnalyticChartData>>;
  getTalentProfileAvgCTR(
    params: AnalyticsTalentViewRequest
  ): Promise<Response<AnalyticChartData>>;
  getTalentModuleAnalytics(
    params: AnalyticsTalentModuleRequest
  ): Promise<Response<AnalyticChartData>>;
}

export default class AnalyticsChartService implements IAnalyticsChartService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getTalentProfileView(
    params: AnalyticsTalentViewRequest
  ): Promise<Response<AnalyticChartData>> {
    return this.api.analyticsTalentProfileView(params);
  }

  getTalentProfileClick(
    params: AnalyticsTalentClickRequest
  ): Promise<Response<AnalyticChartData>> {
    return this.api.analyticsTalentProfileClick(params);
  }

  getTalentProfileEarning(
    params: AnalyticsTalentClickRequest
  ): Promise<Response<AnalyticChartData>> {
    return this.api.analyticsTalentProfileEarning(params);
  }

  getTalentProfileAvgCTR(
    params: AnalyticsTalentViewRequest
  ): Promise<Response<AnalyticChartData>> {
    return this.api.analyticsTalentProfileAvgCTR(params);
  }

  getTalentModuleAnalytics(
    params: AnalyticsTalentModuleRequest,
    cancelToken?: CancelToken
  ): Promise<Response<AnalyticChartData>> {
    return this.api.analyticsTalentModuleAnalytics(params, cancelToken);
  }
}
