import moment from "moment";

import { RootState } from "./../rootReducer";
import { EXPERIENCE_PROCESS_STATUS, DateOff } from "./types";
import { isDateOffs } from "utils";

export const selectExperiences = (rootState: RootState) =>
  rootState.experienceState?.experience;

export const selectExperienceDetail = (rootState: RootState) =>
  rootState.experienceState.experienceDetail;

export const selectFetchExperienceDetail = (rootState: RootState) =>
  rootState.experienceState.fetchExperienceDetail;

export const selectExperienceDetailId = (rootState: RootState) =>
  rootState?.experienceState?.experienceDetail?.id;

export const selectExperienceLoading = (rootState: RootState) =>
  rootState.experienceState?.loading;

export const selectExperienceError = (rootState: RootState) =>
  rootState.experienceState?.error;

export const selectExperiencePublishError = (rootState: RootState) =>
  rootState.experienceState?.publishError;

export const selectExperienceProcessStatus = (rootState: RootState) =>
  rootState.experienceState?.contentProcessStatus;

export const selectExperienceStagingCover =
  (itemIndex: any) => (rootState: RootState) => {
    const contentProcessStatus = selectExperienceProcessStatus(rootState);
    if (contentProcessStatus === EXPERIENCE_PROCESS_STATUS.EMPTY) return {};
    return rootState?.experienceState?.stagingCovers?.[itemIndex];
  };

export const selectExperienceProductImages = (rootState: RootState) => {
  return rootState?.experienceState?.productImages;
};

export const selectExperienceStagingCovers = (rootState: RootState) => {
  const covers = rootState?.experienceState?.stagingCovers;

  if (covers) return Object.keys(covers).map((key) => covers[key]);
  else return [];
};

export const selectExperienceDateOffs = (rootState: RootState) =>
  rootState?.experienceState?.experienceDetail?.dateOffs;

export const selectExperienceDateOffByDate =
  (date: moment.Moment) =>
  (rootState: RootState): DateOff | undefined => {
    const dateOffs: DateOff[] =
      rootState?.experienceState?.experienceDetail?.dateOffs || [];

    return isDateOffs(date, dateOffs);
  };

export const selectExperienceTimeRanges = (rootState: RootState) =>
  rootState?.experienceState?.experienceDetail?.timeRanges;

export const selectExperienceLessons = (rootState: RootState) => {
  return rootState?.experienceState?.lessons;
};

export const selectExperienceLessonItem =
  (itemIndex: any) => (rootState: RootState) => {
    const contentProcessStatus = selectExperienceProcessStatus(rootState);
    if (contentProcessStatus === EXPERIENCE_PROCESS_STATUS.EMPTY) return {};
    return rootState?.experienceState?.lessons?.[itemIndex];
  };

export const selectCourseAttachments = (rootState: RootState) => {
  return rootState?.experienceState?.attachments;
};

export const selectCourseAttachmentsItem =
  (itemIndex: any) => (rootState: RootState) => {
    return rootState?.experienceState?.attachments?.[itemIndex];
  };

export const selectLoadingUpdateExperience = (rootState: RootState) =>
  rootState.experienceState?.loading;

export const selectUpdatingExperience = (rootState: RootState) =>
  rootState.experienceState?.updatingId;

export const selectUpdateSuccessExperience = (rootState: RootState) =>
  rootState.experienceState?.updateSuccess;

export const selectExperienceCreatorName = (rootState: RootState) =>
  `${
    rootState.experienceState?.experienceDetail?.creator?.talentProfile
      ?.firstName ?? ""
  } ${
    rootState.experienceState?.experienceDetail?.creator?.talentProfile
      ?.lastName ?? ""
  }`;

export const selectExperienceCreatorAvatar = (rootState: RootState) =>
  rootState.experienceState?.experienceDetail?.creator?.talentProfile?.avatar ??
  "";

export const selectExperienceCreatorCateName = (rootState: RootState) =>
  rootState.experienceState?.experienceDetail?.creator?.talentProfile
    ?.categories?.length
    ? rootState.experienceState?.experienceDetail?.creator?.talentProfile
        ?.categories[0].name
    : "";

export const selectExperienceCreatorBio = (rootState: RootState) =>
  rootState.experienceState?.experienceDetail?.creator?.talentProfile?.bio ??
  "";
