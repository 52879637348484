import { ExchangeRateDefault } from "constants/exchange-rate";
import floor from "lodash/floor";
import { ExchangeRate } from "redux/User/types";

export const formatterNumber = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const getRoundingPriceLocal = (
  price: number,
  exchangeRate: ExchangeRate = ExchangeRateDefault
) => {
  return floor(price, 0) + floor(0.999999, exchangeRate?.decimalDigits);
};

export const formatPriceToAppDisplayCurrency = (
  price: string | number | undefined
) => {
  if (!price) return 0;

  let priceTxt = price?.toString()?.replace(",", ".");
  let result = "";

  for (const element of priceTxt) {
    if (Number(element) || Number(element) === 0) {
      result += element;
    }
    if (element === "." && !result.includes(".")) {
      result += ".";
    }
  }

  priceTxt = result;
  if (!Number(priceTxt)) return 0;

  return Number(Number(priceTxt)?.toFixed(2)?.split(".")[0] + ".99");
};

export const findNearestPrice = (
  price: number | string | undefined,
  list: Array<number>
) => {
  if (!price) return 0;

  if (price > 999.99) {
    return parseInt(price as string) + 0.99;
  }

  let priceTxt = price?.toString()?.replace(",", ".");
  let result = "";

  for (const element of priceTxt) {
    if (Number(element) || Number(element) === 0) {
      result += element;
    }
    if (element === "." && !result.includes(".")) {
      result += ".";
    }
  }

  priceTxt = result;
  if (!Number(priceTxt)) return 0;

  return list.find((val) => Number(priceTxt) <= val);
};

export const getNumberFromString = (text: string) => {
  const value = text.match(/\d+/)?.[0];
  if (!value) {
    return undefined;
  }
  return parseInt(value, 10);
};
