import Loading from "components/Loading";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutActions } from "redux/User/actions";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // because this page is public so we have to force redirect to consumer manual
    dispatch(logoutActions.REQUEST({ force: true }));
    sessionStorage.clear();
  }, [dispatch]);

  return (
    <React.Fragment>
      <Loading />
    </React.Fragment>
  );
};

export default Logout;
