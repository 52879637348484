import { useCallback } from "react";
import {
  selectUserData,
  selectCollaborator,
  selectIsAdminLogin,
} from "redux/User/selector";
import { AnalyticServices } from "utils/analytics";
import { useTypedSelector } from "redux/rootReducer";
import { User } from "redux/User/types";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import {
  DefaultSegmentProperties,
  KOMI_USER_LOCATION,
  SegmentEventName,
  SegmentEventProperties,
  WithoutDefaultSegmentProperties,
} from "constants/segment";

function getDefaultProperties(
  user: User | null | undefined,
  collaborator: User | null | undefined,
  isMobile: boolean,
  isAdminLogin: boolean | undefined
): DefaultSegmentProperties {
  return {
    Location: Cookies.get(KOMI_USER_LOCATION),
    Platform: isMobile ? "Responsive" : "Web",
    "Talent User Full Name": isAdminLogin
      ? "Komi Admin"
      : `${(collaborator || user)?.firstName || ""} ${
          (collaborator || user)?.lastName || ""
        }`,
    "Talent Profile ID": user?.talentProfile?.id,
    "Talent User ID": isAdminLogin ? "Komi Admin" : user?.id,
    "Talent Name": `${user?.talentProfile?.firstName} ${user?.talentProfile?.lastName}`,
  };
}

export const useAnalytics = () => {
  const user = useTypedSelector(selectUserData);
  const collaborator = useTypedSelector(selectCollaborator);
  const isAdminLogin = useTypedSelector(selectIsAdminLogin);
  const sendSegmentEvent = useCallback(
    <TEvent extends SegmentEventName, TData extends SegmentEventProperties[TEvent]>(
      eventName: TEvent,
      dataWithoutDefaults: WithoutDefaultSegmentProperties<TData> = {} as any
    ) => {
      if (user || collaborator) {
        const defaultProperties = getDefaultProperties(
          user,
          collaborator,
          isMobile,
          isAdminLogin
        );
        AnalyticServices.track(eventName, {
          ...dataWithoutDefaults,
          ...defaultProperties,
        } as TData);
      }
    },
    [isMobile, user, collaborator]
  );
  return { sendSegmentEvent };
};
