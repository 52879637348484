import { EXPERIENCE_TYPE } from "redux/Experience/types";
import AnalyticsChartService from "./AnalyticsChartService";
import { Api } from "./api/komi.api";
import spotifyApi from "./api/spotify.api";
import bandsintownApi from "./api/bandsintown.api";
import shopMyShelfApi from "./api/shop-my-shelf.api";
import AuthService from "./AuthService";
import AvailabilityRuleService from "./AvailabilityRuleService";
import BandsintownService from "./BandsintownService";
import BookingFactoryService from "./booking/BookingFactoryService";
import CategoryService from "./CategoryService";
import ExperienceFactoryService from "./experience/ExperienceFactoryService";
import AWSService from "./externals/AWSService";
import FirebaseService from "./FirebaseService";
import IAPService from "./IAPService";
import PayoutService from "./PayoutService";
import PhotoService from "./PhotoService";
import ProductService from "./ProductService";
import SeatedService from "./SeatedService";
import PurchasingOptionsService from "./PurchasingOptionsService";
import SpecialRequestService from "./SpecialRequestService";
import SpotifyService from "./SpotifyService";
import TagService from "./TagService";
import TalentService from "./TalentService";
import TimeRangeService from "./TimeRangeService";
import UserService from "./UserService";
import SalesService from "./SalesService";
import ShopifyService from "./shopifyServices";
import DspService from "./DspService";
import ShopMyShelfService from "./ShopMyShelfService";

export const Apisauce = new Api();
Apisauce.setup();

export const authService = new AuthService(Apisauce);
export const spotifyService = new SpotifyService(spotifyApi);
export const bandsintownService = new BandsintownService(bandsintownApi);
export const shopMyShelfService = new ShopMyShelfService(shopMyShelfApi);
export const talentService = new TalentService(Apisauce);
export const userService = new UserService(Apisauce);
export const salesService = new SalesService(Apisauce);
export const shopifyService = new ShopifyService(Apisauce);
export const seatedService = new SeatedService(Apisauce);

export const experienceService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.ONE_TO_ONE
);

export const experience1To1Service = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.ONE_TO_ONE
);
export const experienceLiveClassService =
  ExperienceFactoryService.createService(Apisauce, EXPERIENCE_TYPE.LIVE_CLASS);
export const experienceInteractiveLiveClassService =
  ExperienceFactoryService.createService(
    Apisauce,
    EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS
  );
export const exclusiveContentService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.EXCLUSIVE_CONTENT
);

export const courseService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.COURSE
);
export const purchasingOptionsService = new PurchasingOptionsService(Apisauce);
export const booking1To1Service = BookingFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.ONE_TO_ONE
);
export const bookingLiveClassService = BookingFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.LIVE_CLASS
);
export const bundleService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.BUNDLE
);
export const timeRangeService = new TimeRangeService(Apisauce);
export const photoService = new PhotoService(Apisauce);

export const awsService = AWSService.getInstance();
export const categoryService = new CategoryService(Apisauce);
export const tagService = new TagService(Apisauce);
export const payoutService = new PayoutService(Apisauce);
export const productService = new ProductService(Apisauce);
export const iapService = new IAPService(Apisauce);
export const firebaseService = new FirebaseService();
export const specialRequestService = new SpecialRequestService(Apisauce);
export const availabilityRuleService = new AvailabilityRuleService(Apisauce);
export const analyticsChartService = new AnalyticsChartService(Apisauce);

export const dspService = new DspService(Apisauce);
