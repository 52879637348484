export const SEGMENT_EVENTS = {
  TALENT_ONBOARDING_STEPS_COMPLETED: "Talent onboarding step completed",
  TALENT_LOGIN_ATTEMPT: "Talent login attempt",
  VIEW_TALENT_HOME_PAGE: "View talent home page",
  VIEW_TALENT_PROFILE_PAGE: "View talent profile page",
  VIEW_EXPERIENCES_PAGE: "View experiences page",
  EXPERIENCE_CREATED: "Experience created",
  CREATE_EXPERIENCE_STEP: "Create experience step",
  VIEW_SPECIAL_REQUEST_LIST: "View special requests",
  TALENT_CONNECTED_ICLOUD_CALENDAR: "Talent connected iCloud calendar",
  TALENT_CONNECTED_OUTLOOK_CALENDAR: "Talent connected Outlook calender",
  TALENT_CONNECTED_GOOGLE_CALENDAR: "Talent connected Google calender",
  VIEW_CONNECT_A_CALENDAR_CALENDAR_PAGE: "View connect a calender page",
  VIEW_AVAILABLE_HOURS_PAGE: "View available hours page",
  SET_AVAILABLE_HOURS: "Set available hours",
  VIEW_SCHEDULED_EXPERIENCES_PAGE: "View scheduled experiences",
  TALENT_KICK_DURING_CALL: "Talent kicked consumer during call",
  SELFIE_DURING_CALL: "Talent selfie during call",
  TALENT_REPORT_BEFORE_CALL: "Talent reported consumer before call",
  VIEW_HEADER_TAB: "View Header Tab",
  PROFILE_PHOTO_UPDATED: "Profile Photo Updated",
  DISPLAY_NAME_UPDATED: "Display Name Updated",
  VIEW_THEME_TAB: "View Theme Tab",
  THEME_UPDATED: "Theme Updated",
  VIEW_SOCIAL_LINKS_TAB: "View Social Links Tab",
  SOCIAL_LINKS_UPDATED: "Social Links Updated",
  VIEW_MODULES_TAB: "View Modules Tab",
  OPEN_MODULE_CREATION_MENU: "Open Module Creation Menu",
  MODULDE_CREATED: "Module Created",
  ELEMENT_CREATED: "Element Created",
  VIEW_ANALYTICS_PAGE: "View Analytics Page",
  VIEW_TEAM_MEMBERS_PAGE: "View Team Members Page",
  VIEW_ACCOUNT_SETTINGS_PAGE: "View Account Settings Page",
  PUBLISH_CHANGES: "Publish Changes",
  TALENT_ONBOARDING_SECTION_STARTED: "Talent Onboarding Section Started",
  TALENT_ONBOARDING_STEP_COMPLETED: "Talent Onboarding Step Completed",
  CLICK_CONTACT_US: "Click Contact Us",
  CLICK_FAQS: "Click FAQ's",
  GROUP_CREATED: "Group Created",
  MODULE_MOVED_INTRO_GROUP: "Module Moved Intro Group",
  CLICK_SUBSCRIBE: "Click subscribe",
  VIEW_PLAN_SELECTION_SCREEN: "View plan selection screen",
  CLICK_PROCEED_TO_PAYMENT: "Click proceed to payment",
  CLICK_REVIEW_SUBSCRIPTION: "Click review subscription",
  CONFIRM_SUBSCRIPTION_ATTEMPT: "Confirm subscription attempt",
  CLICK_UPDATE_SUBSCRIPTION: "Click update subscription",
  CLICK_UPDATE_PAYMENT_DETAILS: "Click update payment details",
  CLICK_CANCEL_SUBSCRIPTION_PLAN: "Click cancel subscription plan",
  CONFIRM_CANCELLATION: "Confirm cancellation",
  SUBMIT_CANCELLATION_REASONS_FORM: "Submit cancellation reasons form",
} as const;

export type SegmentEventName =
  typeof SEGMENT_EVENTS[keyof typeof SEGMENT_EVENTS];

export type DefaultSegmentProperties = {
  Location: string | undefined;
  Platform: "Responsive" | "Web";
  "Talent User Full Name": string | undefined;
  "Talent Profile ID": string | undefined;
  "Talent User ID": string | number | undefined;
  "Talent Name": string | undefined;
};

export type WithoutDefaultSegmentProperties<TSegmentEventProperties> = Omit<
  TSegmentEventProperties,
  keyof DefaultSegmentProperties
>;

export const KOMI_USER_LOCATION = "KOMI_USER_LOCATION";

export type SegmentEventProperties = {
  [SEGMENT_EVENTS.TALENT_ONBOARDING_STEPS_COMPLETED]: Record<string, any>;
  [SEGMENT_EVENTS.TALENT_LOGIN_ATTEMPT]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_TALENT_HOME_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_TALENT_PROFILE_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_EXPERIENCES_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.EXPERIENCE_CREATED]: Record<string, any>;
  [SEGMENT_EVENTS.CREATE_EXPERIENCE_STEP]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_SPECIAL_REQUEST_LIST]: Record<string, any>;
  [SEGMENT_EVENTS.TALENT_CONNECTED_ICLOUD_CALENDAR]: Record<string, any>;
  [SEGMENT_EVENTS.TALENT_CONNECTED_OUTLOOK_CALENDAR]: Record<string, any>;
  [SEGMENT_EVENTS.TALENT_CONNECTED_GOOGLE_CALENDAR]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_CONNECT_A_CALENDAR_CALENDAR_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_AVAILABLE_HOURS_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.SET_AVAILABLE_HOURS]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_SCHEDULED_EXPERIENCES_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.TALENT_KICK_DURING_CALL]: Record<string, any>;
  [SEGMENT_EVENTS.SELFIE_DURING_CALL]: Record<string, any>;
  [SEGMENT_EVENTS.TALENT_REPORT_BEFORE_CALL]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_HEADER_TAB]: Record<string, any>;
  [SEGMENT_EVENTS.PROFILE_PHOTO_UPDATED]: Record<string, any>;
  [SEGMENT_EVENTS.DISPLAY_NAME_UPDATED]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_THEME_TAB]: Record<string, any>;
  [SEGMENT_EVENTS.THEME_UPDATED]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_SOCIAL_LINKS_TAB]: Record<string, any>;
  [SEGMENT_EVENTS.SOCIAL_LINKS_UPDATED]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_MODULES_TAB]: Record<string, any>;
  [SEGMENT_EVENTS.OPEN_MODULE_CREATION_MENU]: Record<string, any>;
  [SEGMENT_EVENTS.MODULDE_CREATED]: Record<string, any>;
  [SEGMENT_EVENTS.ELEMENT_CREATED]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_ANALYTICS_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_TEAM_MEMBERS_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.VIEW_ACCOUNT_SETTINGS_PAGE]: Record<string, any>;
  [SEGMENT_EVENTS.PUBLISH_CHANGES]: Record<string, any>;
  [SEGMENT_EVENTS.TALENT_ONBOARDING_SECTION_STARTED]: Record<string, any>;
  [SEGMENT_EVENTS.TALENT_ONBOARDING_STEP_COMPLETED]: Record<string, any>;
  [SEGMENT_EVENTS.CLICK_CONTACT_US]: Record<string, any>;
  [SEGMENT_EVENTS.CLICK_FAQS]: Record<string, any>;
  [SEGMENT_EVENTS.GROUP_CREATED]: Record<string, any>;
  [SEGMENT_EVENTS.MODULE_MOVED_INTRO_GROUP]: Record<string, any>;
  [SEGMENT_EVENTS.CLICK_SUBSCRIBE]: DefaultSegmentProperties & {
    "Button placement":
      | "End of trial popup"
      | "Profile settings"
      | "Profile editor";
    "Free trial days remaining": number | undefined;
    "Subscription status":
      | "On trial"
      | "Is subscribed"
      | "Trial expired"
      | "Cancelled"
      | undefined;
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.VIEW_PLAN_SELECTION_SCREEN]: DefaultSegmentProperties & {
    "Subscription status":
    | "On trial"
    | "Is subscribed"
    | "Trial expired"
    | "Cancelled"
    | undefined;
    "Currency": string | undefined;
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.CLICK_PROCEED_TO_PAYMENT]: DefaultSegmentProperties & {
    "Plan type": "Annual" | "Monthly";
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Currency": string | undefined;
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.CLICK_REVIEW_SUBSCRIPTION]: DefaultSegmentProperties & {
    "Plan type": "Annual" | "Monthly";
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Currency": string | undefined;
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.CONFIRM_SUBSCRIPTION_ATTEMPT]: DefaultSegmentProperties & {
    "Is successful?": "Yes" | "No";
    "Plan type": "Annual" | "Monthly";
    "Currency": string | undefined;
    "Subscription action": "Updating payment details" | "Adding first time" | "Updating plan";
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.CLICK_UPDATE_PAYMENT_DETAILS]: DefaultSegmentProperties & {
    "Subscription status":
    | "On trial"
    | "Is subscribed"
    | "Trial expired"
    | "Cancelled"
    | undefined;
    "Button position": "Profile settings" | "Checkout flow";
    "Plan type": "Annual" | "Monthly" | undefined;
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.CLICK_UPDATE_SUBSCRIPTION]: DefaultSegmentProperties & {
    "Subscription status":
    | "On trial"
    | "Is subscribed"
    | "Trial expired"
    | "Cancelled"
    | undefined;
    "Plan type": "Annual" | "Monthly" | undefined;
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.CLICK_CANCEL_SUBSCRIPTION_PLAN]: DefaultSegmentProperties & {
    "Plan type": "Annual" | "Monthly" | undefined;
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.CONFIRM_CANCELLATION]: DefaultSegmentProperties & {
    "Plan type": "Annual" | "Monthly" | undefined;
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Operating System": string | undefined;
  };
  [SEGMENT_EVENTS.SUBMIT_CANCELLATION_REASONS_FORM]: DefaultSegmentProperties & {
    "Plan type": "Annual" | "Monthly" | undefined;
    "Profile Creation Method": "Self Sign Up" | "Admin Panel";
    "Chose reason?": "Yes" | "No";
    "Added detailed answer?": "Yes" | "No";
    "Operating System": string | undefined;
  };
};

/**
 * This does not do anything.
 *
 * This solely exists to ensure that every value of @type {SEGMENT_EVENTS} has properties defined in @type {SegmentEventProperties}
 *
 * If anybody adds a value to @type {SEGMENT_EVENTS} this will error to show that they also need to add to @type {SegmentEventProperties}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AnySegmentEventProperties = SegmentEventProperties[SegmentEventName];
