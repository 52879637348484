import { Col, Row } from "antd";
import classNames from "classnames";
import { Icon } from "components/Icon";
import { Paragraph } from "components/Typography";
import React, { useCallback, useState } from "react";
import { NoticeType } from "utils/notification";

interface Props {
  messages: { message: string; type: string; onReview: () => void }[];
  type?: NoticeType;
  onClose?: () => void;
}

const ErrorPublishNotification: React.FC<Props> = ({ messages, onClose }) => {
  const [step, setStep] = useState(0);
  const isOnly = messages.length === 1;
  const handleUp = useCallback(() => {
    const value = step + 1 < messages.length ? step + 1 : 0;
    setStep(value);
    messages[value].onReview?.();
  }, [step, messages]);
  const handleDown = useCallback(() => {
    const value = step - 1 >= 0 ? step - 1 : messages.length - 1;
    setStep(value);
    messages[value].onReview?.();
  }, [step, messages]);

  return (
    <React.Fragment>
      <div className="m__l--16">
        <Row gutter={32} align="middle">
          <Col flex="1">
            <Paragraph preset="regular15">{messages[step].message}</Paragraph>
          </Col>
          <Col>
            <Row gutter={16} align="middle">
              <Col onClick={handleUp} className="cursor-pointer">
                <Paragraph
                  preset="medium16"
                  className="text--blue p__x--0 p__y--0"
                >
                  Review
                </Paragraph>
              </Col>
              <Col>
                <Row gutter={8} align="middle">
                  <Col
                    onClick={handleDown}
                    className={classNames(
                      "height--24 cursor-pointer",
                      isOnly && "opacity--20"
                    )}
                  >
                    <Icon name="arrow-up" />
                  </Col>
                  <Col>
                    <Paragraph preset="regular15">
                      {`${step + 1}/${messages.length}`}
                    </Paragraph>
                  </Col>
                </Row>
              </Col>

              <Col
                onClick={handleUp}
                className={classNames(
                  "height--24 cursor-pointer",
                  isOnly && "opacity--20"
                )}
              >
                <Icon name="arrow-down" />
              </Col>
              <Col className="height--24 cursor-pointer" onClick={onClose}>
                <Icon name="close-outline" width={24} height={24} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ErrorPublishNotification;
