import "./DragDropButton.scss";
import { Button, ButtonProps } from "antd";
import classNames from "classnames";
import { Icon } from "components/Icon";
import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

interface DragDropButtonProps extends ButtonProps {
  dragType: "vertical" | "horizontal";
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

const DragDropButton: React.FC<DragDropButtonProps> = ({
  dragType,
  dragHandleProps,
  ...props
}) => {
  const dragDropButtonClass = classNames("dragdrop-button", {
    [props.className || ""]: true,
    "btn-dragdrop": props.disabled ? false : true,
    "opacity--50": props.disabled,
  });
  return (
    <React.Fragment>
      {dragHandleProps && (
        <Button
          {...props}
          {...dragHandleProps}
          className={dragDropButtonClass}
          type="link"
        >
          <div data-testid="drag-button" className="height--24">
            {dragType === "horizontal" ? (
              <Icon name="drag-horizontal" />
            ) : props.disabled ? (
              <Icon name="drag-vertical-inactive" />
            ) : (
              <Icon name="drag-vertical" />
            )}
          </div>
        </Button>
      )}
    </React.Fragment>
  );
};

export default DragDropButton;
