import { Action, handleActions } from "redux-actions";
import {
  getListSpecialRequestsActions,
  getListSpecialRequestsTypes,
  getSpecialRequestByIdActions,
  getSpecialRequestByIdTypes,
  setSpecialRequestStatusActions,
} from "./actions";
import { SpecialRequestState } from "./types";

const handlers = {
  // get list special requests
  [getListSpecialRequestsActions.REQUEST]: (
    state: SpecialRequestState
  ): SpecialRequestState => ({
    ...state,
    requests: null,
    loading: true,
    type: null,
  }),
  [getListSpecialRequestsActions.SUCCESS]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    requests: payload,
    loading: false,
    type: getListSpecialRequestsTypes.SUCCESS,
  }),
  [getListSpecialRequestsActions.FAILURE]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    error: payload,
    loading: false,
    type: getListSpecialRequestsTypes.FAILURE,
  }),

  // get special request by id
  [getSpecialRequestByIdActions.REQUEST]: (
    state: SpecialRequestState
  ): SpecialRequestState => ({
    ...state,
    loading: true,
    type: null,
    requestData: null,
  }),
  [getSpecialRequestByIdActions.SUCCESS]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    requestData: payload,
    loading: false,
    type: getSpecialRequestByIdTypes.SUCCESS,
  }),
  [getSpecialRequestByIdActions.FAILURE]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    error: payload,
    loading: false,
    type: getSpecialRequestByIdTypes.FAILURE,
  }),

  [setSpecialRequestStatusActions.REQUEST]: (
    state: SpecialRequestState
  ): SpecialRequestState => ({
    ...state,
    loading: true,
    type: null,
    requestData: null,
  }),
  [setSpecialRequestStatusActions.SUCCESS]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    requestData: payload,
    loading: false,
    type: getSpecialRequestByIdTypes.SUCCESS,
  }),
  [setSpecialRequestStatusActions.FAILURE]: (
    state: SpecialRequestState,
    { payload }: Action<any>
  ): SpecialRequestState => ({
    ...state,
    error: payload,
    loading: false,
    type: getSpecialRequestByIdTypes.FAILURE,
  }),
};

const initialState: SpecialRequestState = {
  error: null,
  loading: false,
  requests: null,
  requestData: null,
  type: null,
};

export const specialRequestReducer = handleActions<SpecialRequestState>(
  handlers,
  initialState
);
