import { createAction } from "redux-actions";
import { createGenericTypes, createGenericActions } from "utils/createAction";

export const setShopifyStoreType = "SET_SHOPIFY_STORES";
export const setShopifyStoreAction = createAction(setShopifyStoreType);

export const triggerCheckCollectionType = "TRIGGER_CHECK_COLECTION";
export const triggerCheckCollectionAction = createAction(
  triggerCheckCollectionType
);

export const reorderShopifyStoreTypes = createGenericTypes(
  "REORDER_SHOPIFY_STORE"
);

export const reorderShopifyStoreActions = createGenericActions(
  reorderShopifyStoreTypes
);
