import { Action, handleActions } from "redux-actions";
import {
  createAvailabilityRuleActions,
  getAvailabilityRuleActions,
  updateAvailabilityRuleActions,
} from "./actions";
import { AvailabilityRuleState } from "./types";

const handlers = {
  // get rules
  [getAvailabilityRuleActions.REQUEST]: (
    state: AvailabilityRuleState
  ): AvailabilityRuleState => ({
    ...state,
    getLoading: true,
    rulesLoaded: false,
  }),
  [getAvailabilityRuleActions.FAILURE]: (
    state: AvailabilityRuleState
  ): AvailabilityRuleState => ({
    ...state,
    getLoading: false,
    rulesLoaded: true,
  }),
  [getAvailabilityRuleActions.SUCCESS]: (
    state: AvailabilityRuleState,
    { payload }: Action<any>
  ): AvailabilityRuleState => {
    return {
      ...state,
      getLoading: false,
      availabilityRules: payload.availabilityRules,
      hasRules: payload.availabilityRules?.[0]?.weeklyPeriods?.length > 0,
      rulesLoaded: true,
    };
  },
  [createAvailabilityRuleActions.SUCCESS]: (
    state: AvailabilityRuleState,
    { payload }: Action<any>
  ): AvailabilityRuleState => {
    const newRules = [...state.availabilityRules, payload.availabilityRule];
    return {
      ...state,
      loading: false,
      availabilityRules: newRules,
      hasRules: newRules?.[0]?.weeklyPeriods?.length > 0,
    };
  },
  [createAvailabilityRuleActions.REQUEST]: (
    state: AvailabilityRuleState
  ): AvailabilityRuleState => ({
    ...state,
    loading: true,
  }),
  [createAvailabilityRuleActions.FAILURE]: (
    state: AvailabilityRuleState
  ): AvailabilityRuleState => ({
    ...state,
    loading: false,
  }),
  [updateAvailabilityRuleActions.SUCCESS]: (
    state: AvailabilityRuleState,
    { payload }: Action<any>
  ): AvailabilityRuleState => {
    const newRules = state.availabilityRules.map((i) =>
      i.availabilityRuleId !== payload.availabilityRule.availabilityRuleId
        ? { ...i }
        : { ...i, ...payload.availabilityRule }
    );

    return {
      ...state,
      loading: false,
      availabilityRules: newRules,
      hasRules: newRules?.[0]?.weeklyPeriods?.length > 0,
    };
  },
  [updateAvailabilityRuleActions.REQUEST]: (
    state: AvailabilityRuleState
  ): AvailabilityRuleState => ({
    ...state,
    loading: true,
  }),
  [updateAvailabilityRuleActions.FAILURE]: (
    state: AvailabilityRuleState
  ): AvailabilityRuleState => ({
    ...state,
    loading: false,
  }),
};

const initialState: AvailabilityRuleState = {
  error: null,
  getLoading: false,
  loading: false,
  availabilityRules: [],
  hasRules: false,
  rulesLoaded: false,
};

export const availabilityRuleReducer = handleActions<AvailabilityRuleState>(
  handlers,
  initialState
);
