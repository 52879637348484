import { ReactComponent as AB } from "icons/country-flags/AB.svg";
import { ReactComponent as AX } from "icons/country-flags/AX.svg";
import { ReactComponent as AS } from "icons/country-flags/AS.svg";
import { ReactComponent as AO } from "icons/country-flags/AO.svg";
import { ReactComponent as AI } from "icons/country-flags/AI.svg";
import { ReactComponent as AQ } from "icons/country-flags/AQ.svg";
import { ReactComponent as AW } from "icons/country-flags/AW.svg";
import { ReactComponent as BJ } from "icons/country-flags/BJ.svg";
import { ReactComponent as BW } from "icons/country-flags/BW.svg";
import { ReactComponent as BF } from "icons/country-flags/BF.svg";
import { ReactComponent as BI } from "icons/country-flags/BI.svg";
import { ReactComponent as DZ } from "icons/country-flags/DZ.svg";
import { ReactComponent as CD } from "icons/country-flags/democratic-republic-of-congo.svg";
import { ReactComponent as CF } from "icons/country-flags/central-african-republic.svg";
import { ReactComponent as CG } from "icons/country-flags/republic-of-the-congo.svg";
import { ReactComponent as CI } from "icons/country-flags/ivory-coast.svg";
import { ReactComponent as CM } from "icons/country-flags/cameroon.svg";
import { ReactComponent as CV } from "icons/country-flags/cape-verde.svg";
import { ReactComponent as DJ } from "icons/country-flags/djibouti.svg";
import { ReactComponent as EG } from "icons/country-flags/egypt.svg";
import { ReactComponent as ER } from "icons/country-flags/eritrea.svg";
import { ReactComponent as ET } from "icons/country-flags/ethiopia.svg";
import { ReactComponent as GA } from "icons/country-flags/gabon.svg";
import { ReactComponent as GH } from "icons/country-flags/ghana.svg";
import { ReactComponent as GM } from "icons/country-flags/gambia.svg";
import { ReactComponent as GN } from "icons/country-flags/guinea.svg";
import { ReactComponent as GQ } from "icons/country-flags/equatorial-guinea.svg";
import { ReactComponent as GW } from "icons/country-flags/guinea-bissau.svg";
import { ReactComponent as KE } from "icons/country-flags/kenya.svg";
import { ReactComponent as KM } from "icons/country-flags/comoros.svg";
import { ReactComponent as LR } from "icons/country-flags/liberia.svg";
import { ReactComponent as LS } from "icons/country-flags/lesotho.svg";
import { ReactComponent as LY } from "icons/country-flags/libya.svg";
import { ReactComponent as MA } from "icons/country-flags/morocco.svg";
import { ReactComponent as MG } from "icons/country-flags/malawi.svg";
import { ReactComponent as ML } from "icons/country-flags/mali.svg";
import { ReactComponent as MR } from "icons/country-flags/mauritania.svg";
import { ReactComponent as MU } from "icons/country-flags/mauritius.svg";
import { ReactComponent as MW } from "icons/country-flags/malawi.svg";
import { ReactComponent as MZ } from "icons/country-flags/mozambique.svg";
import { ReactComponent as NA } from "icons/country-flags/namibia.svg";
import { ReactComponent as NE } from "icons/country-flags/niger.svg";
import { ReactComponent as NG } from "icons/country-flags/nigeria.svg";
import { ReactComponent as RW } from "icons/country-flags/rwanda.svg";
import { ReactComponent as SC } from "icons/country-flags/seychelles.svg";
import { ReactComponent as SD } from "icons/country-flags/sudan.svg";
import { ReactComponent as SL } from "icons/country-flags/sierra-leone.svg";
import { ReactComponent as SN } from "icons/country-flags/senegal.svg";
import { ReactComponent as SO } from "icons/country-flags/somalia.svg";
import { ReactComponent as SS } from "icons/country-flags/south-sudan.svg";
import { ReactComponent as ST } from "icons/country-flags/sao-tome-and-principe.svg";
import { ReactComponent as SZ } from "icons/country-flags/swaziland.svg";
import { ReactComponent as TD } from "icons/country-flags/chad.svg";
import { ReactComponent as TG } from "icons/country-flags/togo.svg";
import { ReactComponent as TN } from "icons/country-flags/tunisia.svg";
import { ReactComponent as TZ } from "icons/country-flags/tanzania.svg";
import { ReactComponent as UG } from "icons/country-flags/uganda.svg";
import { ReactComponent as ZA } from "icons/country-flags/south-africa.svg";
import { ReactComponent as ZM } from "icons/country-flags/zambia.svg";
import { ReactComponent as ZW } from "icons/country-flags/zimbabwe.svg";
import { ReactComponent as AE } from "icons/country-flags/united-arab-emirates.svg";
import { ReactComponent as AF } from "icons/country-flags/AF.svg";
import { ReactComponent as AM } from "icons/country-flags/AM.svg";
import { ReactComponent as AZ } from "icons/country-flags/AZ.svg";
import { ReactComponent as BD } from "icons/country-flags/BD.svg";
import { ReactComponent as BH } from "icons/country-flags/BH.svg";
import { ReactComponent as BN } from "icons/country-flags/BN.svg";
import { ReactComponent as BT } from "icons/country-flags/BT.svg";
import { ReactComponent as CN } from "icons/country-flags/china.svg";
import { ReactComponent as CY } from "icons/country-flags/cyprus.svg";
import { ReactComponent as GE } from "icons/country-flags/georgia.svg";
import { ReactComponent as HK } from "icons/country-flags/hong-kong.svg";
import { ReactComponent as ID } from "icons/country-flags/indonesia.svg";
import { ReactComponent as ESIB } from "icons/country-flags/ESIB.svg";
import { ReactComponent as IL } from "icons/country-flags/israel.svg";
import { ReactComponent as IN } from "icons/country-flags/india.svg";
import { ReactComponent as IQ } from "icons/country-flags/iraq.svg";
import { ReactComponent as IR } from "icons/country-flags/iran.svg";
import { ReactComponent as JO } from "icons/country-flags/jordan.svg";
import { ReactComponent as JP } from "icons/country-flags/japan.svg";
import { ReactComponent as KG } from "icons/country-flags/kyrgyzstan.svg";
import { ReactComponent as KH } from "icons/country-flags/cambodia.svg";
import { ReactComponent as KP } from "icons/country-flags/north-korea.svg";
import { ReactComponent as KR } from "icons/country-flags/south-korea.svg";
import { ReactComponent as KW } from "icons/country-flags/kuwait.svg";
import { ReactComponent as KZ } from "icons/country-flags/kazakhstan.svg";
import { ReactComponent as LA } from "icons/country-flags/laos.svg";
import { ReactComponent as LB } from "icons/country-flags/lebanon.svg";
import { ReactComponent as LK } from "icons/country-flags/sri-lanka.svg";
import { ReactComponent as MM } from "icons/country-flags/myanmar.svg";
import { ReactComponent as MN } from "icons/country-flags/mongolia.svg";
import { ReactComponent as MO } from "icons/country-flags/macao.svg";
import { ReactComponent as MV } from "icons/country-flags/maldives.svg";
import { ReactComponent as MY } from "icons/country-flags/malaysia.svg";
import { ReactComponent as NP } from "icons/country-flags/nepal.svg";
import { ReactComponent as OM } from "icons/country-flags/oman.svg";
import { ReactComponent as PH } from "icons/country-flags/philippines.svg";
import { ReactComponent as PK } from "icons/country-flags/pakistan.svg";
import { ReactComponent as PS } from "icons/country-flags/palestine.svg";
import { ReactComponent as QA } from "icons/country-flags/panama.svg";
import { ReactComponent as SA } from "icons/country-flags/saudi-arabia.svg";
import { ReactComponent as SG } from "icons/country-flags/singapore.svg";
import { ReactComponent as SY } from "icons/country-flags/syria.svg";
import { ReactComponent as TH } from "icons/country-flags/thailand.svg";
import { ReactComponent as TJ } from "icons/country-flags/tajikistan.svg";
import { ReactComponent as TL } from "icons/country-flags/east-timor.svg";
import { ReactComponent as TM } from "icons/country-flags/turkmenistan.svg";
import { ReactComponent as TR } from "icons/country-flags/turkey.svg";
import { ReactComponent as TW } from "icons/country-flags/taiwan.svg";
import { ReactComponent as UZ } from "icons/country-flags/uzbekistan.svg";
import { ReactComponent as VN } from "icons/country-flags/vietnam.svg";
import { ReactComponent as YE } from "icons/country-flags/yemen.svg";
import { ReactComponent as AD } from "icons/country-flags/AD.svg";
import { ReactComponent as AL } from "icons/country-flags/AL.svg";
import { ReactComponent as AT } from "icons/country-flags/AT.svg";
import { ReactComponent as BA } from "icons/country-flags/BA.svg";
import { ReactComponent as BE } from "icons/country-flags/BE.svg";
import { ReactComponent as BG } from "icons/country-flags/BG.svg";
import { ReactComponent as BY } from "icons/country-flags/BY.svg";
import { ReactComponent as BQ } from "icons/country-flags/BQ.svg";
import { ReactComponent as CH } from "icons/country-flags/switzerland.svg";
import { ReactComponent as CZ } from "icons/country-flags/czech-republic.svg";
import { ReactComponent as DE } from "icons/country-flags/germany.svg";
import { ReactComponent as DK } from "icons/country-flags/denmark.svg";
import { ReactComponent as EE } from "icons/country-flags/estonia.svg";
import { ReactComponent as ES } from "icons/country-flags/spain.svg";
import { ReactComponent as FI } from "icons/country-flags/finland.svg";
import { ReactComponent as FR } from "icons/country-flags/france.svg";
import { ReactComponent as GR } from "icons/country-flags/greece.svg";
import { ReactComponent as HR } from "icons/country-flags/croatia.svg";
import { ReactComponent as HU } from "icons/country-flags/hungary.svg";
import { ReactComponent as IE } from "icons/country-flags/ireland.svg";
import { ReactComponent as IS } from "icons/country-flags/iceland.svg";
import { ReactComponent as IT } from "icons/country-flags/italy.svg";
import { ReactComponent as LI } from "icons/country-flags/liechtenstein.svg";
import { ReactComponent as LT } from "icons/country-flags/lithuania.svg";
import { ReactComponent as LU } from "icons/country-flags/luxembourg.svg";
import { ReactComponent as LV } from "icons/country-flags/latvia.svg";
import { ReactComponent as MC } from "icons/country-flags/monaco.svg";
import { ReactComponent as MD } from "icons/country-flags/moldova.svg";
import { ReactComponent as ME } from "icons/country-flags/montenegro.svg";
import { ReactComponent as MK } from "icons/country-flags/republic-of-macedonia.svg";
import { ReactComponent as MT } from "icons/country-flags/malta.svg";
import { ReactComponent as NL } from "icons/country-flags/netherlands.svg";
import { ReactComponent as NO } from "icons/country-flags/norway.svg";
import { ReactComponent as PL } from "icons/country-flags/poland.svg";
import { ReactComponent as PT } from "icons/country-flags/portugal.svg";
import { ReactComponent as RO } from "icons/country-flags/romania.svg";
import { ReactComponent as RS } from "icons/country-flags/serbia.svg";
import { ReactComponent as RU } from "icons/country-flags/russia.svg";
import { ReactComponent as SE } from "icons/country-flags/sweden.svg";
import { ReactComponent as SI } from "icons/country-flags/slovenia.svg";
import { ReactComponent as SK } from "icons/country-flags/slovakia.svg";
import { ReactComponent as SM } from "icons/country-flags/san-marino.svg";
import { ReactComponent as UA } from "icons/country-flags/ukraine.svg";
import { ReactComponent as GB } from "icons/country-flags/england.svg";
import { ReactComponent as VA } from "icons/country-flags/vatican-city.svg";
import { ReactComponent as AG } from "icons/country-flags/AG.svg";
import { ReactComponent as AR } from "icons/country-flags/AR.svg";
import { ReactComponent as BB } from "icons/country-flags/BB.svg";
import { ReactComponent as ESPV } from "icons/country-flags/ES-PV.svg";
import { ReactComponent as BO } from "icons/country-flags/BO.svg";
import { ReactComponent as BR } from "icons/country-flags/BR.svg";
import { ReactComponent as BS } from "icons/country-flags/BS.svg";
import { ReactComponent as BZ } from "icons/country-flags/BZ.svg";
import { ReactComponent as BM } from "icons/country-flags/BM.svg";
import { ReactComponent as CA } from "icons/country-flags/canada.svg";
import { ReactComponent as CL } from "icons/country-flags/chile.svg";
import { ReactComponent as CO } from "icons/country-flags/colombia.svg";
import { ReactComponent as CR } from "icons/country-flags/costa-rica.svg";
import { ReactComponent as CU } from "icons/country-flags/cuba.svg";
import { ReactComponent as DM } from "icons/country-flags/dominica.svg";
import { ReactComponent as DO } from "icons/country-flags/dominican-republic.svg";
import { ReactComponent as EC } from "icons/country-flags/ecuador.svg";
import { ReactComponent as GD } from "icons/country-flags/grenada.svg";
import { ReactComponent as GT } from "icons/country-flags/guatemala.svg";
import { ReactComponent as GY } from "icons/country-flags/guyana.svg";
import { ReactComponent as HN } from "icons/country-flags/honduras.svg";
import { ReactComponent as HT } from "icons/country-flags/haiti.svg";
import { ReactComponent as JM } from "icons/country-flags/jamaica.svg";
import { ReactComponent as KN } from "icons/country-flags/KN.svg";
import { ReactComponent as LC } from "icons/country-flags/st-lucia.svg";
import { ReactComponent as MX } from "icons/country-flags/mexico.svg";
import { ReactComponent as NI } from "icons/country-flags/nicaragua.svg";
import { ReactComponent as PA } from "icons/country-flags/panama.svg";
import { ReactComponent as PE } from "icons/country-flags/peru.svg";
import { ReactComponent as PY } from "icons/country-flags/paraguay.svg";
import { ReactComponent as SR } from "icons/country-flags/suriname.svg";
import { ReactComponent as SV } from "icons/country-flags/el-salvador.svg";
import { ReactComponent as TT } from "icons/country-flags/trinidad-and-tobago.svg";
import { ReactComponent as US } from "icons/country-flags/united-states.svg";
import { ReactComponent as UY } from "icons/country-flags/uruguay.svg";
import { ReactComponent as VC } from "icons/country-flags/st-vincent-and-the-grenadines.svg";
import { ReactComponent as VE } from "icons/country-flags/venezuela.svg";
import { ReactComponent as AU } from "icons/country-flags/AU.svg";
import { ReactComponent as FJ } from "icons/country-flags/fiji.svg";
import { ReactComponent as FM } from "icons/country-flags/micronesia.svg";
import { ReactComponent as KI } from "icons/country-flags/kiribati.svg";
import { ReactComponent as MH } from "icons/country-flags/martinique.svg";
import { ReactComponent as NR } from "icons/country-flags/nauru.svg";
import { ReactComponent as NZ } from "icons/country-flags/new-zealand.svg";
import { ReactComponent as PG } from "icons/country-flags/papua-new-guinea.svg";
import { ReactComponent as PW } from "icons/country-flags/palau.svg";
import { ReactComponent as SB } from "icons/country-flags/solomon-islands.svg";
import { ReactComponent as TO } from "icons/country-flags/tonga.svg";
import { ReactComponent as TV } from "icons/country-flags/tuvalu.svg";
import { ReactComponent as VU } from "icons/country-flags/vanuatu.svg";
import { ReactComponent as WS } from "icons/country-flags/samoa.svg";
import { ReactComponent as PT20 } from "icons/country-flags/PT-20.svg";
import { ReactComponent as CABC } from "icons/country-flags/CA-BC.svg";
import { ReactComponent as IO } from "icons/country-flags/IO.svg";
import { ReactComponent as VG } from "icons/country-flags/VG.svg";
import { ReactComponent as BV } from "icons/country-flags/norway.svg";
import { ReactComponent as KY } from "icons/country-flags/cayman-islands.svg";
import { ReactComponent as CX } from "icons/country-flags/AU.svg";
import { ReactComponent as CC } from "icons/country-flags/cocos-island.svg";
import { ReactComponent as CK } from "icons/country-flags/cook-islands.svg";
import { ReactComponent as FK } from "icons/country-flags/falkland-islands.svg";
import { ReactComponent as FO } from "icons/country-flags/faroe-islands.svg";
import { ReactComponent as PF } from "icons/country-flags/french-polynesia.svg";
import { ReactComponent as TF } from "icons/country-flags/france.svg";
import { ReactComponent as GI } from "icons/country-flags/gibraltar.svg";
import { ReactComponent as GL } from "icons/country-flags/greenland.svg";
import { ReactComponent as GP } from "icons/country-flags/france.svg";
import { ReactComponent as GU } from "icons/country-flags/guam.svg";
import { ReactComponent as GG } from "icons/country-flags/guernsey.svg";
import { ReactComponent as IM } from "icons/country-flags/isle-of-man.svg";
import { ReactComponent as JE } from "icons/country-flags/jersey.svg";
import { ReactComponent as MQ } from "icons/country-flags/martinique.svg";
import { ReactComponent as YT } from "icons/country-flags/france.svg";
import { ReactComponent as MS } from "icons/country-flags/montserrat.svg";
import { ReactComponent as NC } from "icons/country-flags/france.svg";
import { ReactComponent as NU } from "icons/country-flags/niue.svg";
import { ReactComponent as NF } from "icons/country-flags/norfolk-island.svg";
import { ReactComponent as MP } from "icons/country-flags/northern-marianas-islands.svg";
import { ReactComponent as PN } from "icons/country-flags/pitcairn-islands.svg";
import { ReactComponent as PR } from "icons/country-flags/puerto-rico.svg";
import { ReactComponent as RE } from "icons/country-flags/france.svg";
import { ReactComponent as SH } from "icons/country-flags/united-kingdom.svg";
import { ReactComponent as PM } from "icons/country-flags/france.svg";
import { ReactComponent as SJ } from "icons/country-flags/norway.svg";
import { ReactComponent as TK } from "icons/country-flags/tokelau.svg";
import { ReactComponent as TC } from "icons/country-flags/turks-and-caicos.svg";
import { ReactComponent as VI } from "icons/country-flags/virgin-islands.svg";
import { ReactComponent as EH } from "icons/country-flags/sahrawi-arab-democratic-republic.svg";
export const countryFlags = {
  AB: AB,
  AX: AX,
  AS: AS,
  AO: AO,
  AI: AI,
  AQ: AQ,
  AW: AW,
  BJ: BJ,
  BW: BW,
  BF: BF,
  BI: BI,
  CD: CD,
  CF: CF,
  CG: CG,
  CI: CI,
  CM: CM,
  CV: CV,
  DJ: DJ,
  DZ: DZ,
  EG: EG,
  ER: ER,
  ET: ET,
  GA: GA,
  GH: GH,
  GM: GM,
  GN: GN,
  GQ: GQ,
  GW: GW,
  KE: KE,
  KM: KM,
  LR: LR,
  LS: LS,
  LY: LY,
  MA: MA,
  MG: MG,
  ML: ML,
  MR: MR,
  MU: MU,
  MW: MW,
  MZ: MZ,
  NA: NA,
  NE: NE,
  NG: NG,
  RW: RW,
  SC: SC,
  SD: SD,
  SL: SL,
  SN: SN,
  SO: SO,
  SS: SS,
  ST: ST,
  SZ: SZ,
  TD: TD,
  TG: TG,
  TN: TN,
  TZ: TZ,
  UG: UG,
  ZA: ZA,
  ZM: ZM,
  ZW: ZW,
  AE: AE,
  AF: AF,
  AM: AM,
  AZ: AZ,
  BD: BD,
  BH: BH,
  BN: BN,
  BT: BT,
  CN: CN,
  CY: CY,
  GE: GE,
  HK: HK,
  ID: ID,
  "ES-IB": ESIB,
  IL: IL,
  IN: IN,
  IQ: IQ,
  IR: IR,
  JO: JO,
  JP: JP,
  KG: KG,
  KH: KH,
  KP: KP,
  KR: KR,
  KW: KW,
  KZ: KZ,
  LA: LA,
  LB: LB,
  LK: LK,
  MM: MM,
  MN: MN,
  MO: MO,
  MV: MV,
  MY: MY,
  OM: OM,
  PH: PH,
  PK: PK,
  PS: PS,
  QA: QA,
  SA: SA,
  SG: SG,
  SY: SY,
  TH: TH,
  TJ: TJ,
  TL: TL,
  TM: TM,
  TR: TR,
  TW: TW,
  UZ: UZ,
  VN: VN,
  YE: YE,
  NP: NP,
  AD: AD,
  AL: AL,
  AT: AT,
  BA: BA,
  BE: BE,
  BG: BG,
  BY: BY,
  CH: CH,
  CZ: CZ,
  DE: DE,
  DK: DK,
  EE: EE,
  ES: ES,
  FI: FI,
  FR: FR,
  GR: GR,
  HR: HR,
  HU: HU,
  IE: IE,
  IS: IS,
  IT: IT,
  LI: LI,
  LT: LT,
  LU: LU,
  LV: LV,
  MC: MC,
  MD: MD,
  ME: ME,
  MK: MK,
  MT: MT,
  NL: NL,
  NO: NO,
  PL: PL,
  PT: PT,
  RO: RO,
  RS: RS,
  RU: RU,
  SE: SE,
  SI: SI,
  SK: SK,
  SM: SM,
  UA: UA,
  GB: GB,
  VA: VA,
  AG: AG,
  AR: AR,
  BB: BB,
  BO: BO,
  BR: BR,
  BS: BS,
  BZ: BZ,
  CA: CA,
  CL: CL,
  CO: CO,
  CR: CR,
  CU: CU,
  DM: DM,
  DO: DO,
  EC: EC,
  GD: GD,
  GT: GT,
  GY: GY,
  HN: HN,
  HT: HT,
  JM: JM,
  KN: KN,
  LC: LC,
  MX: MX,
  NI: NI,
  PA: PA,
  PE: PE,
  PY: PY,
  SR: SR,
  SV: SV,
  TT: TT,
  US: US,
  UY: UY,
  VC: VC,
  VE: VE,
  AU: AU,
  FJ: FJ,
  FM: FM,
  KI: KI,
  MH: MH,
  NR: NR,
  NZ: NZ,
  PG: PG,
  PW: PW,
  SB: SB,
  TO: TO,
  TV: TV,
  VU: VU,
  WS: WS,
  "PT-20": PT20,
  "ES-PV": ESPV,
  "CA-BC": CABC,
  BQ: BQ,
  BM: BM,
  IO: IO,
  VG: VG,
  BV: BV,
  KY: KY,
  CX: CX,
  CC: CC,
  CK: CK,
  FK: FK,
  FO: FO,
  PF: PF,
  TF: TF,
  GI: GI,
  GL: GL,
  GP: GP,
  GU: GU,
  GG: GG,
  IM: IM,
  JE: JE,
  MQ: MQ,
  YT: YT,
  MS: MS,
  NC: NC,
  NU: NU,
  NF: NF,
  MP: MP,
  PN: PN,
  PR: PR,
  RE: RE,
  SH: SH,
  PM: PM,
  SJ: SJ,
  TK: TK,
  TC: TC,
  VI: VI,
  EH: EH,
};
export type FlagIconName = keyof typeof countryFlags;
