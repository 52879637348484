import { createGenericTypes, createGenericActions } from "utils/createAction";

export const createEcommerceTypes = createGenericTypes("CREATE_E_COMMERCE");

export const createEcommerceActions =
  createGenericActions(createEcommerceTypes);

export const updateEcommerceTypes = createGenericTypes("UPDATE_E_COMMERCE");

export const updateEcommerceActions =
  createGenericActions(updateEcommerceTypes);

export const togglePublishEcommerceTypes = createGenericTypes(
  "TOGGLE_PUBLISH_E_COMMERCE"
);

export const togglePublishEcommerceActions = createGenericActions(
  togglePublishEcommerceTypes
);

export const getEcommercesTypes = createGenericTypes("GET_E_COMMERCES");

export const getEcommercesActions = createGenericActions(getEcommercesTypes);

export const getEcommerceByIdTypes = createGenericTypes("GET_E_COMMERCE_BY_ID");

export const getEcommerceByIdActions = createGenericActions(
  getEcommerceByIdTypes
);

export const deleteEcommerceByIdTypes = createGenericTypes(
  "DELETE_E_COMMERCE_BY_ID"
);

export const deleteEcommerceByIdActions = createGenericActions(
  deleteEcommerceByIdTypes
);

export const updateEcommerceStateTypes = createGenericTypes(
  "UPDATE_E_COMMERCE_STATE"
);

export const updateEcommerceStateActions = createGenericActions(
  updateEcommerceStateTypes
);
