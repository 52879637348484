import { Response } from "redux/Common/types";
import {
  BandsintownArtist,
  BandsintownArtistEvent,
} from "./../redux/User/types";
import { BandsintownApi } from "./api/bandsintown.api";
import { BandsintownItem } from "models/talent/talent-profile-module.model";

export default class BandsintownService {
  constructor(private readonly api: BandsintownApi) {
    this.api = api;
  }

  getArtistById = (id: number): Promise<Response<BandsintownArtist>> => {
    return this.api.getArtistById(id);
  };
  getArtistEventsByName = (
    item: BandsintownItem,
    params: { date: string }
  ): Promise<Response<BandsintownArtistEvent[]>> => {
    return this.api.getArtistEventsByName(item, params);
  };
}
