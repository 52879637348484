import 'dotenv/config';
window.env = { ...process.env } as any;

let { komi } = (window as any);

// temporary loading for tests
if (typeof process !== 'undefined' && process.env.NODE_ENV === 'test') {
  window.env = process.env as any;
}

if (!komi) {
  komi = {};

  Object.defineProperty(window, 'komi', {
    value: komi,
    configurable: false
  });
}

const config = {
  version: window.env.REACT_APP_SERVICE_VERSION,

  admin: {
    url: window.env.REACT_APP_ADMIN_URL
  },
  api: {
    url: window.env.REACT_APP_API_URL
  },
  agora: {
    id: window.env.REACT_APP_AGORA_APP_ID
  },
  bandsintown: {
    id: window.env.REACT_APP_BANDSINTOWN_APP_ID,
    mask: window.env.REACT_APP_BANDSINTOWN_APP_ID_BASE,
    url: window.env.REACT_APP_BANDSINTOWN_API_URL
  },
  ci: {
    build: window.env.REACT_APP_CI_BUILD,
    domain: window.env.REACT_APP_CI_ROOT_DOMAIN
  },
  client: {
    url: window.env.REACT_APP_CONSUMER_URL,
  },
  dca: {
    flags: {
      revealModule: window.env.REACT_APP_DCA_REVEAL_MODULE_FLAG === 'true',
      settings: window.env.REACT_APP_DCA_SETTINGS_FLAG === 'true',
      banners: window.env.REACT_APP_DCA_ALLOWANCE_BANNERS_FLAG === 'true',
    }
  },
  firebase: {
    apiKey: window.env.REACT_APP_FIREBASE_API_KEY,
    databaseUrl: window.env.REACT_APP_FIREBASE_DATABASE_URL
  },
  flags: {
    host: window.env.REACT_APP_FEATURE_FLAG_HOST,
    key: window.env.REACT_APP_FEATURE_FLAG_KEY,
  },
  logRocket: {
    id: window.env.REACT_APP_LOG_ROCKET
  },
  segment: {
    id: window.env.REACT_APP_SEGMENT_APP_ID
  },
  service: {
    domain: window.env.REACT_APP_ROOT_DOMAIN,
    env: window.env.REACT_APP_NODE_ENV,
    local: window.env.REACT_APP_LOCAL,
    name: window.env.REACT_APP_SERVICE_NAME,
    url: window.env.REACT_APP_PUBLIC_URL,
    assetUrl: window.env.PUBLIC_URL,
    version: window.env.REACT_APP_SERVICE_VERSION,
  },
  shopify: {
    key: window.env.REACT_APP_SHOPIFY_KEY
  },
  shopMyShelf: {
    url: window.env.REACT_APP_SHOP_MY_SHELF_URL
  },
  spotify: {
    url: window.env.REACT_APP_SPOTIFY_URL
  },
  stream: {
    key: window.env.REACT_APP_MESSAGE_ENCRYPTION_KEY
  },
  stripe: {
    key: window.env.REACT_APP_STRIPE_KEY
  },
  userleap: {
    id: window.env.REACT_APP_USERLEAP_APP_ID
  }
}

komi.talent || Object.defineProperty(komi, 'talent', {
  value: Object.freeze(config),
  configurable: false
});

export default config;
