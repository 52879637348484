import React, { useState } from "react";
import { Button, Form, Input, Row } from "antd";
import { isMobile } from "react-device-detect";

import { useTypedSelector } from "redux/rootReducer";
import { selectUserData } from "redux/User/selector";
import { talentService } from "services";
import Modal from "components/Modal";
import { Paragraph } from "components/Typography";
import { Icon } from "components/Icon";
import { KomiLogo } from "@komi-app/components";

interface FormProperties {
  talentName: string;
  talentSocialProfileUrl: string;
}

type RequestProfileStatus = undefined | "loading" | "success" | "error";

interface RequestAProfileModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk?: () => void;
}

export const RequestAProfileModal: React.FC<RequestAProfileModalProps> = ({
  visible,
  onCancel,
  onOk,
}) => {
  const user = useTypedSelector(selectUserData);
  const [form] = Form.useForm<FormProperties>();

  const [requestProfileStatus, setRequestProfileStatus] = useState<RequestProfileStatus>(undefined);

  const handleSubmit = async () => {
    await form.validateFields();

    const formResult = form.getFieldsValue();

    if (!user?.id) {
      setRequestProfileStatus("error");
      return;
    }

    setRequestProfileStatus("loading");

    try {
      const response = await talentService.requestTalentProfile({
        talentName: formResult.talentName,
        talentSocialProfileUrl: formResult.talentSocialProfileUrl,
        userId: user.id,
      });

      setRequestProfileStatus(response && "ok" in response && response.ok ? "success" : "error");
    } catch (error) {
      setRequestProfileStatus("error");
    }
  };

  return (
    <Modal
      className="request-talent-profile-modal"
      closable={true}
      maskClosable={false}
      title={
        <>
          <Paragraph preset="semibold20">
            {requestProfileStatus === "success"
              ? "Your Request Has Been Submitted"
              : "Request a New Talent Profile"}
          </Paragraph>
          <Paragraph className="opacity--08 m__t--4" preset={isMobile ? "regular14" : "regular16"}>
            {requestProfileStatus === "success"
              ? "Your profile request has been submitted. A member of our team will be in touch once it’s reviewed."
              : "Fill out the form below and a member of our team will be in touch."}
          </Paragraph>
        </>
      }
      visible={visible}
      onOk={() => {}}
      footer={
        <Row align="middle" justify="center">
          <KomiLogo height={isMobile ? 20 : 24} />
        </Row>
      }
      onCancel={onCancel}
      centered
      destroyOnClose
    >
      {requestProfileStatus === "success" ? (
        <Button type="primary" block onClick={onOk}>
          Done
        </Button>
      ) : (
        <Form
          layout="vertical"
          form={form}
          hideRequiredMark
          onFinish={handleSubmit}
          initialValues={{ talentName: "", talentSocialProfileUrl: "" }}
        >
          <Form.Item
            className="m__b--16"
            name="talentName"
            label="Talent name"
            rules={[{ required: true, message: "Please add a social profile link" }]}
          >
            <Input placeholder="Enter talent name" disabled={requestProfileStatus === "loading"} />
          </Form.Item>

          <Form.Item
            className="m__b--16"
            name="talentSocialProfileUrl"
            label="Talent Instagram or TikTok URL"
            rules={[
              {
                type: "url",
                message: "Enter a valid URL",
              },
              {
                required: true,
                message: "Please add a social profile link",
              },
            ]}
          >
            <Input
              placeholder="Enter Instagram or TikTok link"
              disabled={requestProfileStatus === "loading"}
            />
          </Form.Item>

          {requestProfileStatus === "error" ? (
            <div className="error-container">
              <Icon name="error" />
              <Paragraph preset="regular16">
                There was an error submitting the form. Please try again.
              </Paragraph>
            </div>
          ) : null}

          <Button
            className="m__t--16"
            type="primary"
            htmlType="submit"
            block
            loading={requestProfileStatus === "loading"}
          >
            Submit Request
          </Button>
        </Form>
      )}
    </Modal>
  );
};
