import { createGenericTypes, createGenericActions } from "utils/createAction";

export const createPurchasingOptionsTypes = createGenericTypes(
  "CREATE_PURCHASING_OPTIONS"
);
export const createPurchasingOptionsActions = createGenericActions(
  createPurchasingOptionsTypes
);

export const getPurchasingOptionsTypes = createGenericTypes(
  "GET_PURCHASING_OPTIONS"
);
export const getPurchasingOptionsActions = createGenericActions(
  getPurchasingOptionsTypes
);

export const updatePurchasingOptionsTypes = createGenericTypes(
  "UPDATE_PURCHASING_OPTIONS"
);
export const updatePurchasingOptionsActions = createGenericActions(
  updatePurchasingOptionsTypes
);

export const getPurchasingOptionsByIdTypes = createGenericTypes(
  "GET_PURCHASING_OPTIONS_BY_ID"
);
export const getPurchasingOptionsByIdActions = createGenericActions(
  getPurchasingOptionsByIdTypes
);
