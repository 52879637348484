import { Response } from "../redux/Common/types";
import { Api } from "./api";

interface IPhotoService {
  getPresignedPhotoUrl(
    fileExtension: string,
    fileName: string,
    contentType: string
  ): Promise<Response<any>>;
  getPresignedVideoUrl(fileExtension: string): Promise<Response<any>>;
  createInitiateMultipartUpload(
    fileExtension: string,
    fileName: string,
    fileSize: number
  ): Promise<Response<any>>;
  getCompleteMultipartUpload(
    objectKey: string,
    uploadId: string,
    parts: Array<{ ETag: string; PartNumber: number }>
  ): Promise<Response<any>>;
  stopMultipartUpload(
    objectKey: string,
    uploadId: string
  ): Promise<Response<any>>;
}

export default class PhotoService implements IPhotoService {
  constructor(private readonly api: Api) {
    this.api = api;
  }
  getPresignedPhotoUrl = (
    fileExtension: string,
    contentType: string
  ): Promise<Response<any>> => {
    return this.api.createPresignedPhotoUrl(fileExtension, contentType);
  };

  getPresignedVideoUrl = (fileExtension: string): Promise<Response<any>> => {
    return this.api.createPresignedVideoUrl(fileExtension);
  };

  getPresignedUrl = (
    fileExtension: string,
    fileName: string,
    contentType: string
  ): Promise<Response<any>> => {
    return this.api.createPresignedUrl(fileExtension, fileName, contentType);
  };

  createInitiateMultipartUpload = (
    fileExtension: string,
    fileName: string,
    fileSize: number
  ): Promise<Response<any>> => {
    return this.api.createInitiateMultipartUpload(
      fileExtension,
      fileName,
      fileSize
    );
  };

  getCompleteMultipartUpload = (
    objectKey: string,
    uploadId: string,
    parts: Array<{ ETag: string; PartNumber: number }>
  ): Promise<Response<any>> => {
    return this.api.getCompleteMultipartUpload(objectKey, uploadId, parts);
  };

  stopMultipartUpload = (
    objectKey: string,
    uploadId: string
  ): Promise<Response<any>> => {
    return this.api.stopMultipartUpload(objectKey, uploadId);
  };
}
