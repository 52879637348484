import { Button, Checkbox, Col, Row } from "antd";
import classNames from "classnames";
import Modal from "components/Modal";
import { Paragraph, Text } from "components/Typography";
import isArray from "lodash/isArray";
import sortBy from "lodash/sortBy";
import React, { useCallback, useMemo, useState } from "react";
import { useTypedSelector } from "redux/rootReducer";
import {
  selectLocalizations,
  selectLocalizationUnpublishIds,
  selectTalentProfile,
} from "redux/User/selector";
import "./PublishModal.scss";

export interface PublishModalProps {
  visible?: boolean;
  setVisible?(show: boolean): void;
  onSubmit?: (values: any) => void;
}

const PublishModal: React.FC<PublishModalProps> = ({
  visible,
  setVisible,
  onSubmit,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [selected, setSelected] = useState<(string | null)[]>([]);
  const [showError, setShowError] = useState(false);
  const talentProfile = useTypedSelector(selectTalentProfile);
  const localizationUnpublishedIds = useTypedSelector(
    selectLocalizationUnpublishIds
  );
  const defaultPage = useMemo(() => {
    const isUnPublished =
      talentProfile?.versions?.length ||
      localizationUnpublishedIds?.includes(null);
    return {
      id: null,
      name: "Default",
      currency: "USD",
      active: true,
      countries: [],
      copyFrom: null,
      isFirstTimePublished: true,
      isPublished: !isUnPublished,
    };
  }, [localizationUnpublishedIds, talentProfile?.versions?.length]);

  const localizationsData = useTypedSelector(selectLocalizations);
  const localizations = useMemo(() => {
    return localizationsData?.map((item) => {
      return {
        ...item,
        isPublished:
          item.isPublished &&
          isArray(localizationUnpublishedIds) &&
          !localizationUnpublishedIds?.includes(item.id),
      };
    });
  }, [localizationsData, localizationUnpublishedIds]);
  const unPublishData = useMemo(() => {
    const data = sortBy(localizations?.filter((el) => !el.isPublished) || [], [
      "name",
    ]);
    return defaultPage.isPublished ? data : [defaultPage, ...data];
  }, [defaultPage, localizations]);
  const publishedData = useMemo(() => {
    const data = sortBy(localizations?.filter((el) => el.isPublished) || [], [
      "name",
    ]);
    return !defaultPage.isPublished ? data : [defaultPage, ...data];
  }, [defaultPage, localizations]);
  const handleSubmit = useCallback(() => {
    if (!selected.length) {
      setShowError(true);
      return;
    }
    setVisible?.(false);
    setSelected([]);
    setSelectedAll(false);
    onSubmit?.(selected);
  }, [selected, onSubmit]);
  const handleChangeSelected = useCallback(
    (item) => (event: any) => {
      const checked = event.target.checked;
      setShowError(false);
      if (selectedAll) {
        setSelectedAll(false);
      }
      setSelected((values) => {
        if (checked) {
          return [...values, item.id];
        }
        return values.filter((el) => el !== item.id);
      });
    },
    [selectedAll]
  );
  const handleSelectAll = useCallback(
    (event: any) => {
      const checked = event.target.checked;
      setShowError(false);
      setSelectedAll(checked);
      setSelected(checked ? unPublishData.map((el) => el.id) : []);
      return;
    },
    [unPublishData]
  );

  const renderUnpublished = useCallback(() => {
    if (!unPublishData.length) {
      return null;
    }
    const isOnly = unPublishData.length === 1;

    return (
      <div>
        <Paragraph preset="semibold14">
          Pages With Unpublished Changes
        </Paragraph>
        <Row className="m__t--12" gutter={[0, 13]}>
          {!isOnly && (
            <Col span={24}>
              <Row align="middle" gutter={12}>
                <Col>
                  <Checkbox checked={selectedAll} onChange={handleSelectAll} />
                </Col>
                <Col>
                  <Paragraph className="leading-22 opacity--80">
                    Select All
                  </Paragraph>
                </Col>
              </Row>
            </Col>
          )}
          {unPublishData.map((item, index) => {
            const isSelected = selected.includes(item.id);
            return (
              <Col key={`unpublished-${index}`} span={24}>
                <Row align="middle" gutter={12}>
                  <Col>
                    <Checkbox
                      checked={isSelected}
                      onChange={handleChangeSelected(item)}
                    />
                  </Col>
                  <Col>
                    <Paragraph className="leading-22 opacity--80">
                      {item.name}
                    </Paragraph>
                  </Col>
                </Row>
                {isSelected && (!item.isFirstTimePublished || !item.active) && (
                  <div className="publish-modal__warning m__t--5">
                    <Paragraph className="opacity--80 leading-22">
                      {!item.isFirstTimePublished
                        ? "You are about to publish this page for the first time. Once it’s published, visitors from the relevant countries will be directed to your localized page."
                        : "You are about to publish a page that is currently set to inactive. Once it’s published, the page will become active again and visitors from the relevant countries will be directed to your localized page."}
                    </Paragraph>
                  </div>
                )}
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }, [
    unPublishData,
    selected,
    selectedAll,
    handleSelectAll,
    handleChangeSelected,
  ]);
  const renderPublished = useCallback(() => {
    if (!publishedData.length) {
      return null;
    }

    return (
      <div className={unPublishData.length ? "m__t--20" : ""}>
        <Paragraph preset="semibold14">All Changes Published</Paragraph>
        <Row className="m__t--12" gutter={[0, 13]}>
          {publishedData.map((item, index) => {
            return (
              <Col
                className="opacity--40 pointer-none"
                key={`unpublished-${index}`}
                span={24}
              >
                <Row align="middle" gutter={12}>
                  <Col>
                    <Checkbox checked={false} />
                  </Col>
                  <Col>
                    <Paragraph className="leading-22 opacity--80">
                      {item.name}
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }, [publishedData]);
  const handleCancel = useCallback(() => {
    setVisible?.(false);
    setSelectedAll(false);
    setSelected([]);
  }, []);

  return (
    <Modal
      closable={false}
      centered
      className={classNames("publish-modal")}
      visible={visible}
      title={
        <Text preset="semibold18">
          You are About to Publish Your Updated Profile
        </Text>
      }
      onCancel={handleCancel}
      width={602}
    >
      <Row justify="start">
        <Paragraph className="opacity--80" preset="regular16">
          Once your updates are published, they will be reflected on the live
          version of your Komi profile and everyone will be able to see them. To
          proceed, select the pages you want to publish.
        </Paragraph>
        <Paragraph className="m__t--24" preset="semibold16">
          Select Which Pages You Want to Publish
        </Paragraph>
      </Row>
      <div className="publish-modal__content m__t--16">
        {renderUnpublished()}
        {renderPublished()}
      </div>
      {showError && (
        <Paragraph className="m__t--8 text--error" preset="regular12">
          Please select a page to publish
        </Paragraph>
      )}
      <Row
        align="middle"
        justify="center"
        gutter={[24, 16]}
        className={classNames("m__t--32")}
      >
        <Col xs={24} md={12} lg={12}>
          <Button
            size="large"
            className="uppercase"
            type={"default"}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Button
            size="large"
            type={"primary"}
            onClick={handleSubmit}
            className="uppercase"
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default PublishModal;
