import { IconName } from "components/Icon";
import type { Subscription, PaymentMethod } from "types/sales";
import { ProductKey } from "types/sales";
import {
  CollaboratorProfile,
  TalentCollaborator,
} from "models/talent/talent-profile-collaborator.model";
import {
  TalentModuleMixItem,
  TalentProfileModule,
} from "models/talent/talent-profile-module.model";
import { MediaUpload } from "redux/Common/types";
import { Analytics } from "redux/Talent/types";

export type User = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  username?: string;
  phone?: string;
  spotifyRefreshToken?: string;
  isConnectedStripeAccount?: boolean;
  acceptTermsAndConditions?: boolean;
  acceptMarketingOffers?: boolean;
  emailVerified?: boolean;
  accountStatus?: TalentAccountStatusType;
  activationCode?: string;
  talentProfile?: UserProfile;
  talentProfileOrigin?: UserProfile;
  previousVersionTalentProfile?: UserProfile;
  avatar?: string;
  bio?: string;
  dateOfBirth?: string;
  country?: string;
  localCurrency?: string;
  roles?: [
    {
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    }
  ];
  collaborationTalentProfiles?: CollaboratorProfile[];
  thirdPartyInfo?: {
    spotifyRefreshToken?: string;
    stripeAccountId?: string;
  };
  authProviders?: string[];
  phone2faEnabled?: boolean;
  phoneOtp?: string;
  email2faEnabled?: boolean;
  subscriptions?: {
    [talent_profile_id: string]: {
      [value in ProductKey]?: Subscription;
    };
  };
  paymentMethod?: PaymentMethod;
  createdById?: number;
};

export type LocalizationItem = {
  id: string | null;
  name: string;
  currency: string;
  active: boolean;
  countries: string[];
  copyFrom: string | null;
  isPublished?: boolean;
  isFirstTimePublished?: boolean;
};

export interface FormDataItem {
  id?: string;
  layout?: "left" | "center";
  image: string;
  title: string;
  subTitle?: string;
  form: FormItem;
}

export interface FormItem {
  name: string;
  title?: string;
  subTitle?: string;
  fields: FieldItem[];
}

export interface FieldItem {
  name?: string;
  label?: string;
  text?: string;
  required?: boolean;
  type?: FieldType;
  options?: OptionItem[];
  legalType?: string;
  checked?: boolean;
}

export interface OptionItem {
  name?: string;
  value?: string;
  label: string;
  checked: boolean;
}

export enum FieldType {
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  SELECT_SINGLE = "SELECT_SINGLE",
  SELECT_MULTIPLE = "SELECT_MULTIPLE",
  LEGALS = "LEGALS",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  SELECT_DATE = "SELECT_DATE",
  SELECT_COUNTRY = "SELECT_COUNTRY",
}

export enum TalentProfileThemeTypes {
  DARK = "DARK",
  LIGHT = "LIGHT",
  CUSTOM = "CUSTOM",
}

export interface TalentProfileThemeColor {
  typographyColor: string;
  backgroundColor: string;
  overlayColor: string;
  overlayOpacity: number;
}

export enum TalentAccountStatusType {
  INACTIVE = "inactive",
  ACTIVE = "active",
  BANNED = "banned",
  SUSPENSE = "suspended",
  DEACTIVATED = "deactivated",
}

export enum TalentProfileStatusType {
  NEW = "new",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

export type TalentCategory = {
  id: number;
  name: string;
};

export enum FOOT_PRINT {
  youtube = "youtube",
  instagram = "instagram",
  website = "website",
}
export enum TalentProfileDisplayNameTypes {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
}
export type UserProfile = {
  avatar?: string;
  bio?: string;
  city?: string;
  country?: string;
  createdAt?: string;
  dateOfBirth?: string;
  gender?: string;
  id?: string;
  status?: TalentProfileStatusType;
  categories?: TalentCategory[];
  category?: {
    name: string;
    id?: number;
  };
  phone?: string;
  state?: string;
  updatedAt?: string;
  accomplishments?: string[];
  covers?: string[];
  firstName?: string;
  lastName?: string;
  [FOOT_PRINT.youtube]?: string;
  [FOOT_PRINT.website]?: string;
  [FOOT_PRINT.instagram]?: string;
  calendarSetting?: {
    availabilityRuleId: string;
    city: string;
    hasAvailabilityRules: boolean;
    tzid: string;
  };
  onboardSteps?: {
    shareProfile?: boolean;
    addProfile?: boolean;
    addSocialChannel?: boolean;
    addFirstModule?: boolean;
    customizeBrand?: boolean;
    goLive?: boolean;
    isDone?: boolean;
  };
  displayName?: string;
  displayNameImage?: string;
  displayNameImageScale?: string;
  showDisplayName?: boolean;
  displayNameType?: TalentProfileDisplayNameTypes;
  modules?: TalentProfileModule<TalentModuleMixItem>[];
  socialProfileLinks?: SocialProfileLink[];
  privacyPolicy?: string;
  privacyPolicyName?: string;
  accountStatus?: TalentAccountStatusType;
  facebookPixels?: MetaPixel[];
  fanClubName?: string;
  fanClubBenefits?: string;
  themeType?: TalentProfileThemeTypes;
  themeColor?: TalentProfileThemeColor;
  shopifyMerchants?: any;
  versions?: any[];
  createdVia?: string;
  localizations?: LocalizationItem[];
  localizationActive?: boolean;
  subscriptions?: {
    [value in ProductKey]?: Subscription;
  };
  user?: {
    id: number
    username: string
    createdById?: number
  }
};

export interface MetaPixel {
  pixelId: string;
  conversionAPIAccessToken: string;
}

export type LogoutRequest = {
  waiting?: number;
  force?: boolean;
};

export type UpdatePasswordRequest = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export enum TalentImageTypes {
  AVATAR = "avatar",
  COVERS = "covers",
}

export type TempImage = {
  [TalentImageTypes.AVATAR]: string | null;
  [TalentImageTypes.COVERS]: Array<string>;
};

export enum TalentImageErrorMessage {
  REQUIRED_AVATAR = "Please upload a profile picture",
  REQUIRED_COVERS = "Please upload a cover photo",
}

export enum CALENDAR_PROVIDERS {
  GOOGLE_CALENDAR = "google",
  OUTLOOK_PLUG_IN = "live_connect",
  OFFICE_365_CALENDAR = "office365",
  EXCHANGE_CALENDAR = "exchange",
  ICLOUD_CALENDAR = "apple",
}

export enum STATUS_CALENDAR_PROVIDERS {
  IN_PROGRESS = "in_progress",
  CONNECT = "connect",
  IDEAL = "ideal",
}

export type PhoneOTP = {
  serviceSid: string;
  phone: string;
};

export type SendPhoneOTPRequest = {
  phone: string;
};

export type SendEmailOTPRequest = {
  email: string;
  firstName: string;
};

export type CheckUserExistedRequest = {
  username?: string;
  email?: string;
  role?: string;
  password?: string;
};

export type CheckUserExistedResult = {
  isExisted: boolean;
  firstName: string;
  lastName: string;
  avatar: null;
};

export type SpotifyConnectRequest = {
  callbackUrl: string;
};

export type SpotifyConnectResponse = {
  url: string;
};

export type StripeConnectRequest = {
  callbackUrl: string;
};

export type StripeConnectResponse = {
  url: string;
};

export type SpotifyUserTokenRequest = {
  grant_type: string;
  code: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
};

export type SpotifyUser = {
  access_token: string;
  refresh_token?: string;
  expires_in: string;
  token_type: string;
  scope: string;
  callback?: any;
};

export type ArtistsSpotify = {
  external_urls?: {
    spotify?: string;
  };
  href: string;
  id: string;
  name: string;
  type: string;
  uri: string;
};
export type SpotifyTrack = {
  album?: {
    album_type?: string;
    artists?: ArtistsSpotify[];
    available_markets?: string[];
    external_urls: {
      spotify: string;
    };
    href: string;
    id: string;
    images: {
      height: number;
      url: string;
      width: number;
    }[];
    name: string;
    release_date: string;
    release_date_precision: string;
    total_tracks: number;
    type: string;
    uri: string;
  };
  artists?: ArtistsSpotify[];
  available_markets?: string[];
  disc_number?: number;
  duration_ms?: number;
  explicit?: boolean;
  external_ids?: {
    isrc?: string;
  };
  external_urls?: {
    spotify?: string;
  };
  href?: string;
  id: string;
  is_local: boolean;
  name: string;
  popularity: number;
  preview_url: string;
  track_number: number;
  type: string;
  uri: string;
};

export type UserState = {
  loading: boolean;
  moduleLoading?: boolean;
  loadingAutoSave: boolean;
  user?: User | null;
  talentCredential?: User | null;
  password?: boolean | null;
  error?: string | null;
  type?: string | null;
  gallery: { [key in string]: MediaUpload };
  tempTalentImages: TempImage;
  analytic: Analytics | null;
  analyticMonthly: Analytics | null;
  consumerCredential?: string | null;
  avalabilityCalendar: {
    [key in CALENDAR_PROVIDERS]: STATUS_CALENDAR_PROVIDERS;
  };
  errorConnectCalendar: string | null;
  phoneOTP?: PhoneOTP | null;
  emailOTP?: string | null;
  isUserExisted?: CheckUserExistedResult | null;
  exchangeRates?: ExchangeRate;
  exchangeRatesUSD?: ExchangeRate;
  isCheckLocalCurrency?: boolean | null;
  shopifyMerchants?: { [key in string]: ShopifyStore };
  talentProfileModules?: TalentProfileModule<TalentModuleMixItem>[];
  analyticsProfileModules?: TalentProfileModule<TalentModuleMixItem>[];
  previousModulesVersion?: TalentProfileModule<TalentModuleMixItem>[];
  talentProfileModulesOrigin?: TalentProfileModule<TalentModuleMixItem>[];
  defaultModulesVersion?: TalentProfileModule<TalentModuleMixItem>[];
  talentCollaborators?: TalentCollaborator[]; // = user.talentProfile.
  collaborator?: User | null;
  talentRevert?: boolean;
  isPublished?: boolean;
  isBlockingEdit?: boolean;
  isAutoSaved?: boolean;
  isAdminLogin?: boolean;
  isCollaborator?: boolean;
  loadingPublish?: boolean;
  localizationUnpublishIds?: (string | null)[];
  localizationSelected?: LocalizationItem;
};

export type ShopifyStore = {
  name?: string;
  accessToken: string;
  order: number;
};

export enum ROLE {
  Talent = "talent",
  User = "user",
  SelfSign = "selfsign",
  Admin = "admin",
  Moderator = "moderator",
  External = "external"
}

export type ExchangeRate = {
  localCurrency: string;
  symbol: string;
  decimalDigits: number;
  GBPExchangeRate: string;
};

export interface CurrentLocation {
  city?: string;
  country?: string;
  ip?: string;
  loc?: string;
  org?: string;
  postal?: string;
  region?: string;
  timezone?: string;
}

export enum SocialProfileLinkTypes {
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  MEDIUM = "MEDIUM",
  PINTEREST = "PINTEREST",
  REDDIT = "REDDIT",
  TWITCH = "TWITCH",
  SNAPCHAT = "SNAPCHAT",
  WEBSITE = "WEBSITE",
  YOUTUBE = "YOUTUBE",
  AMAZON_MUSIC = "AMAZON_MUSIC",
  APPLE_MUSIC = "APPLE_MUSIC",
  DEEZER = "DEEZER",
  SOUNDCLOUD = "SOUNDCLOUD",
  YOUTUBE_MUSIC = "YOUTUBE_MUSIC",
  SPOTIFY = "SPOTIFY",
  APPLE_PODCAST = "APPLE_PODCAST",
  AUDIBLE = "AUDIBLE",
  OVERCAST = "OVERCAST",
  STITCHER = "STITCHER",
  PANDORA = "PANDORA",
  TIDAL = "TIDAL",
  AUDIOMACK = "AUDIOMACK",
  NAPSTER = "NAPSTER",
  BANDCAMP = "BANDCAMP",
  QOBUZ = "QOBUZ",
  SIRIUSXM = "SIRIUSXM",
  GOOGLE_PLAY_MUSIC = "GOOGLE_PLAY_MUSIC",
  GAANA = "GAANA",
  BEATPORT = "BEATPORT",
  TIKTOK = "TIKTOK",
  IHEARTRADIO = "IHEARTRADIO",
  ANGHAMMI = "ANGHAMMI",
  MIXCLOUD = "MIXCLOUD",
  // digital download
  ITUNES_DIGITAL_DOWNLOAD = "ITUNES_DIGITAL_DOWNLOAD",
  AMAZONE_DIGITAL_DOWNLOAD = "AMAZONE_DIGITAL_DOWNLOAD",
  // physical purchase
  HMV = "HMV",
  TARGET = "TARGET",
  WALMART = "WALMART",
  URBAN_OUTFITTERS = "URBAN_OUTFITTERS",
  RECORD_STORE = "RECORD_STORE",
  AMAZONE_PHYSICAL_PURCHASE = "AMAZONE_PHYSICAL_PURCHASE",
  OFFICIAL_STORE = "OFFICIAL_STORE",
  BANQUET_RECORDS = "BANQUET_RECORDS",
  BEAR_TREE_RECORDS = "BEAR_TREE_RECORDS",
  BLEEP = "BLEEP",
  CRASH_RECORDS = "CRASH_RECORDS",
  DEEJAY_DE = "DEEJAY_DE",
  JUNO = "JUNO",
  DRIFT = "DRIFT",
  NORMAN_RECORDS = "NORMAN_RECORDS",
  PICCADILLY_RECORDS = "PICCADILLY_RECORDS",
  REDEYE = "REDEYE",
  RESIDENT = "RESIDENT",
  ROUGH_TRADE = "ROUGH_TRADE",
  VINILO = "VINILO",
  DISCORD = "DISCORD",
  EMAIL = "EMAIL",
  BANDSINTOWN = "BANDSINTOWN",
  CUSTOM_LINK = "customLink",
}
export interface SocialProfileLink {
  type: SocialProfileLinkTypes;
  link: string;
}

export interface SocialProfileLinkItem {
  edit?: boolean;
  icon: IconName;
  id: number;
  key: string;
  name?: string;
  value?: string;
  url?: string;
  hideTitle?: boolean;
  required?: boolean;
}

export interface FacebookPixelItem {
  id: string;
  isEdit: boolean;
  isCreate: boolean;
  order: number;
}
export interface ExportFanClubRequest {
  gender?: string;
  age?: {
    start: number;
    end: number;
  }[];
  country?: string[];
  signUpDateStart?: string;
  signUpDateEnd?: string;
}
export interface ExportDataFormRequest {
  moduleId?: string;
  formId?: string;
  country?: string[];
  fromDate?: string;
  toDate?: string;
}
export interface getFanClubCountResult {
  count: number;
}
export interface getDataFormCountResult {
  totalSubmissions: number;
}

export type BandsintownArtist = {
  thumb_url: string;
  mbid: string;
  facebook_page_url: string;
  image_url: string;
  tracker_count: number;
  tracking: any[];
  upcoming_event_count: number;
  url: string;
  support_url: string;
  name: string;
  options: { display_listen_unit: boolean };
  links: { type: string; url: string }[];
  id: string;
};

export type BandsintownArtistEvent = {
  offers: any[];
  venue: {
    country: string;
    city: string;
    latitude: string;
    name: string;
    location: string;
    region: string;
    longitude: string;
  };
  artist: BandsintownArtist;
  festival_datetime_display_rule: string;
  description: string;
  lineup: string[];
  festival_start_date: string;
  bandsintown_plus: boolean;
  title: string;
  artist_id: string;
  url: string;
  datetime: string;
  on_sale_datetime: string;
  id: string;
  festival_end_date: string;
};
export type SteatedEvent = {
  attributes: {
    "additional-info"?: string;
    "ends-at-date-local"?: string;
    "is-sold-out": boolean;
    "primary-button-text": string;
    "primary-link-url": string;
    "promoted-button-text": string;
    "promoted-link-url": string;
    "starts-at-date-local": string;
    "venue-address-line1": string;
    "venue-address-line2?": string;
    "venue-city": string;
    "venue-country": string;
    "venue-country-code": string;
    "venue-display-name": string;
    "venue-formatted-address": string;
    "venue-latitude": number;
    "venue-longitude": number;
    "venue-name": string;
    "venue-postal-code": string;
    "venue-state-or-province": string;
    "venue-time-zone": string;
  };
  id: string;
  type: string;
};
