import "./StripeConnectButton.scss";
import React, { useEffect, useState } from "react";
import { userService } from "services";
import { Button, ButtonProps, Row } from "antd";
import { useTypedSelector } from "redux/rootReducer";
import { selectUserData } from "redux/User/selector";
import { ReactComponent as CheckIcon } from "icons/check-circle.svg";
import { ReactComponent as UncheckIcon } from "icons/report-bg-red.svg";
import classNames from "classnames";
import { selectPayoutSchedule } from "redux/Payout/selector";
import Tooltip from "antd/lib/tooltip";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { getPayoutScheduleActions } from "redux/Payout/actions";

interface StripeConnectButtonProps extends ButtonProps {
  tooltipSupport?: boolean;
  callbackUrl?: string;
  hideIcon?: boolean;
  windownTarget?: string;
  className?: string;
}

const StripeConnectButtonTooltipWrapper: React.FC<any> = ({
  showTooltip = false,
  children,
}) => {
  return (
    <React.Fragment>
      {showTooltip ? (
        <Tooltip
          style={{ zIndex: 9999999 }}
          className="payout-tooltip"
          placement="bottom"
          title="More information is required to process payments."
        >
          {children}
        </Tooltip>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </React.Fragment>
  );
};

const StripeConnectButton: React.FC<StripeConnectButtonProps> = ({
  callbackUrl,
  tooltipSupport = true,
  hideIcon = false,
  className,
  windownTarget = "_self",
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [stripeConnectUrl, setStripeConnectUrl] = useState("");

  const dispatch = useDispatch();
  const user = useTypedSelector(selectUserData);
  const payoutSchedule = useTypedSelector(selectPayoutSchedule);

  useEffect(() => {
    if (!user || !user?.localCurrency) return;

    const fetchStripeConnectUrl = async () => {
      try {
        setLoading(true);
        const result = await userService.stripeConnect({
          callbackUrl: callbackUrl ? callbackUrl : window.location.href,
        });
        if (!!result && "ok" in result) {
          if (user?.isConnectedStripeAccount) {
            setStripeConnectUrl(result.response.url + "#/account");
          } else {
            setStripeConnectUrl(result.response.url);
          }
        }
      } catch (ex) {
        console.error("fetchStripeConnectUrl error:", ex);
      } finally {
        setLoading(false);
      }
    };

    const fetchPayoutSchedule = () =>
      dispatch(getPayoutScheduleActions.REQUEST());

    fetchStripeConnectUrl();
    fetchPayoutSchedule();
  }, [user, callbackUrl, dispatch]);

  const connectStripe = () => {
    window.open(stripeConnectUrl, windownTarget);
  };

  const checkIconClass = classNames({
    "verified-icon": true,
    "verified-icon-small": props.size === "small",
  });

  const isShowTooltip = useMemo(() => {
    if (
      user?.isConnectedStripeAccount &&
      (!payoutSchedule?.payoutsEnabled || !payoutSchedule?.paymentEnabled)
    )
      return true;
    return false;
  }, [payoutSchedule, user]);

  return (
    <StripeConnectButtonTooltipWrapper
      showTooltip={tooltipSupport && isShowTooltip}
    >
      <Button
        className={classNames({
          "stripe-connect-btn": true,
          [className || ""]: true,
        })}
        loading={loading}
        onClick={connectStripe}
        type={user?.isConnectedStripeAccount ? undefined : "primary"}
        {...props}
      >
        {!hideIcon && (
          <React.Fragment>
            <Row align="middle">
              {user?.isConnectedStripeAccount &&
                (payoutSchedule?.payoutsEnabled &&
                payoutSchedule?.paymentEnabled ? (
                  <CheckIcon className={checkIconClass} />
                ) : (
                  <UncheckIcon className={checkIconClass} />
                ))}
            </Row>
          </React.Fragment>
        )}

        {user?.isConnectedStripeAccount
          ? "View payout account"
          : "Setup payout details"}
      </Button>
    </StripeConnectButtonTooltipWrapper>
  );
};

export default StripeConnectButton;
