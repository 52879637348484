import { Icon } from "components/Icon";
import React, { useMemo } from "react";
import { NoticeType } from "utils/notification";

interface Props {
  type?: NoticeType;
  width?: number;
  height?: number;
}

const NotificationIcon: React.FC<Props> = ({ type, ...props }) => {
  const nameIcon = useMemo(() => {
    switch (type) {
      case "success":
        return "noti-success";
      case "error":
        return "noti-error";
      case "warning":
        return "noti-warning";
      default:
        return "noti-warning";
    }
  }, [type]);
  return (
    <React.Fragment>
      <Icon name={nameIcon} {...props} />
    </React.Fragment>
  );
};

export default NotificationIcon;
