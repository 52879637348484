import { Response, ListQueryParams, Pagination } from "../redux/Common/types";
import {
  CreatePurchasingOptionsRequest,
  PurchasingOptions,
} from "./../redux/PurchasingOptions/types";
import { Api } from "./api";

interface IPurchasingOptionsService {
  createPurchasingOptions(
    payload: CreatePurchasingOptionsRequest
  ): Promise<Response<PurchasingOptions>>;
  getPurchasingOptions(
    params: ListQueryParams
  ): Promise<Response<Pagination<PurchasingOptions[]>>>;
  updatePurchasingOptions(
    payload: PurchasingOptions
  ): Promise<Response<PurchasingOptions>>;
  getPurchasingOptionsById(
    id: string
  ): Promise<Response<Pagination<PurchasingOptions>>>;
}

export default class PurchasingOptionsService
  implements IPurchasingOptionsService
{
  constructor(private readonly api: Api) {
    this.api = api;
  }

  createPurchasingOptions = (
    payload: CreatePurchasingOptionsRequest
  ): Promise<Response<PurchasingOptions>> => {
    return this.api.createPurchasingOptions(payload);
  };

  getPurchasingOptions = (
    params: ListQueryParams
  ): Promise<Response<Pagination<PurchasingOptions[]>>> => {
    return this.api.getPurchasingOptions(params);
  };

  updatePurchasingOptions = (
    payload: any
  ): Promise<Response<PurchasingOptions>> => {
    return this.api.updatePurchasingOptions(payload);
  };

  getPurchasingOptionsById = (
    id: string
  ): Promise<Response<Pagination<PurchasingOptions>>> => {
    return this.api.getPurchasingOptionsById(id);
  };
}
