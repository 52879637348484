import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { experienceService } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import { getExperiencesScheduledActions } from "./actions";
import { GetExperiencesScheduled } from "./types";

function* getExperiencesScheduled({
  payload,
}: Action<GetExperiencesScheduled>) {
  const result: any = yield* call(
    experienceService.getExperiencesScheduled,
    payload
  );

  if (result.ok) {
    yield* put(getExperiencesScheduledActions.SUCCESS(result.response));
  } else {
    yield* put(getExperiencesScheduledActions.FAILURE(result.message));
  }
}

export default function* experienceScheduledSagas(): SagaIterator {
  yield* takeLatest(
    getExperiencesScheduledActions.REQUEST,
    getExperiencesScheduled
  );
}
