import { Experience } from "redux/Experience/types";
import { IMAGE_REG } from "constants/regexp";

export const getFirstAvatar = (experience: Experience): string => {
  if (!experience) return "";

  let result = "";

  if (!experience.covers) return result;

  for (const val of experience.covers) {
    if (IMAGE_REG.test(val)) {
      result = val;
      break;
    }
  }

  return result;
};

export const getAvatarName = (name: string) => {
  if (name) {
    const elements = name.split(" ");
    return elements.length > 0
      ? elements
          .map((item) => (item.length ? item[0].toUpperCase() : ""))
          .join("")
      : name[0];
  }
  return "";
};
