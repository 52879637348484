import { message } from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { talentService } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import { ExportFanClubRequest } from "../User/types";
import { ExportDataFormRequest } from "./../User/types";
import {
  dataFormCountAction,
  exportDataCaptureFormAction,
  exportFanClubAction,
  fanClubCountAction,
  getListDataFormAction,
  signupTalentActions,
  verifyBecomeTalentOtpActions,
  getListPreReleaseAction,
  exportPreReleaseAction,
  preReleaseCountAction,
} from "./actions";
import {
  CreateState,
  FinishTalentProfileRequest,
  TalentProfileRequest,
  VerifyBecomeTalentOtpRequest,
} from "./types";

function* signUpTalent({
  payload,
}: Action<{
  state: CreateState;
  data: TalentProfileRequest | FinishTalentProfileRequest;
}>) {
  let result: any;

  switch (payload.state) {
    case CreateState.CREATE:
      result = yield* call(
        talentService.createTalentProfile,
        payload.data as TalentProfileRequest
      );
      break;

    case CreateState.FINISH:
      result = yield* call(
        talentService.finishTalentProfile,
        payload.data as FinishTalentProfileRequest
      );
  }

  if (result.ok) {
    const { response } = result;
    yield* put(signupTalentActions.SUCCESS(response));
  } else {
    yield* put(signupTalentActions.FAILURE(result.message));
  }
}

function* verifyBecomeTalentOtp({
  payload,
}: Action<VerifyBecomeTalentOtpRequest>) {
  const result: any = yield* call(talentService.verifyBecomeTalentOtp, payload);
  if (result.ok) {
    const { response } = result;
    yield* put(verifyBecomeTalentOtpActions.SUCCESS(response));
  } else {
    yield* put(
      verifyBecomeTalentOtpActions.FAILURE(
        "The code you've provided is incorrect, please try again"
      )
    );
  }
}
function* getFanClubCount({ payload }: Action<ExportFanClubRequest>) {
  const result: any = yield* call(talentService.getFanClubCount, payload);
  if (result.ok) {
    const { response } = result;
    yield* put(fanClubCountAction.SUCCESS(response));
  } else {
    yield* put(verifyBecomeTalentOtpActions.FAILURE(result.message));
  }
}

function* getDataFormCount({ payload }: Action<ExportDataFormRequest>) {
  const result: any = yield* call(talentService.getDataFormCount, payload);
  if (result.ok) {
    const { response } = result;
    yield* put(dataFormCountAction.SUCCESS(response));
  } else {
    yield* put(dataFormCountAction.FAILURE(result.message));
  }
}

function* getListDataForm() {
  const result: any = yield* call(talentService.getListDataForm);
  if (result.ok) {
    const { response } = result;
    yield* put(getListDataFormAction.SUCCESS(response));
  } else {
    yield* put(getListDataFormAction.FAILURE(result.message));
  }
}

function* getListPreRelease() {
  const result: any = yield* call(talentService.getListPreRelease);
  if (result.ok) {
    const { response } = result;
    yield* put(getListPreReleaseAction.SUCCESS(response));
  } else {
    yield* put(getListPreReleaseAction.FAILURE(result.message));
  }
}

function* getPreReleaseCount({ payload }: Action<ExportDataFormRequest>) {
  const result: any = yield* call(talentService.getPreReleaseCount, payload);
  if (result.ok) {
    const { response } = result;
    yield* put(preReleaseCountAction.SUCCESS(response));
  } else {
    yield* put(preReleaseCountAction.FAILURE(result.message));
  }
}

function* exportPreRelease({ payload }: Action<ExportDataFormRequest>) {
  message.loading("Downloading");
  const result: any = yield* call(talentService.exportPreReleaseCSV, payload);
  if (result.ok) {
    const { response } = result;
    fileDownload(
      response.data,
      `pre-release-report-${moment().toISOString()}.csv`
    );
  } else {
    yield* put(exportPreReleaseAction.FAILURE(result.message));
  }
  message.destroy();
}

function* exportFanClub({ payload }: Action<ExportFanClubRequest>) {
  message.loading("Downloading");
  const result: any = yield* call(talentService.exportFanClubCSV, payload);
  if (result.ok) {
    const { response } = result;
    fileDownload(
      response.data,
      `fan-club-report-${moment().toISOString()}.csv`
    );
  } else {
    yield* put(exportFanClubAction.FAILURE(result.message));
  }
  message.destroy();
}

function* exportDataCaptureFrom({ payload }: Action<ExportDataFormRequest>) {
  message.loading("Downloading");
  const result: any = yield* call(talentService.exportDataCaptureCSV, payload);
  if (result.ok) {
    const { response } = result;
    fileDownload(
      response.data,
      `data-capture-report-${moment().toISOString()}.csv`
    );
  } else {
    yield* put(exportFanClubAction.FAILURE(result.message));
  }
  message.destroy();
}

export default function* userSagas(): SagaIterator {
  yield* takeLatest(signupTalentActions.REQUEST, signUpTalent);
  yield* takeLatest(
    verifyBecomeTalentOtpActions.REQUEST,
    verifyBecomeTalentOtp
  );
  yield* takeLatest(fanClubCountAction.REQUEST, getFanClubCount);
  yield* takeLatest(dataFormCountAction.REQUEST, getDataFormCount);
  yield* takeLatest(exportFanClubAction.REQUEST, exportFanClub);
  yield* takeLatest(exportDataCaptureFormAction.REQUEST, exportDataCaptureFrom);
  yield* takeLatest(getListDataFormAction.REQUEST, getListDataForm);
  yield* takeLatest(getListPreReleaseAction.REQUEST, getListPreRelease);
  yield* takeLatest(preReleaseCountAction.REQUEST, getPreReleaseCount);
  yield* takeLatest(exportPreReleaseAction.REQUEST, exportPreRelease);
}
