import { useEffect, useState } from "react";
import { useVideoContext } from "./useVideoContext";
import { ROOM_CONNECTION_STATUS } from "constants/room";

export const useRoomState = () => {
  const { room } = useVideoContext();
  const [state, setState] = useState<ROOM_CONNECTION_STATUS>(
    ROOM_CONNECTION_STATUS.Disconnected
  );

  useEffect(() => {
    const setRoomState = () =>
      setState((room.state || "disconnected") as ROOM_CONNECTION_STATUS);
    setRoomState();
    room
      .on("disconnected", setRoomState)
      .on("reconnected", setRoomState)
      .on("reconnecting", setRoomState);
    return () => {
      room
        .off("disconnected", setRoomState)
        .off("reconnected", setRoomState)
        .off("reconnecting", setRoomState);
    };
  }, [room]);

  return state;
};
