import { PodcastSyncLatestParams, RequestTalentProfileRequest } from './../types/index';
import {
  ExportDataFormRequest,
  getDataFormCountResult,
} from "./../redux/User/types";
import { Response } from "./../redux/Common/types";
import { Api } from "./api";
import {
  TalentProfileRequest,
  FinishTalentProfileRequest,
  GetAnalyticRequest,
  Analytics,
  VerifyBecomeTalentOtpRequest,
  DataForm,
} from "redux/Talent/types";
import {
  CALENDAR_PROVIDERS,
  ExportFanClubRequest,
  getFanClubCountResult,
} from "redux/User/types";
import {
  CreateTalentProfileModuleRequest,
  TalentModuleMixItem,
  ReorderTalentProfileModuleRequest,
  TalentProfileModule,
  UpdateTalentProfileModuleRequest,
  publishProfileRequest,
  DeleteLocalizationRequest,
  autoSaveProfileRequest,
} from "models/talent/talent-profile-module.model";
import {
  CancelInviteCollaboratorRequest,
  InviteCollaboratorRequest,
  ResendInviteCollaboratorRequest,
  RevokeInviteCollaboratorRequest,
} from "models/talent/talent-profile-collaborator.model";
import { YoutubeParams } from "types/youtube";
import { MetaPixelParams, PodcastValidateParams } from "types";

interface ITalentService {
  getTalentProfileById(id: string): Promise<Response<any>>;
  updateTalentProfileById(
    id: string,
    payload: TalentProfileRequest
  ): Promise<Response<any>>;
  createTalentProfile(payload: TalentProfileRequest): Promise<Response<any>>;
  updateTalentProfile(payload: TalentProfileRequest): Promise<Response<any>>;
  finishTalentProfile(
    payload: FinishTalentProfileRequest
  ): Promise<Response<any>>;
  getAvailabilityCalendars(): Promise<Response<any>>;
  connectCalendarProvider(payload: {
    provider_name: string;
    redirect_uri: string;
  }): Promise<Response<any>>;
  disconnectCalendarProvider(
    providerName: CALENDAR_PROVIDERS
  ): Promise<Response<any>>;
  authorizationTokenCalendarProvider(payload: {
    code: string;
    redirect_uri: string;
  }): Promise<Response<any>>;

  //talent profile module
  createTalentProfileModule(
    payload: CreateTalentProfileModuleRequest<TalentModuleMixItem>
  ): Promise<Response<any>>;
  updateTalentProfileModule(
    payload: UpdateTalentProfileModuleRequest<TalentModuleMixItem>
  ): Promise<Response<any>>;
  reorderTalentProfileModule(
    payload: ReorderTalentProfileModuleRequest[]
  ): Promise<Response<any>>;
  deleteTalentProfileModule(
    payload: TalentProfileModule<TalentModuleMixItem>
  ): Promise<Response<any>>;

  // talent collaborator
  getCollaborator(): Promise<Response<any>>;
  inviteCollaborator(
    payload: InviteCollaboratorRequest
  ): Promise<Response<any>>;
  cancelInviteCollaborator(
    payload: CancelInviteCollaboratorRequest
  ): Promise<Response<any>>;
  revokeInviteCollaborator(
    payload: RevokeInviteCollaboratorRequest
  ): Promise<Response<any>>;
  getFanClubCount(
    payload: ExportFanClubRequest
  ): Promise<Response<getFanClubCountResult>>;
  exportFanClubCSV(payload: ExportFanClubRequest): Promise<Response<any>>;
}

export default class TalentService implements ITalentService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getTalentProfileById = (id: string) => {
    return this.api.getTalentProfileById(id);
  };

  updateTalentProfileById = (id: string, payload: TalentProfileRequest) => {
    return this.api.updateTalentProfileById(id, payload);
  };

  createTalentProfile = (
    payload: TalentProfileRequest
  ): Promise<Response<any>> => {
    return this.api.createTalentProfile(payload);
  };

  updateTalentProfile = (
    payload: TalentProfileRequest
  ): Promise<Response<any>> => {
    return this.api.updateTalentProfile(payload);
  };

  finishTalentProfile = (
    payload: FinishTalentProfileRequest
  ): Promise<Response<any>> => {
    return this.api.finishTalentProfile(payload);
  };

  getTalentAnalytic = (
    payload: GetAnalyticRequest
  ): Promise<Response<Analytics>> => {
    return this.api.getTalentAnalytic(payload);
  };

  getAvailabilityCalendars = (): Promise<Response<any>> => {
    return this.api.getCalendars();
  };

  connectCalendarProvider = (payload: {
    provider_name: string;
    redirect_uri: string;
    state: string;
  }): Promise<Response<any>> => {
    return this.api.calendarCronofyRequestAuthorizationCode(payload);
  };

  disconnectCalendarProvider = (
    providerName: CALENDAR_PROVIDERS
  ): Promise<Response<any>> => {
    return this.api.revokeCalendarCronofy(providerName);
  };

  authorizationTokenCalendarProvider = (payload: {
    redirect_uri: string;
    code: string;
  }): Promise<Response<any>> => {
    return this.api.calendarCronofyRequestAuthorizationToken(payload);
  };

  verifyBecomeTalentOtp = (
    payload: VerifyBecomeTalentOtpRequest
  ): Promise<Response<any>> => {
    return this.api.verifyBecomeTalentOtp(payload);
  };

  // talent profile module
  createTalentProfileModule = (
    payload: CreateTalentProfileModuleRequest<TalentModuleMixItem>
  ) => {
    return this.api.createTalentProfileModule(payload);
  };

  updateTalentProfileModule = (
    payload: UpdateTalentProfileModuleRequest<TalentModuleMixItem>
  ) => {
    return this.api.updateTalentProfileModule(payload);
  };

  deleteTalentProfileModule = (
    payload: TalentProfileModule<TalentModuleMixItem>
  ) => {
    return this.api.deleteTalentProfileModule(payload);
  };

  reorderTalentProfileModule = (
    payload: ReorderTalentProfileModuleRequest[]
  ) => {
    return this.api.reorderTalentProfileModule(payload);
  };

  // talent collaborator
  getCollaborator = () => {
    return this.api.getCollaborator();
  };

  inviteCollaborator = (payload: InviteCollaboratorRequest) => {
    return this.api.inviteCollaborator(payload);
  };

  cancelInviteCollaborator = (payload: CancelInviteCollaboratorRequest) => {
    return this.api.cancelInviteCollaborator(payload);
  };

  revokeInviteCollaborator = (payload: RevokeInviteCollaboratorRequest) => {
    return this.api.revokeInviteCollaborator(payload);
  };

  resendInviteCollaborator = (payload: ResendInviteCollaboratorRequest) => {
    return this.api.resendInviteCollaborator(payload);
  };
  getFanClubCount = (
    payload: ExportFanClubRequest
  ): Promise<Response<getFanClubCountResult>> => {
    return this.api.getFanClubCount(payload);
  };
  getDataFormCount = (
    payload: ExportDataFormRequest
  ): Promise<Response<getDataFormCountResult>> => {
    return this.api.getDataFormCount(payload);
  };
  getListDataForm = (): Promise<Response<DataForm[]>> => {
    return this.api.getListDataForm();
  };
  exportFanClubCSV = (payload: ExportFanClubRequest) => {
    return this.api.exportFanClubCSV(payload);
  };
  exportDataCaptureCSV = (payload: ExportDataFormRequest) => {
    return this.api.exportDataCaptureCSV(payload);
  };

  getDataCaptureForms = () => {
    return this.api.getDataCaptureForm();
  };

  getListPreRelease = (): Promise<Response<DataForm[]>> => {
    return this.api.getListPreRelease();
  };

  getPreReleaseCount = (
    payload: ExportDataFormRequest
  ): Promise<Response<getDataFormCountResult>> => {
    return this.api.getPreReleaseCount(payload);
  };

  exportPreReleaseCSV = (payload: any) => { //TODO fix headers
    return this.api.exportPreReleaseCSV(payload);
  };

  autoSaveProfile = (payload: autoSaveProfileRequest) => {
    return this.api.autoSaveProfile(payload);
  };
  publicProfile = (payload: publishProfileRequest) => {
    return this.api.publishProfile(payload);
  };

  deleteLocalization = (payload: DeleteLocalizationRequest) => {
    return this.api.deleteLocalization(payload);
  };

  getLocalizationModules = (
    talentProfileId: string,
    localizationId: string
  ) => {
    return this.api.getLocalizationModules(talentProfileId, localizationId);
  };
  getYoutubeCollection = (params: YoutubeParams) => {
    return this.api.getYoutubeCollections(params);
  };
  validateMetaPixel = (params: MetaPixelParams) => {
    return this.api.validateMetaPixel(params);
  };
  validatePodcastLinks = (params: PodcastValidateParams) => {
    return this.api.validatePodcastLinks(params);
  };
  getSyncLatestPodcasts = (params: PodcastSyncLatestParams) => {
    return this.api.getSyncLatestPodcasts(params);
  };

  requestTalentProfile = (payload: RequestTalentProfileRequest): Promise<Response<any>> => {
    return this.api.requestTalentProfile(payload);
  };
}
