import moment from "moment";
import momentTZ from "moment-timezone";
import { DateOff } from "redux/Experience/types";
import { TIME_ZONE_DATA } from "../redux/Common/types";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "a few secs",
    ss: "%d secs",
    m: "a min",
    mm: "%d mins",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

export const date = moment;
export const formatDate = (date: any, format: string) =>
  moment(date).format(format);
export const dayAgo = (date: any) => {
  const now = moment(new Date());
  const end = moment(date); // another date
  const duration = moment.duration(now.diff(end));
  const days = duration.asDays();
  return days;
};

export const formatMinuteSecond = (second: number) => {
  const time = moment.utc(second * 1000);
  return time.hours() > 0 ? time.format("hh:mm:ss") : time.format("mm:ss");
};

export const formatHourMinuteSecond = (second: number) => {
  if (second <= 0) return "00:00";
  return moment
    .utc(second * 1000)
    .format(second >= 3600 ? "HH:mm:ss" : "mm:ss");
};

export const formatMinuteSecondWithUnit = (value: number): string => {
  if (!value) return "";
  const hour = Math.floor(value / (60 * 60));
  const minute =
    hour * 60 * 60 < value ? Math.floor((value - hour * 60 * 60) / 60) : 0;
  const second =
    minute * 60 + hour * 60 * 60 < value
      ? value - (minute * 60 + hour * 60 * 60)
      : 0;

  return `${hour !== 0 ? `${hour >= 10 ? hour : `0${hour}`}` + "h " : ""}${
    minute !== 0 ? `${minute >= 10 ? minute : `0${minute}`}` + "m " : ""
  }${second === 0 ? "" : second + "s"}`;
};

export const formatTime = (date: Date) => moment(date).format("hh:mm a");

export const getDiffTime = (startDate: Date, endDate: Date): number => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.isBefore(start) ? 0 : moment.duration(end.diff(start)).asMinutes();
};

export const isDateOffs = (
  date: moment.Moment,
  dateOffs: DateOff[]
): DateOff | undefined => {
  const dateTz = momentTZ(date).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return dateOffs?.find(({ date }) => {
    return moment(date).toISOString() === dateTz.toISOString();
  });
};

export const formatMinsAndSecs = (second: number) => {
  const mins = Math.floor(second / 60);
  const secs = second % 60;

  return `${mins > 0 ? (mins === 1 ? `${mins} min` : `${mins} mins`) : ""} ${
    secs > 0 ? (secs === 1 ? `${secs} sec` : `${secs} secs`) : ""
  }`;
};

export const formatMins = (second: number) => {
  const mins = Math.floor(second / 60);
  return mins > 0 ? (mins === 1 ? `${mins} Min` : `${mins} Mins`) : "";
};

export const convertTZ = (date: any, tzString: string) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};
export const getTimezoneLabel = (timezone?: string) => {
  if (!timezone) {
    return TIME_ZONE_DATA[0].data[0];
  }
  const select = TIME_ZONE_DATA.find(
    (item) => !!item.data.find((el) => el.tz === timezone)
  );
  return (
    select?.data.find((el) => el.tz === timezone) || TIME_ZONE_DATA[0].data[0]
  );
};
export const sortExperiencesByDate = (experiences: any[] | undefined = []) => {
  let scheduleList = [...experiences]?.filter(
    (experience) => experience?.scheduleTime
  );
  let createdAtList = [...experiences]?.filter(
    (experience) => !experience?.scheduleTime
  );

  scheduleList = scheduleList?.sort((experienceA, experienceB) => {
    const dateA = moment(experienceA?.scheduleTime);
    const dateB = moment(experienceB?.scheduleTime);

    return dateA.isBefore(dateB) ? -1 : 1;
  });

  createdAtList = createdAtList?.sort((experienceA, experienceB) => {
    const dateA = moment(experienceA?.createdAt);
    const dateB = moment(experienceB?.createdAt);

    return dateA.isBefore(dateB) ? -1 : 1;
  });

  return [...scheduleList, ...createdAtList];
};
export function validURL(str: string) {
  const pattern = new RegExp(
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i
  ); // fragment locator
  return !!pattern.test(str);
}

export const isSameDay = (d1: moment.Moment, d2: moment.Moment): boolean => {
  const compare =
    d1.year() === d2.year() &&
    d1.month() === d2.month() &&
    d1.date() === d2.date();
  return compare;
};
