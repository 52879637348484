import { ApisauceInstance, create } from "apisauce";
import { getGeneralApiProblem } from "services/api/apiProblem";
import { ApiConfig, DEFAULT_BANDSINTOWN_API_CONFIG } from "./api.config";
import { BandsintownItem } from "models/talent/talent-profile-module.model";
import config from "config";

const BANDSINTOWN_MASK =
  config.bandsintown.mask || "";

export class BandsintownApi {
  api: ApisauceInstance;

  constructor(config: ApiConfig = DEFAULT_BANDSINTOWN_API_CONFIG) {
    this.api = create({
      baseURL: config.url,
      timeout: config.timeout,
    });
  }

  async getArtistById(id: number): Promise<any> {
    // this url will hack cache from spotify.
    const response = await this.api.get(`/artists/id_${id}/`, {
      app_id: this.getAppId(id),
    });

    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  async getArtistEventsByName(
    item: BandsintownItem,
    { date }: { date: string }
  ): Promise<any> {

    let id = Number(item?.metadata?.id);
    let url = `/artists/id_${id}/events/`

    if (!Number.isInteger(id)) {
      const name = item?.metadata?.name || "";
      url = `/artists/id_${encodeURIComponent(name.replace("'", ""))}/events/`
      id = 0
    }

    const response = await this.api.get(
      url,
      {
        app_id: this.getAppId(id),
        date,
      }
    );

    if (!response.ok) {
      return getGeneralApiProblem(response);
    }

    return { ok: response.ok, response: response.data };
  }

  getAppId(artistId: number): string {
    const strArtistId = `${artistId}`;
    const { length } = strArtistId;
    const REGEXP_LAST_N_CHARS = new RegExp(`.{${length}}$`);

    return BANDSINTOWN_MASK.replace(REGEXP_LAST_N_CHARS, strArtistId);
  }
}

export default new BandsintownApi();
