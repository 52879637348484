import { Action, handleActions } from "redux-actions";
import {
  getTimeRangesInMonthActions,
  updateTimeRangeActions,
  createTimeRangeActions,
  deleteTimeRangeActions,
  createDateOffActions,
  deleteDateOffActions,
  copyTimeRangeActions,
  deleteWeekDayActions,
  setRepeatTimeRangeActions,
  removeRepeatTimeRangeActions,
} from "./actions";
import { TimeRangeState } from "./types";

const handlers = {
  // get slots of a month
  [getTimeRangesInMonthActions.REQUEST]: (
    state: TimeRangeState
  ): TimeRangeState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getTimeRangesInMonthActions.SUCCESS]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    timeRanges: payload,
    loading: false,
  }),
  [getTimeRangesInMonthActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create slot
  [createTimeRangeActions.REQUEST]: (
    state: TimeRangeState
  ): TimeRangeState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [createTimeRangeActions.SUCCESS]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => {
    return {
      ...state,
      timeRange: payload,
      loading: false,
    };
  },
  [createTimeRangeActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update slot
  [updateTimeRangeActions.REQUEST]: (
    state: TimeRangeState
  ): TimeRangeState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [updateTimeRangeActions.SUCCESS]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => {
    return {
      ...state,
      timeRange: payload,
      loading: false,
    };
  },
  [updateTimeRangeActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // copy time range
  [copyTimeRangeActions.REQUEST]: (state: TimeRangeState): TimeRangeState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [copyTimeRangeActions.SUCCESS]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => {
    return {
      ...state,
      timeRange: payload,
      loading: false,
    };
  },
  [copyTimeRangeActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // delete slot
  [deleteTimeRangeActions.REQUEST]: (
    state: TimeRangeState
  ): TimeRangeState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [deleteTimeRangeActions.SUCCESS]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => {
    return {
      ...state,
      timeRange: payload,
      loading: false,
    };
  },
  [deleteTimeRangeActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [createDateOffActions.REQUEST]: (state: TimeRangeState): TimeRangeState => ({
    ...state,
    error: null,
    dateOffSubmit: null,
    loading: true,
  }),
  [createDateOffActions.SUCCESS]: (state: TimeRangeState): TimeRangeState => {
    return {
      ...state,
      dateOffSubmit: true,
      loading: false,
    };
  },
  [createDateOffActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    dateOffSubmit: false,
    loading: false,
  }),
  [deleteDateOffActions.REQUEST]: (state: TimeRangeState): TimeRangeState => ({
    ...state,
    error: null,
    dateOffSubmit: null,
    loading: true,
  }),
  [deleteDateOffActions.SUCCESS]: (state: TimeRangeState): TimeRangeState => {
    return {
      ...state,
      dateOffSubmit: true,
      loading: false,
    };
  },
  [deleteDateOffActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    dateOffSubmit: false,
    loading: false,
  }),
  // delete weekday
  [deleteWeekDayActions.REQUEST]: (state: TimeRangeState): TimeRangeState => ({
    ...state,
    error: null,
    timeRange: null,
    loading: true,
  }),
  [deleteWeekDayActions.SUCCESS]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => {
    return {
      ...state,
      timeRange: payload,
      loading: false,
    };
  },
  [deleteWeekDayActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    timeRange: null,
    loading: false,
  }),
  // set repeat time range
  [setRepeatTimeRangeActions.REQUEST]: (
    state: TimeRangeState
  ): TimeRangeState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [setRepeatTimeRangeActions.SUCCESS]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => {
    return {
      ...state,
      timeRange: payload,
      loading: false,
    };
  },
  [setRepeatTimeRangeActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    timeRange: null,
    loading: false,
  }),
  // remove repeat time range
  [removeRepeatTimeRangeActions.REQUEST]: (
    state: TimeRangeState
  ): TimeRangeState => ({
    ...state,
    error: null,
    timeRange: null,
    loading: true,
  }),
  [removeRepeatTimeRangeActions.SUCCESS]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => {
    return {
      ...state,
      timeRange: payload,
      loading: false,
    };
  },
  [removeRepeatTimeRangeActions.FAILURE]: (
    state: TimeRangeState,
    { payload }: Action<any>
  ): TimeRangeState => ({
    ...state,
    error: payload,
    timeRange: null,
    loading: false,
  }),
};

const initialState: TimeRangeState = {
  error: null,
  loading: false,
  timeRanges: null,
  timeRange: null,
  dateOffSubmit: null,
};

export const timeRangeReducer = handleActions<TimeRangeState>(
  handlers,
  initialState
);
