import React, { ReactNode, useEffect } from "react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import config from "config";

const growthbook = new GrowthBook({
  apiHost: config.flags.host,
  clientKey: config.flags.key,
  enableDevMode: config.service.env !== "production",
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

export interface FeatureProviderProps {
  children: ReactNode;
}

export const FeatureProvider = ({ children }: FeatureProviderProps) => {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures({ autoRefresh: true });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export default FeatureProvider;
