import React from "react";
import { useTypedSelector } from "redux/rootReducer";
import { selectProfileBuilderSubscription } from "redux/User/selector";
import {
  SubscriptionDetails,
  subscriptionTrialForUser,
} from "utils/subscription";
import { SSXSubscriptionStage } from "constants/trial";
import { useEffect } from "react";
import SubscriptionBanner from "components/SubscriptionBanner";
import config from "config";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { DCA_ALLOWANCE_BANNERS_FLAG } from "constants/feature-flags";

export interface PreviewLayoutBannerProps {
  url: string;
  setBannerDisplayed: (value: boolean) => void;
  isSubscribed: boolean;
  isExceedingContacts: boolean;
}

const enum LEVEL {
  INFO = `info`,
  WARNING = `warning`,
  ERROR = `error`,
}

const exceededContactsOverageMessageText = `You are over your contact limit. Overage charges will apply for any additional contacts.`;
const exceededContactsFreePlanMessageText = `You are over your contact limit.  Only the first 500 contacts can be accessed, segmented or contacted on the free plan.`;

function getSsxBannerProps(
  props: SubscriptionDetails
): { message: string; level: LEVEL } | null {
  const {
    stage,
    trialRemainingDaysFormatted,
    subscriptionTrialEndAtFormatted,
  } = props;

  switch (stage) {
    case SSXSubscriptionStage.Early:
      return {
        message: `Your free trial expires ${trialRemainingDaysFormatted}. Start a 
              subscription now – you only get charged when your free trial ends.`,
        level: LEVEL.INFO,
      };

    case SSXSubscriptionStage.Late:
      return {
        message: `Your free trial expires ${trialRemainingDaysFormatted}. Set up a
            subscription now – you will not be charged until your free trial
            expires on ${subscriptionTrialEndAtFormatted}.`,
        level: LEVEL.WARNING,
      };

    case SSXSubscriptionStage.Expired:
      return {
        message: `Your free trial expired. Your URL is no longer accessible and changes
            to your profile cannot be published.`,
        level: LEVEL.ERROR,
      };

    case SSXSubscriptionStage.Inactive:
      return {
        message: `Your subscription is inactive. Your URL is no longer accessible and
            changes to your profile cannot be published.`,
        level: LEVEL.ERROR,
      };

    default:
      return null;
  }
}

const PreviewLayoutBanner = ({
  url,
  setBannerDisplayed,
  isSubscribed,
  isExceedingContacts,
}: PreviewLayoutBannerProps) => {
  const subscription = subscriptionTrialForUser(
    useTypedSelector(selectProfileBuilderSubscription)!
  );
  const isDcaAllowanceOn = config.dca.flags.banners;

  useEffect(() => {
    if (ssxBannerProps || isUserExceedingContactsOnAudiencesCampaigns) {
      setBannerDisplayed(true);
    }
  }, [url, subscription.stage]);

  const ssxBannerProps = getSsxBannerProps(subscription);
  const isUserExceedingContactsOnAudiencesCampaigns =
    url === "/audience-campaigns" && isExceedingContacts;

  if (subscription.stage === SSXSubscriptionStage.None) {
    setBannerDisplayed(false);
    return null;
  }

  if (ssxBannerProps) {
    return (
      <SubscriptionBanner
        messageText={ssxBannerProps.message}
        level={ssxBannerProps.level}
        closeable={false}
        setBannerDisplayed={setBannerDisplayed}
      />
    );
  }

  if (isUserExceedingContactsOnAudiencesCampaigns && isDcaAllowanceOn) {
    if (isSubscribed) {
      return (
        <SubscriptionBanner
          messageText={exceededContactsOverageMessageText}
          level={LEVEL.WARNING}
          closeable={true}
          setBannerDisplayed={setBannerDisplayed}
        />
      );
    } else {
      return (
        <SubscriptionBanner
          messageText={exceededContactsFreePlanMessageText}
          level={LEVEL.ERROR}
          closeable={false}
          setBannerDisplayed={setBannerDisplayed}
        />
      );
    }
  }
  setBannerDisplayed(false);
  return <></>;
};

export default PreviewLayoutBanner;
