import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import {
  createEcommerceActions,
  updateEcommerceActions,
  getEcommerceByIdActions,
  deleteEcommerceByIdActions,
  getEcommercesActions,
  togglePublishEcommerceActions,
} from "./actions";

import { Product, ProductRequest } from "./types";
import { productService } from "services";
import { Pagination, Response } from "redux/Common/types";

function* getEcommerces({ payload }: Action<ProductRequest>) {
  const result: Response<Pagination<Product>> = yield* call(
    productService.getProducts,
    payload
  );

  if (!!result && "ok" in result) {
    yield* put(getEcommercesActions.SUCCESS(result.response));
  } else {
    yield* put(getEcommercesActions.FAILURE(result?.message));
  }
}

function* createEcommerce({ payload }: Action<Product>) {
  const result: any = yield* call(productService.createProduct, payload);

  if (result.ok) {
    yield* put(createEcommerceActions.SUCCESS(result.response));
  } else {
    yield* put(createEcommerceActions.FAILURE(result.message));
  }
}

function* updateEcommerce({ payload }: Action<{ id: string; data: Product }>) {
  const result: any = yield* call(
    productService.updateProduct,
    payload.id,
    payload.data
  );
  if (result.ok) {
    yield* put(updateEcommerceActions.SUCCESS(result.response));
  } else {
    yield* put(updateEcommerceActions.FAILURE(result.message));
  }
}

function* deleteEcommerceById({ payload }: Action<string>) {
  const result: any = yield* call(productService.deleteProductById, payload);
  if (result.ok) {
    yield* put(
      deleteEcommerceByIdActions.SUCCESS({
        id: payload,
        response: result.response,
      })
    );
  } else {
    yield* put(deleteEcommerceByIdActions.FAILURE(result.message));
  }
}

function* getEcommerceById({ payload }: Action<string>) {
  const result: any = yield* call(productService.getProductById, payload);
  if (result.ok) {
    yield* put(getEcommerceByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getEcommerceByIdActions.FAILURE(result.message));
  }
}

function* toglePublishEcommerce({
  payload,
}: Action<{ id: string; data: Product }>) {
  const result: any = yield* call(
    productService.updateProduct,
    payload.id,
    payload.data
  );
  if (result.ok) {
    yield* put(togglePublishEcommerceActions.SUCCESS(result.response));
  } else {
    yield* put(togglePublishEcommerceActions.FAILURE(result.message));
  }
}

export default function* productSagas(): SagaIterator {
  yield* takeLatest(createEcommerceActions.REQUEST, createEcommerce);
  yield* takeLatest(updateEcommerceActions.REQUEST, updateEcommerce);
  yield* takeLatest(getEcommerceByIdActions.REQUEST, getEcommerceById);
  yield* takeLatest(deleteEcommerceByIdActions.REQUEST, deleteEcommerceById);
  yield* takeLatest(getEcommercesActions.REQUEST, getEcommerces);
  yield* takeLatest(
    togglePublishEcommerceActions.REQUEST,
    toglePublishEcommerce
  );
}
