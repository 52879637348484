import { createAction } from "redux-actions";
import { createGenericTypes, createGenericActions } from "utils/createAction";

export const resetUserActionType = "RESET_USER_ACTION_TYPE";
export const resetUserActionTypeAction = createAction(resetUserActionType);

export const setUserValueType = "RESET_USER_VALUE_TYPE";
export const setUserValueAction = createAction(setUserValueType);

export const logoutTypes = createGenericTypes("LOGOUT");
export const logoutActions = createGenericActions(logoutTypes);

export const getUserProfileTypes = createGenericTypes("GET_USER_PROFILE");
export const getUserProfileActions = createGenericActions(getUserProfileTypes);

export const setTalentProfileType = "SET_TALENT_PROFILE";
export const setTalentProfileAction = createAction(setTalentProfileType);

export const revertTalentProfileType = "REVERT_TALENT_PROFILE";
export const revertTalentProfileAction = createAction(revertTalentProfileType);

export const updateTalentRevertType = "UPDATE_TALENT_REVERT";
export const updateTalentRevertAction = createAction(updateTalentRevertType);

export const updateUserProfileTypes = createGenericTypes("UPDATE_USER_PROFILE");
export const updateUserProfileActions = createGenericActions(
  updateUserProfileTypes
);

export const updateTalentProfileTypes = createGenericTypes(
  "UPDATE_TALENT_PROFILE"
);
export const updateTalentProfileActions = createGenericActions(
  updateTalentProfileTypes
);

export const getSpotifyUserTokenTypes = createGenericTypes(
  "GET_SPOTIFY_USER_TOKEN"
);
export const getSpotifyUserTokenActions = createGenericActions(
  getSpotifyUserTokenTypes
);

export const updateSpotifyUserTypes = createGenericTypes("UPDATE_SPOTIFY_USER");
export const updateSpotifyUserActions = createGenericActions(
  updateSpotifyUserTypes
);

export const updateProfileGalleryTypes = createGenericTypes(
  "UPDATE_PROFILE_GALLERY"
);

export const removeProfileGalleryTypes = createGenericTypes(
  "REMOVE_PROFILE_GALLERY"
);

export const updateTempTalentImagesTypes = createGenericTypes(
  "UPDATE_TEMP_TALENT_IMAGES"
);
export const removeTempTalentImageType = createGenericTypes(
  "REMOVE_TEMP_TALENT_IMAGE"
);

export const updateProfileGalleryActions = createGenericActions(
  updateProfileGalleryTypes
);
export const removeProfileGalleryActions = createGenericActions(
  removeProfileGalleryTypes
);
export const updateTempTalentImagesActions = createGenericActions(
  updateTempTalentImagesTypes
);
export const removeTempTalentImageActions = createGenericActions(
  removeTempTalentImageType
);

export const updatePasswordTypes = createGenericTypes("UPDATE_PASSWORD");
export const updatePasswordActions = createGenericActions(updatePasswordTypes);

export const getTalentAnalyticTypes = createGenericTypes(
  "GET_TALENT_ANALYTICS"
);
export const getTalentAnalyticActions = createGenericActions(
  getTalentAnalyticTypes
);

export const getTalentAnalyticMonthlyTypes = createGenericTypes(
  "GET_TALENT_ANALYTICS_MONTHLY"
);
export const getTalentAnalyticMonthlyActions = createGenericActions(
  getTalentAnalyticMonthlyTypes
);

export const getAvailabilityCalendarsTypes = createGenericTypes(
  "GET_AVAILABILITY_CALENDARS"
);
export const getAvailabilityCalendarsActions = createGenericActions(
  getAvailabilityCalendarsTypes
);

export const connectCalendarProviderTypes = createGenericTypes(
  "CONNECT_CALENDAR_PROVIDER"
);
export const connectCalendarProviderActions = createGenericActions(
  connectCalendarProviderTypes
);

export const authorizationTokenCalendarProviderTypes = createGenericTypes(
  "AUTHORIZATION_TOKEN_CALENDAR_PROVIDER"
);
export const authorizationTokenCalendarProviderActions = createGenericActions(
  authorizationTokenCalendarProviderTypes
);

export const disconnectCalendarProviderTypes = createGenericTypes(
  "DISCONNECT_CALENDAR_PROVIDER"
);
export const disconnectCalendarProviderActions = createGenericActions(
  disconnectCalendarProviderTypes
);

export const sendOTPTypes = createGenericTypes("SEND_OTP");
export const sendOTPActions = createGenericActions(sendOTPTypes);

export const sendEmailOTPTypes = createGenericTypes("SEND_EMAIL_OTP");
export const sendEmailOTPActions = createGenericActions(sendEmailOTPTypes);

export const checkUserExistedTypes = createGenericTypes("CHECK_USER_EXISTED");
export const checkUserExistedActions = createGenericActions(
  checkUserExistedTypes
);

export const getExchangeRateTypes = createGenericTypes("GET_EXCHANGE_RATES");
export const getExchangeRateActions =
  createGenericActions(getExchangeRateTypes);

export const getExchangeRateUSDTypes = createGenericTypes(
  "GET_EXCHANGE_RATES_USD"
);
export const getExchangeRateUSDActions = createGenericActions(
  getExchangeRateUSDTypes
);

export const detectLocalCurrencyTypes = createGenericTypes(
  "DETECT_LOCAL_CURRENCY"
);
export const detectLocalCurrencyActions = createGenericActions(
  detectLocalCurrencyTypes
);

// talent profile module
export const setTalentProfileModulesOriginType =
  "SET_TALENT_PROFILE_MODULE_LIST_ORIGIN";
export const setTalentProfileModulesOriginActions = createAction(
  setTalentProfileModulesOriginType
);

export const setPreviosModuleVersionType = "SET_PREVIOUS_MODULE_LIST";
export const setPreviosModuleVersionActions = createAction(
  setPreviosModuleVersionType
);

export const setTalentProfileModuleListType = "SET_TALENT_PROFILE_MODULE_LIST";
export const setTalentProfileModuleListActions = createAction(
  setTalentProfileModuleListType
);

export const setTalentProfileModuleType = "SET_TALENT_PROFILE_MODULE";
export const setTalentProfileModuleActions = createAction(
  setTalentProfileModuleType
);

export const addTalentProfileModuleType = "ADD_TALENT_PROFILE_MODULE";
export const addTalentProfileModuleActions = createAction(
  addTalentProfileModuleType
);

export const removeTalentProfileModuleType = "REMOVE_TALENT_PROFILE_MODULE";
export const removeTalentProfileModuleActions = createAction(
  removeTalentProfileModuleType
);

export const revertTalentProfileModuleType = "REVERT_TALENT_PROFILE_MODULE";
export const revertTalentProfileModuleActions = createAction(
  revertTalentProfileModuleType
);

export const createTalentProfileModuleTypes = createGenericTypes(
  "CREATE_TALENT_PROFILE_MODULE"
);
export const createTalentProfileModuleActions = createGenericActions(
  createTalentProfileModuleTypes
);

export const publishProfileType = createGenericTypes("PUBLISH_PROFILE");
export const publishProfileActions = createGenericActions(publishProfileType);

export const forceSaveAndpublishProfileType = createGenericTypes(
  "FORCE_SAVE_AND_PUBLISH_PROFILE"
);
export const forceSaveAndpublishProfileActions = createGenericActions(
  forceSaveAndpublishProfileType
);

export const forceSaveProfileAndLogoutType = createGenericTypes(
  "FORCE_SAVE_PROFILE_AND_LOGOUT"
);
export const forceSaveAndLogoutActions = createGenericActions(
  forceSaveProfileAndLogoutType
);

export const autoSaveProfileType = createGenericTypes("AUTO_SAVE_PROFILE");
export const autoSaveProfileActions = createGenericActions(autoSaveProfileType);

export const setIsBlockingEditType = "SET_IS_BLOCKING_EDIT";
export const setIsBlockingEditActions = createAction(setIsBlockingEditType);

export const setIsPublishedType = "SET_IS_PUBLISH";
export const setIsPublishedActions = createAction(setIsPublishedType);

export const setLoadingAutoSaveType = "SET_LOADING_AUTO_SAVE";
export const setLoadingAutoSaveActions = createAction(setLoadingAutoSaveType);

export const updateTalentProfileModuleTypes = createGenericTypes(
  "UPDATE_TALENT_PROFILE_MODULE"
);
export const updateTalentProfileModuleActions = createGenericActions(
  updateTalentProfileModuleTypes
);

export const deleteTalentProfileModuleTypes = createGenericTypes(
  "DELETE_TALENT_PROFILE_MODULE"
);
export const deleteTalentProfileModuleActions = createGenericActions(
  deleteTalentProfileModuleTypes
);

export const reorderTalentProfileModuleTypes = createGenericTypes(
  "REORDER_TALENT_PROFILE_MODULE"
);

export const reorderTalentProfileModuleActions = createGenericActions(
  reorderTalentProfileModuleTypes
);

// talent collaborator
export const getCollaboratorInvitationsTypes = createGenericTypes(
  "GET_COLLABORATOR_INVITATIONS"
);
export const getCollaboratorInvitationsActions = createGenericActions(
  getCollaboratorInvitationsTypes
);

export const inviteCollaboratorTypes = createGenericTypes(
  "INVITE_COLLABORATOR"
);
export const inviteCollaboratorActions = createGenericActions(
  inviteCollaboratorTypes
);

export const cancelInviteCollaboratorTypes = createGenericTypes(
  "CANCEL_COLLABORATOR"
);
export const cancelInviteCollaboratorActions = createGenericActions(
  cancelInviteCollaboratorTypes
);

export const revokeInviteCollaboratorTypes = createGenericTypes(
  "REVOKE_COLLABORATOR"
);
export const revokeInviteCollaboratorActions = createGenericActions(
  revokeInviteCollaboratorTypes
);

export const resendInviteCollaboratorTypes = createGenericTypes(
  "RESEND_COLLABORATOR"
);
export const resendInviteCollaboratorActions = createGenericActions(
  resendInviteCollaboratorTypes
);

export const getCollaboratorProfileTypes = createGenericTypes(
  "GET_COLLABORATOR_PROFILE"
);
export const getCollaboratorProfileActions = createGenericActions(
  getCollaboratorProfileTypes
);

export const updateTalentProfileByIdTypes = createGenericTypes(
  "UPDATE_TALENT_PROFILE_BY_ID"
);
export const updateTalentProfileByIdActions = createGenericActions(
  updateTalentProfileByIdTypes
);

export const updateCollaboratorProfileTypes = createGenericTypes(
  "UPDATE_CONSUMER_PROFILE"
);
export const updateCollaboratorProfileActions = createGenericActions(
  updateCollaboratorProfileTypes
);

export const deleteLocalizationTypes = createGenericTypes(
  "DELETE_LOCALIZATION"
);
export const deleteLocalizationActions = createGenericActions(
  deleteLocalizationTypes
);

export const getLocalizationModulesTypes = createGenericTypes(
  "GET_LOCALIZATION_MODULES"
);
export const getLocalizationModulesActions = createGenericActions(
  getLocalizationModulesTypes
);

export const getAnalyticsLocalizationModulesTypes = createGenericTypes(
  "GET_ANALYTICS_LOCALIZATION_MODULES"
);
export const getAnalyticsLocalizationModulesActions = createGenericActions(
  getAnalyticsLocalizationModulesTypes
);

export const setLocalizationUnpublishIdsType = "SET_LOCALIZATION_UNPUBLISH_IDS";
export const setLocalizationUnpublishIdsAction = createAction(
  setLocalizationUnpublishIdsType
);

export const setLocalizationSelectedType = "SET_LOCALIZATION_SELECTED";
export const setLocalizationSelectedAction = createAction(
  setLocalizationSelectedType
);
