import { GetShopMyShelfCollectionRes, Response } from "redux/Common/types";
import { ShopMyShelfApi } from "./api/shop-my-shelf.api";

export default class ShopMyShelfService {
  constructor(private readonly api: ShopMyShelfApi) {
    this.api = api;
  }

  getCollectionById = (
    id: number | string
  ): Promise<Response<GetShopMyShelfCollectionRes>> => {
    return this.api.getCollectionById(id);
  };
}
