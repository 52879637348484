export type PaymentCard = {
  brand: string;
  country: string | null;
  exp_month: number;
  exp_year: number;
  last4: string;
};

export type Customer = {
  firstname: string;
  lastname: string;
  email: string;
  user_id: number;
};

export type CustomerPayload = {
  email: string;
  firstname: string;
  lastname: string;
};

export type CustomerWithDetails = Customer & CustomerDetails;
export type CustomerWithDetailsSuccess = {
  success: true;
  customer: CustomerWithDetails;
};

export type CustomerDetails = {
  provider: Provider;
  pricing?: {
    country: string;
    region: string;
  };
  paymentMethod?: {
    card: PaymentCard; // TODO: generic type
  };
  subscriptions?: SubscriptionDetails;
};

export enum Interval {
  MONTH = "month",
  YEAR = "year",
}

export type ProductSuccess = {
  success: true;
  product: Product;
};

export type Product = {
  key: ProductKey;
  name: string;
  price_key: string;
  prices: Record<Interval, Price[]>;
};

export enum ProductKey {
  PROFILE_BUILDER = "profile_builder",
}

export enum Provider {
  STRIPE = "stripe",
}

export type Price = {
  product_key: ProductKey;
  price_key: string;
  interval: Interval;
  amount: number;
  currency: string;
  tax_included: boolean;
};

export type StripeCustomer = Customer & {
  stripe_id: string;
};

export type StripeCustomerSuccess = {
  success: true;
  customer: StripeCustomer;
};

export type StripeSetupIntentSuccess = {
  success: true;
  client_secret: string;
};

export type SalesSuccess = {
  success: true;
};

export type SalesSetupConfirmationFailure = {
  success: false;
  status: number;
  message: string;
}

export type CheckoutInvoiceSuccess = {
  success: true;
  checkout: CheckoutInvoice;
};

export type Subscription = {
  talent_profile_id: string;
  product_key: ProductKey;
  user_id: number;
  price_key: string;
  interval: Interval;
  country_code: string;
  region_code: string;
  status: SubscriptionStatus;
  start_date: number;
  end_date?: number | null;
  cancel_date?: number | null;
  trial_start?: number;
  trial_end?: number;
  current_period_start?: number;
  current_period_end?: number;
};

export type SubscriptionDetails = Record<string, SubscriptionMap>;
export type SubscriptionMap = Partial<Record<ProductKey, Subscription>>;

export type SubscriptionSuccess = {
  success: true;
  subscription: Subscription;
};

export type CanDeletePaymentMethodSuccess = {
  succes: true;
  canDelete: boolean;
};

// based on stripe subscriptions

export enum SubscriptionStatus {
  ACTIVE = "active",
  CANCELED = "canceled",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  PAST_DUE = "past_due",
  TRIALING = "trialing",
  UNPAID = "unpaid",
}

export type SubscriptionSyncPayload = {
  subscription: Subscription;
  synced_at: number;
};

export type TrialSubscription = {
  talent_profile_id: string;
  product_key: ProductKey;
  user_id: number;
  country_code: string;
  region_code?: string;
  status: SubscriptionStatus.TRIALING;
  trial_start: number;
  trial_end: number;
};

export type TrialSubscriptionSuccess = {
  success: true;
  subscription: TrialSubscription;
};

import Stripe from "stripe";

export enum SubscriptionPeriod {
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export enum SubscriptionCreationStep {
  Selection = "Selection",
  Setup = "Setup",
  Review = "Review",
  Confirmation = "Confirmation",
  UpdateDetails = "UpdateDetails",
  DetailsConfirmation = "DetailsConfirmation",
}

// TODO: rm
export interface SalesSubscriptionRequest {
  country_code: string;
  interval: Interval;
  product_key: ProductKey;
  talent_profile_id: string;
}

// Reference: https://komi-workspace.slack.com/archives/C041X3085F1/p1667992748527799?thread_ts=1667924502.594359&cid=C041X3085F1
// Endpoint: POST /sales/stripe/customer/setup
export interface PaymentSetupIntent {
  client_secret: string;
}

export enum Region {
  AU = "AU",
  CA = "CA",
  EU = "EU",
  GB = "GB",
  US = "US",
}

export interface PaymentMethod {
  id: string;
  object: string;
  card: Partial<Stripe.PaymentMethod.Card>;
}

export enum PaymentMethodAction {
  DELETE = "Delete",
  UPDATE = "Update",
}

export enum OverviewStatus {
  ACTIVE = "active",
  FREE_TRIAL = "free trial",
  CANCELLED = "cancelled",
  FREE_TRIAL_EXPIRED = "free trial expired",
}

// temporary

export enum SessionStorageKeys {
  CUSTOMER = "customer",
  CLIENT_SECRET = "client_secret",
  INTERVAL = "interval",
  FLOW = "flow",
}

export const FLOW_CREATE = "FLOW_CREATE";
export const FLOW_UPDATE = "FLOW_UPDATE";

export type CheckoutInvoice = {
  amount_due: number;
  currency: string;
  subtotal: number;
  total: number;
  total_discount_amounts: any[];
  total_tax_amounts: TaxAmount[];
};

export type TaxAmount = {
  amount: number;
  inclusive: boolean;
  tax_rate: {
    active: boolean
    description: string | null
    display_name: string
    inclusive: boolean
    percentage: number
  }
}

export type Language = {
  Global: {
    Title: string;
  },
  SubscriptionSelection: {
    CTAButtonLabel: string;
  },
  SubscriptionConfirmation: {
    Title: string;
  }
}
