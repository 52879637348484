import { Action, handleActions } from "redux-actions";
import {
  getBookings1To1Actions,
  getBooking1To1ByIdActions,
  rejectBookingActions,
  getUpcomingBookingsActions,
  completeBookingActions,
  getLastestPurchaseActions,
  updateBookingSetupActions,
  getBookingSetupActions,
} from "./actions";
import { BookingState } from "./types";

const handlers = {
  // get 1 to 1 bookings
  [getBookings1To1Actions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    bookings1To1: null,
    booking1To1Data: null,
    loading: true,
    isCompletedRoom: false,
  }),
  [getBookings1To1Actions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    bookings1To1: payload,
    loading: false,
  }),
  [getBookings1To1Actions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // get 1 to 1 booking by id
  [getBooking1To1ByIdActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    booking1To1Data: null,
    loading: true,
    isCompletedRoom: false,
  }),
  [getBooking1To1ByIdActions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    booking1To1Data: payload,
    loading: false,
  }),
  [getBooking1To1ByIdActions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // reject booking
  [rejectBookingActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    loading: true,
  }),
  [rejectBookingActions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    booking1To1Data: payload,
    loading: false,
  }),
  [rejectBookingActions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // get upcoming bookings
  [getUpcomingBookingsActions.REQUEST]: (
    state: BookingState
  ): BookingState => ({
    ...state,
    upcomingBookings: null,
    loading: true,
    isCompletedRoom: false,
  }),
  [getUpcomingBookingsActions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    upcomingBookings: payload,
    loading: false,
  }),
  [getUpcomingBookingsActions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // get lastest purchase request
  [getLastestPurchaseActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    lastestPurchases: null,
    loading: true,
    isCompletedRoom: false,
  }),
  [getLastestPurchaseActions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    lastestPurchases: payload,
    loading: false,
  }),
  [getLastestPurchaseActions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // complete booking
  [completeBookingActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    isCompletedRoom: false,
  }),
  [completeBookingActions.SUCCESS]: (state: BookingState): BookingState => ({
    ...state,
    isCompletedRoom: true,
    loading: false,
  }),
  [completeBookingActions.FAILURE]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update booking setup
  [updateBookingSetupActions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    bookingSetup: payload,
  }),
  // get booking setup
  // get lastest purchase request
  [getBookingSetupActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    bookingSetup: [],
  }),
  [getBookingSetupActions.SUCCESS]: (
    state: BookingState,
    { payload }: Action<any>
  ): BookingState => ({
    ...state,
    bookingSetup: payload,
  }),
};

const initialState: BookingState = {
  error: null,
  loading: true,
  bookings1To1: null,
  upcomingBookings: null,
  lastestPurchases: null,
  booking1To1Data: null,
  isCompletedRoom: false,
  bookingSetup: [],
};

export const bookingReducer = handleActions<BookingState>(
  handlers,
  initialState
);
