import { Col, Row, Typography, Avatar } from "antd";
import classNames from "classnames";
import ImageSkeleton from "components/ImageSkeleton";
import React, { useMemo } from "react";
import "./AvatarProfile.scss";

interface IProps {
  avatar: string;
  avatarText?: string;
  name: string;
  profession?: string;
  showInfo?: boolean;
  avatarSize?: number;
  shape?: "circle" | "square";
  className?: string;
}

const { Text } = Typography;

const AvatarProfile = ({
  avatar,
  avatarText,
  name,
  profession,
  showInfo,
  avatarSize,
  shape = "circle",
  className,
}: IProps) => {
  const avatarName = useMemo(() => {
    const value = avatarText || name;
    if (value) {
      const elements = value.split(" ");
      return elements.length > 0
        ? elements
            .map((item) => (item.length ? item[0].toUpperCase() : ""))
            .join("")
        : value[0];
    }
    return "";
  }, [name, avatarText]);

  return (
    <Row
      gutter={showInfo ? 12 : 0}
      align="middle"
      className={classNames("avatar-profile", className)}
    >
      <Col>
        {avatar ? (
          <ImageSkeleton
            shape={shape}
            src={avatar}
            size={avatarSize || 56}
            imgWidth={avatarSize || 56}
          />
        ) : (
          <Avatar
            className="avatar-profile__text"
            shape="circle"
            size={avatarSize || 56}
          >
            {avatarName}
          </Avatar>
        )}
      </Col>
      {showInfo && (
        <Col className="flex--1">
          <Row className="avatar-profile__info">
            <Text>{name}</Text>
          </Row>
          {profession && (
            <Row className="avatar-profile__info">
              <Text className="avatar-profile__profession">{profession}</Text>
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
};

export default AvatarProfile;
