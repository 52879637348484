export const IMAGE_REG = /[.](gif|jpg|jpeg|tiff|png|webp)$/i;
export const URL_REG =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1,}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,})?(\/.*)?$/;
export const URL_YOUTUBE_REG =
  /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/;
export const URL_YOUTUBE_PLAYLIST_REG = /[&?]list=([^&]+)/i;
export const URL_YOUTUBE_CHANNEL_REG = new RegExp(
  "(https?://)?(www.)?youtu((.be)|(be..{2,5}))/((user)|(channel)|(c))/"
);

export const HTTP_REG = /^(https?|ftp):\/\//;
export const NAME_REG = /^(?![\s.]+$)[a-zA-Z0-9\s]*$/;
export const EMAIL_REG = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const NUMBER_REG = /\d/g;
export const PROVIDER_NAME = /^@/;
export const PHONE_REG =
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/g;
export const SHOPIFY_STORE_DOMAIN = /^([a-z0-9-]+\.)myshopify.com$/g;
export const SPECIAL_CHARS = /^[a-z0-9][a-z0-9\ ]*[a-z0-9]?$/gim;

export const URL_APPLE_PODCAST_REG = new RegExp(
  "(?:(?:https)?://)?(?:www.)?(podcasts|itunes).apple.com/?([a-z0-9])+/podcast/"
);

export const URL_AMAZON_PODCAST_REG = new RegExp(
  "(?:(?:https)?://)?(?:www.)?(music).amazon.com/podcasts/"
);
export const URL_AUDIBLE_PODCAST_REG = new RegExp(
  "(?:(?:https)?://)?(?:www.)audible.com/pd/"
);
