import LogRocket from "logrocket";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import mySaga from "./sagas";
import reducer from "./rootReducer";
import CustomMiddleware from "./customMiddlewares";
import { ACTION_NO_LOG_LIST } from './actionlog';
import config from "config";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// custom middlewares
const middlewareDev = [
  CustomMiddleware.SuccessRequestHandling,
  CustomMiddleware.ErrorRequestHandling,
];

const middleware =
  config.service.env === "dev"
    || config.service.env === "development"
    || config.service.env === "local"
    ? composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        ...middlewareDev,
        LogRocket.reduxMiddleware({
          actionSanitizer: function (action) {
            return ACTION_NO_LOG_LIST.includes(action) ? null : action;
          },
        })
      )
    )
    : applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware());

// mount it on the Store
const store = createStore(reducer, middleware);

export type StoreInstance = typeof store;

// then run the saga
sagaMiddleware.run(mySaga);

export default store;
