import * as React from "react";
import Typography from "antd/lib/typography";
import { LinkProps as LinkAntdProps } from "antd/lib/typography/Link";
import { TypographyPresets, presets } from "./Preset";
const LinkAntd = Typography.Link;
interface LinkProps extends LinkAntdProps {
  preset?: TypographyPresets;
  className?: string;
}

export const Link = (props: LinkProps) => {
  const { preset, className, ...rest } = props;
  const namePreset = preset && presets[preset];
  const mainClass = `text text--primary ${
    namePreset ? namePreset : ""
  } ${className}`;
  return <LinkAntd className={mainClass} {...rest} />;
};
