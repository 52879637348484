import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getRoomTokenTypes = createGenericTypes("GET_ROOM_TOKEN");
export const getRoomTokenActions = createGenericActions(getRoomTokenTypes);

export const getRoomLiveClassTokenTypes = createGenericTypes(
  "GET_ROOM_LIVE_CLASS_TOKEN"
);
export const getRoomLiveClassTokenActions = createGenericActions(
  getRoomLiveClassTokenTypes
);

export const getRoomViewersTypes = createGenericTypes("GET_ROOM_VIEWERS");
export const getRoomViewersActions = createGenericActions(getRoomViewersTypes);

export const completeRoomTypes = createGenericTypes("COMPLETE_ROOM");
export const completeRoomActions = createGenericActions(completeRoomTypes);
