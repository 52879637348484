import { createAction } from "redux-actions";
import { createGenericTypes, createGenericActions } from "utils/createAction";

export const signupTalentTypes = createGenericTypes("SIGN_UP_TALENT");
export const signupTalentActions = createGenericActions(signupTalentTypes);

export const verifyBecomeTalentOtpTypes = createGenericTypes(
  "VERIFY_BECOME_TALENT_OTP"
);
export const verifyBecomeTalentOtpActions = createGenericActions(
  verifyBecomeTalentOtpTypes
);

export const resetVerifyOtpValueType = "RESET_VERIFY_OTP_VALUE";
export const resetVerifyOtpValueAction = createAction(resetVerifyOtpValueType);

export const fanClubCountType = createGenericTypes("FAN_CLUB_COUNT");
export const fanClubCountAction = createGenericActions(fanClubCountType);

export const dataFormCountType = createGenericTypes("DATA_FORM_COUNT");
export const dataFormCountAction = createGenericActions(dataFormCountType);

export const getListDataFormType = createGenericTypes("GET_LIST_DATA_FORM");
export const getListDataFormAction = createGenericActions(getListDataFormType);

export const getListPreReleaseType = createGenericTypes("GET_LIST_PRE_RELEASE");
export const getListPreReleaseAction = createGenericActions(getListPreReleaseType);

export const preReleaseCountType = createGenericTypes("PRE_RELEASE_COUNT");
export const preReleaseCountAction = createGenericActions(preReleaseCountType);

export const exportPreReleaseType = createGenericTypes("EXPORT_PRE_RELEASE");
export const exportPreReleaseAction = createGenericActions(exportPreReleaseType);

export const exportFanClubType = createGenericTypes("EXPORT_FAN_CLUB");
export const exportFanClubAction = createGenericActions(exportFanClubType);

export const exportDataCaptureFormType = createGenericTypes(
  "EXPORT_DATA_CAPTURE_FORM"
);
export const exportDataCaptureFormAction = createGenericActions(
  exportDataCaptureFormType
);

export const getDataCaptureFormType = createGenericTypes(
  "GET_DATA_CAPTURE_FORM"
);
export const getDataCaptureFormAction = createGenericActions(
  getDataCaptureFormType
);

export const setActiveModuleType = "SET_ACTIVE_MODULE";
export const setActiveModuleAction = createAction(setActiveModuleType);

export const updateCloseExpandModuleType = "UPDATE_CLOSE_EXPAND_MODULE_TYPE";
export const updateCloseExpandActions = createAction(
  updateCloseExpandModuleType
);
