import { createAction } from "redux-actions";
import { createGenericTypes, createGenericActions } from "utils";

export const getAvailabilityRuleTypes = createGenericTypes(
  "GET_AVAILABILITY_RULE"
);
export const getAvailabilityRuleActions = createGenericActions(
  getAvailabilityRuleTypes
);

export const createAvailabilityRuleTypes = createGenericTypes(
  "CREATE_AVAILABILITY_RULE"
);
export const createAvailabilityRuleActions = createGenericActions(
  createAvailabilityRuleTypes
);

export const updateAvailabilityRuleTypes = createGenericTypes(
  "UPDATE_AVAILABILITY_RULE"
);
export const updateAvailabilityRuleActions = createGenericActions(
  updateAvailabilityRuleTypes
);

