import isEmpty from "lodash/isEmpty";
import type {
  SegmentEventProperties,
  SegmentEventName,
} from "constants/segment";

export class AnalyticServices {
  static facebookPixelTrack(
    event: string,
    properties?: Record<string, any>
  ): void {
    if (
      (window as any).FacebookPixel &&
      !isEmpty((window as any).FacebookPixel)
    ) {
      Object.keys((window as any).FacebookPixel).forEach((id) => {
        (window as any).FacebookPixel[id].track(event, properties);
      });
    }
  }

  static track<TEvent extends SegmentEventName>(
    event: TEvent,
    properties?: SegmentEventProperties[TEvent],
    trackFBOnly?: boolean
  ): void {
    if (!trackFBOnly) {
      (window as any)?.analytics?.track(event, properties);
    }
    this.facebookPixelTrack(event, properties);
  }

  static identify(event: string, properties?: Record<string, any>): void {
    (window as any)?.analytics?.identify(event, properties);
  }

  static ready(callback: () => void): void {
    (window as any)?.analytics?.ready(callback);
  }

  static reset(): void {
    (window as any)?.analytics?.reset();
  }
}
