import { Api } from "../api";
import { EXPERIENCE_TYPE } from "redux/Experience/types";
import { PURCHASE_TYPE } from "redux/Booking/types";
import Booking1To1Service from "./Booking1To1Service";
import BookingService from "./BookingService";
import BookingLiveClassService from "./BookingLiveClassService";
import BookingInteractiveLiveClassService from "./BookingInteractiveLiveClassService";

export default class BookingFactoryService {
  static createService(
    api: Api,
    type: PURCHASE_TYPE | EXPERIENCE_TYPE
  ): BookingService {
    switch (type) {
      case EXPERIENCE_TYPE.ONE_TO_ONE:
        return new Booking1To1Service(api);
      case EXPERIENCE_TYPE.LIVE_CLASS:
        return new BookingLiveClassService(api);
      case EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS:
        return new BookingInteractiveLiveClassService(api);
      default:
        return new Booking1To1Service(api);
    }
  }
}
