import { Layout } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import classnames from "classnames";
import Loading from "components/Loading";
import MainHeader from "components/MainHeader";
import { useWindowSize } from "hooks";
import React, { ReactNode, Suspense } from "react";
import { isMobile } from "react-device-detect";
import { ContainerProvider } from "utils/container";
import { useLocation } from "react-router-dom";

import "./MainLayout.scss";

interface IProps {
  children?: ReactNode;
  showSidebar?: boolean;
  isDarkMode?: boolean;
  isExperienceFlow?: boolean;
  isLivestreamFLow?: boolean;
  className?: string;
  timerComponent?: ReactNode;
  finishComponent?: ReactNode;
  hideHeader?: boolean;
  showAvatarProfile?: boolean;
}

const { Content, Header } = Layout;

const MainLayout = ({
  children,
  showSidebar,
  isExperienceFlow = false,
  isLivestreamFLow = false,
  className = "",
  timerComponent,
  finishComponent,
  hideHeader = false,
  isDarkMode,
  showAvatarProfile,
}: IProps) => {
  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);
  const screens = useBreakpoint();
  const mobile = isMobile || screens["xs"];
  const { height } = useWindowSize();
  const location = useLocation();

  const layoutClass = classnames({
    "main-layout": true,
    [className]: true,
    "main-layout--experience": isExperienceFlow,
    "main-layout--livestream": isLivestreamFLow,
    "main-layout--dark-mode": isDarkMode,
  });

  const mainPageContentClass = classnames({
    "main-layout__page-content": true,
    "main-layout__page-content--no-sidebar": true,
  });

  const toggleMobileMenu = React.useCallback(() => {
    setMobileMenuVisible((preState) => !preState);
  }, []);

  return (
    <>
      <Layout
        className={layoutClass}
        style={{
          minHeight: `${height}px`,
        }}
      >
        {!hideHeader && (
          <Header className="main-layout__header fluid-container">
            <MainHeader
              isDarkMode={true}
              isExperienceFlow={isExperienceFlow}
              isLiveStreamFlow={isLivestreamFLow}
              timerComponent={timerComponent}
              finishComponent={finishComponent}
              isVisibleMobileMenu={mobileMenuVisible}
              toggleMobileMenu={toggleMobileMenu}
              showAvatarProfile={
                showAvatarProfile
                  || location.pathname === "/user/settings"
                  || location.pathname === "/me"
              }
            />
          </Header>
        )}
        <Content>
          <Layout className="main-layout__content">
            <div className="fluid-container d--flex flex--1">
              <ContainerProvider>
                <Content className={mainPageContentClass}>
                  <Suspense fallback={<Loading />}>{children}</Suspense>
                </Content>
              </ContainerProvider>
            </div>
          </Layout>
        </Content>
      </Layout>
    </>
  );
};

export default MainLayout;
