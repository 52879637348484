import { Action, handleActions } from "redux-actions";
import { getCategoriesActions } from "./actions";
import { CategoryState } from "./types";

const handlers = {
  // get 1 to 1 bookings
  [getCategoriesActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    categories: null,
    loading: true,
  }),
  [getCategoriesActions.SUCCESS]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    categories: payload,
    loading: false,
  }),
  [getCategoriesActions.FAILURE]: (
    state: CategoryState,
    { payload }: Action<any>
  ): CategoryState => ({
    ...state,
    error: payload,
    loading: false,
  }),
};

const initialState: CategoryState = {
  error: null,
  loading: false,
  categories: null,
  category: null,
};

export const categoryReducer = handleActions<CategoryState>(
  handlers,
  initialState
);
