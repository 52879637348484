import React, { ReactNode } from "react";
import { Modal as AntdModal } from "antd";
import { ModalProps } from "antd/lib/modal";
import { ReactComponent as CloseIcon } from "icons/close.svg";

interface IProps extends ModalProps {
  children: ReactNode;
}

const Modal = ({ children, footer = false, ...props }: IProps) => {
  return (
    <AntdModal
      closeIcon={<CloseIcon role="close" width={24} height={24} />}
      footer={footer}
      {...props}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
