import { Action, handleActions } from "redux-actions";
import {
  getRoomTokenActions,
  getRoomLiveClassTokenActions,
  getRoomViewersActions,
  completeRoomActions,
} from "./actions";
import { RoomState } from "./types";

const handlers = {
  // get room token
  [getRoomTokenActions.REQUEST]: (state: RoomState): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      token: null,
      isCompletedRoom: false,
    };
  },
  [getRoomTokenActions.SUCCESS]: (
    state: RoomState,
    { payload }: Action<any>
  ): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      token: payload,
    };
  },
  [getRoomTokenActions.FAILURE]: (
    state: RoomState,
    { payload }: Action<any>
  ): RoomState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get room Live Broadcasts token
  [getRoomLiveClassTokenActions.REQUEST]: (state: RoomState): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      token: null,
      isCompletedRoom: false,
    };
  },
  [getRoomLiveClassTokenActions.SUCCESS]: (
    state: RoomState,
    { payload }: Action<any>
  ): RoomState => {
    const allRequestors = payload?.allRequestors?.reduce(
      (requestors: any, requestor: any) => {
        requestors[requestor.id] = requestor;
        return requestors;
      },
      {}
    );

    payload = {
      ...payload,
      allRequestors,
    };

    return {
      ...state,
      error: null,
      isLoading: false,
      token: payload,
    };
  },
  [getRoomLiveClassTokenActions.FAILURE]: (
    state: RoomState,
    { payload }: Action<any>
  ): RoomState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get room viewers
  [getRoomViewersActions.REQUEST]: (state: RoomState): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      viewers: null,
    };
  },
  [getRoomViewersActions.SUCCESS]: (
    state: RoomState,
    { payload }: Action<any>
  ): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      viewers: payload,
    };
  },
  [getRoomViewersActions.FAILURE]: (
    state: RoomState,
    { payload }: Action<any>
  ): RoomState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // complete room
  [completeRoomActions.REQUEST]: (state: RoomState): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      isCompletedRoom: false,
    };
  },
  [completeRoomActions.SUCCESS]: (state: RoomState): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      isCompletedRoom: true,
    };
  },
  [completeRoomActions.FAILURE]: (
    state: RoomState,
    { payload }: Action<any>
  ): RoomState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
};

const defaultState: RoomState = {
  isLoading: false,
  token: null,
  viewers: null,
  error: null,
  isCompletedRoom: false,
};

export const roomReducer = handleActions<RoomState>(handlers, defaultState);
