import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getExperiencesTypes = createGenericTypes("GET_EXPERIENCES");

export const getExperiencesActions = createGenericActions(getExperiencesTypes);

export const createExperience1To1Types = createGenericTypes(
  "CREATE_EXPERIENCE_ONE_TO_ONE"
);

export const createExperience1To1Actions = createGenericActions(
  createExperience1To1Types
);

export const createExperienceLiveClassTypes = createGenericTypes(
  "CREATE_EXPERIENCE_LIVE_CLASS"
);

export const createExperienceLiveClassActions = createGenericActions(
  createExperienceLiveClassTypes
);

export const createExperienceBundleTypes = createGenericTypes(
  "CREATE_EXPERIENCE_BUNDLE"
);

export const createExperienceBundleActions = createGenericActions(
  createExperienceBundleTypes
);

export const createExperienceInteractiveLiveClassTypes = createGenericTypes(
  "CREATE_EXPERIENCE_INTERACTIVE_LIVE_CLASS"
);

export const createExperienceInteractiveLiveClassActions = createGenericActions(
  createExperienceInteractiveLiveClassTypes
);

export const createExclusiveContentTypes = createGenericTypes(
  "CREATE_EXCLUSIVE_CONTENT"
);

export const createExclusiveContentActions = createGenericActions(
  createExclusiveContentTypes
);

export const createExperienceCourseTypes = createGenericTypes(
  "CREATE_EXPERIENCE_COURSE"
);

export const createExperienceCourseActions = createGenericActions(
  createExperienceCourseTypes
);

export const getExperienceByIdTypes = createGenericTypes(
  "GET_EXPERIENCE_BY_ID"
);

export const getExperienceByIdActions = createGenericActions(
  getExperienceByIdTypes
);

export const createExperienceTypes = createGenericTypes("CREATE_EXPERIENCE");

export const createExperienceActions = createGenericActions(
  createExperienceTypes
);

export const updateExperience1To1Types = createGenericTypes(
  "UPDATE_EXPERIENCE_ONE_TO_ONE"
);

export const updateExperience1To1Actions = createGenericActions(
  updateExperience1To1Types
);

export const updateExperienceLiveClassTypes = createGenericTypes(
  "UPDATE_EXPERIENCE_LIVE_CLASS"
);

export const updateExperienceLiveClassActions = createGenericActions(
  updateExperienceLiveClassTypes
);

export const updateExperienceBundleTypes = createGenericTypes(
  "UPDATE_EXPERIENCE_BUNDLE"
);

export const updateExperienceBundleActions = createGenericActions(
  updateExperienceBundleTypes
);

export const updateExperienceInteractiveLiveClassTypes = createGenericTypes(
  "UPDATE_EXPERIENCE_INTERACTIVE_LIVE_CLASS"
);

export const updateExperienceInteractiveLiveClassActions = createGenericActions(
  updateExperienceInteractiveLiveClassTypes
);

export const updateExclusiveContentTypes = createGenericTypes(
  "UPDATE_EXCLUSIVE_CONTENT"
);

export const updateExclusiveContentActions = createGenericActions(
  updateExclusiveContentTypes
);

export const updateExperienceCourseTypes = createGenericTypes(
  "UPDATE_EXPERIENCE_COURSE"
);

export const updateExperienceCourseActions = createGenericActions(
  updateExperienceCourseTypes
);

export const updateStagingCoversTypes = createGenericTypes(
  "UPDATE_STAGING_COVERS"
);

export const updateStagingCoversActions = createGenericActions(
  updateStagingCoversTypes
);

export const updateProductImagesTypes = createGenericTypes(
  "UPDATE_PRODUCT_IMAGES"
);

export const updateProductImagesActions = createGenericActions(
  updateProductImagesTypes
);

export const updateLessonsTypes = createGenericTypes("UPDATE_LESSONS");

export const updateLessonsActions = createGenericActions(updateLessonsTypes);

export const updateAttachmentsTypes = createGenericTypes(
  "UPDATE_COURSE_ATTACHMENTS"
);

export const updateAttachmentsActions = createGenericActions(
  updateAttachmentsTypes
);

export const updateExperienceProcessStatusTypes = createGenericTypes(
  "UPDATE_EXPERIENCE_PROCESS_STATUS"
);

export const updateExperienceProcessStatusActions = createGenericActions(
  updateExperienceProcessStatusTypes
);

export const publishExperienceTypes = createGenericTypes("PUBLISH_EXPERIENCE");

export const publishExperienceActions = createGenericActions(
  publishExperienceTypes
);

export const updateExperienceTypes = createGenericTypes("UPDATE_EXPERIENCE");

export const updateExperienceActions = createGenericActions(
  updateExperienceTypes
);
