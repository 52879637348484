import { createGenericTypes, createGenericActions } from "utils";

export const getBookings1To1Types = createGenericTypes("GET_BOOKINGS_1_TO_1");
export const getBookings1To1Actions =
  createGenericActions(getBookings1To1Types);

export const getBooking1To1ByIdTypes = createGenericTypes(
  "GET_BOOKING_1_TO_1_BY_ID"
);
export const getBooking1To1ByIdActions = createGenericActions(
  getBooking1To1ByIdTypes
);

export const getUpcomingBookingsTypes = createGenericTypes(
  "GET_UPCOMING_BOOKINGS"
);
export const getUpcomingBookingsActions = createGenericActions(
  getUpcomingBookingsTypes
);

export const getLastestPurchaseTypes = createGenericTypes(
  "GET_LASTEST_PURCHASE"
);
export const getLastestPurchaseActions = createGenericActions(
  getLastestPurchaseTypes
);

export const rejectBookingTypes = createGenericTypes("REJECT_BOOKING");
export const rejectBookingActions = createGenericActions(rejectBookingTypes);

export const completeBookingTypes = createGenericTypes("COMPLETE_BOOKING");
export const completeBookingActions =
  createGenericActions(completeBookingTypes);

export const updateBookingSetupTypes = createGenericTypes(
  "UPDATE_BOOKING_SETUP"
);
export const updateBookingSetupActions = createGenericActions(
  updateBookingSetupTypes
);
export const getBookingSetupTypes = createGenericTypes("GET_BOOKING_SETUP");
export const getBookingSetupActions =
  createGenericActions(getBookingSetupTypes);

export const updateBookingAttachmentsTypes = createGenericTypes(
  "CREATE_BOOKING_ATTACHMENTS"
);
export const updateBookingAttachmentsActions = createGenericActions(
  updateBookingAttachmentsTypes
);
