import { Response } from "redux/Common/types";
import { Api } from "./api";

export default class SeatedService {
  constructor(private readonly api: Api) {
    this.api = api;
  }
  getValidateSeated = (id: number): Promise<Response<any>> => {
    return this.api.getValidateSeatedById(id);
  };
  getMetadataSeated = (id: number): Promise<Response<any>> => {
    return this.api.getMetadataSeatedById(id);
  };
}
