import { Button, Col, Dropdown, Input, Menu, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { KomiLogo } from "@komi-app/components";
import AvatarProfile from "components/AvatarProfile";
import { Icon } from "components/Icon";
import copyToClipboard from "copy-to-clipboard";
import React, { ReactNode, useCallback, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { useTypedSelector } from "redux/rootReducer";
import {
  selectCollaborator,
  selectCollaboratorList,
  selectIsAdminLogin,
  selectTalentProfile,
  selectUserData,
} from "redux/User/selector";
import { komiConsumerUrl, createKomiDomain } from "services/DomainService";
import notification from "utils/notification";
import { Paragraph, Text } from "../Typography";
import "./MainHeader.scss";
import { userHasRole } from "utils/user";
import { ROLE } from "redux/User/types";
import { MENU_LINKS } from "routes";
import { RequestAProfileModal } from "components/PreviewLayoutHeader/RequestAProfileModal";
import config from "config";

const baseUrl = new URL(config.service.url)
function profileLink(username = '') {
  return `${baseUrl.protocol}//${username}.${baseUrl.host}/admin`
}

type MainHeaderProps = {
  isDarkMode?: boolean;
  isPhoneLayout?: boolean;
  isExperienceFlow?: boolean;
  isLiveStreamFlow?: boolean;
  isVisibleMobileMenu?: boolean;
  toggleMobileMenu?: () => void;
  timerComponent?: ReactNode;
  finishComponent?: ReactNode;
  showAvatarProfile?: boolean;
};

const MainHeader = ({
  isDarkMode,
  timerComponent,
  finishComponent,
  toggleMobileMenu,
  showAvatarProfile,
}: MainHeaderProps) => {
  const screens = useBreakpoint();
  const mobile = isMobile || screens["xs"];

  const user = useTypedSelector(selectUserData);
  const collaborationTalentProfiles = useTypedSelector(selectCollaboratorList);
  const collaborator = useTypedSelector(selectCollaborator);

  const isAdminLogin = useTypedSelector(selectIsAdminLogin);
  const talentProfileOrigin = useTypedSelector(selectTalentProfile);

  const komiLink = createKomiDomain(talentProfileOrigin?.displayName || "");
  const [requestNewProfileModalIsOpen, setRequestNewProfileModalIsOpen] =
    useState(false);

  const clickCreateProfile = useCallback(() => {
    window.open(`${komiConsumerUrl}/create-profile`);
  }, []);

  // NOTE: used only in account info
  const accountInformationUrl = `${config.service.url}${location.pathname}`;
  const isAdminLike = userHasRole([user, collaborator], ROLE.Admin)
    || userHasRole([user, collaborator], ROLE.Moderator)
    || userHasRole([user, collaborator], ROLE.External);

  const menu = (
    <Menu className="dropdown-menu__items account-menu">
      {isAdminLike ? (
        <>
          <Paragraph
            preset="semibold14"
            className="p__x--20 p__t--16 p__b--8 opacity-6"
          >
            Admin
          </Paragraph>
          <Menu.Item
            key="admin"
          >
            <a href={config.admin.url}>
              <Text preset="semibold16">Dashboard</Text>
            </a>
          </Menu.Item>
        </>
      ) : <>
        <Paragraph
          preset="semibold14"
          className="p__x--20 p__t--16 p__b--8 opacity-6"
        >
          Connected Profiles
        </Paragraph>
        {collaborator ? (
          <>
            {collaborator?.talentProfile && (
              <Menu.Item>
                <a href={profileLink(collaborator?.talentProfile?.user?.username)}>
                  <Row align="middle">
                    <Col>
                      <AvatarProfile
                        avatarSize={24}
                        avatar={collaborator.talentProfile?.avatar || ""}
                        name={`${collaborator.talentProfile?.firstName || ""} ${collaborator.talentProfile?.lastName || ""
                          }`}
                      />
                    </Col>
                    <Col>
                      <Text preset="semibold16" className="m__l--8">
                        {collaborator.talentProfile?.firstName}{" "}
                        {collaborator.talentProfile?.lastName}
                      </Text>
                    </Col>
                  </Row>
                </a>
              </Menu.Item>
            )}
            {!isAdminLogin &&
              !!collaborationTalentProfiles?.length &&
              collaborationTalentProfiles.map(({ talentProfile }, index) => (
                <Menu.Item key={index}>
                  <a href={profileLink(talentProfile.user?.username)}>
                    <Row align="middle">
                      <Col>
                        <AvatarProfile
                          avatarSize={24}
                          avatar={talentProfile.avatar || ""}
                          name={`${talentProfile?.firstName || ""} ${talentProfile?.lastName || ""
                            }`}
                        />
                      </Col>
                      <Col>
                        <Text preset="semibold16" className="m__l--8">
                          {talentProfile.firstName} {talentProfile.lastName}
                        </Text>
                      </Col>
                    </Row>
                  </a>
                </Menu.Item>
              ))}
          </>
        ) : (
          <>
            {talentProfileOrigin && user?.username && (
              <Menu.Item>
                <a href={profileLink(user?.username)}>
                  <Row align="middle">
                    <Col>
                      <AvatarProfile
                        avatarSize={24}
                        avatar={user?.avatar || ""}
                        name={`${user?.firstName || ""} ${user?.lastName || ""
                          }`}
                      />
                    </Col>
                    <Col>
                      <Text preset="semibold16" className="m__l--8">
                        {user?.firstName}{" "}
                        {user?.lastName}
                      </Text>
                    </Col>
                  </Row>
                </a>
              </Menu.Item>
            )}
            {!isAdminLogin &&
              !!user?.collaborationTalentProfiles?.length &&
              user.collaborationTalentProfiles.map(({ talentProfile }, index) => (
                <Menu.Item>
                  <a href={profileLink(talentProfile.user?.username)}>
                    <Row align="middle">
                      <Col>
                        <AvatarProfile
                          avatarSize={24}
                          avatar={talentProfile.avatar || ""}
                          name={`${talentProfile?.firstName || ""} ${talentProfile?.lastName || ""
                            }`}
                        />
                      </Col>
                      <Col>
                        <Text preset="semibold16" className="m__l--8">
                          {talentProfile.firstName} {talentProfile.lastName}
                        </Text>
                      </Col>
                    </Row>
                  </a>
                </Menu.Item>
              ))}
          </>
        )}

        {userHasRole([user, collaborator], ROLE.SelfSign) ? (
          <Menu.Item
            key={`${MENU_LINKS.SETUP_PROFILE}-${ROLE.SelfSign}`}
            onClick={clickCreateProfile}
          >
            <Row align="middle">
              <Col className="d--flex align__items--center">
                <Icon name="add-blue-full-width" width={24} />
              </Col>

              <Col>
                <div className="p__l--8 d--flex flex__direction--column">
                  <Text preset="semibold16">Create a New Profile</Text>
                  <Text preset="semibold14" className="opacity--60">
                    30-day trial available
                  </Text>
                </div>
              </Col>
            </Row>
          </Menu.Item>
        ) : null}

        {userHasRole([user, collaborator], ROLE.Talent) ? (
          <Menu.Item
            key={`${MENU_LINKS.SETUP_PROFILE}-${ROLE.Talent}`}
            onClick={() => setRequestNewProfileModalIsOpen(true)}
          >
            <Row align="middle">
              <Col className="d--flex align__items--center">
                <Icon name="add-blue-full-width" width={24} />
              </Col>

              <Col>
                <div className="p__l--8 d--flex flex__direction--column">
                  <Text preset="semibold16">Request a New Profile</Text>
                </div>
              </Col>
            </Row>
          </Menu.Item>
        ) : null}
      </>
      }

      <Paragraph
        preset="semibold14"
        className="p__x--20 p__t--16 p__b--8 opacity-6"
      >
        Account
      </Paragraph>

      <Menu.Item
        key={MENU_LINKS.PROFILE_SETTINGS}
      >
        <a href={accountInformationUrl}>
          <Text preset="semibold16">Account Information</Text>
        </a>
      </Menu.Item>

      <Menu.Item
        key={MENU_LINKS.LOGOUT}
      >
        <Link to={"/logout"}>
          <Text preset="semibold16">Log Out</Text>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row className="main-header" align="middle" justify="space-between">
        <Col className="d--flex cursor-pointer">
          <Row align="bottom">
            <a href={config.client.url}>
              <KomiLogo width={100} color="primary-light" />
            </a>
          </Row>
          {timerComponent && <div className={"m__l--40"}>{timerComponent}</div>}
        </Col>

        {user && (
          <React.Fragment>
            {showAvatarProfile ? (
              <div onClick={toggleMobileMenu}>
                <Dropdown overlay={menu} placement="bottomRight">
                  <div className="m__l--32">
                    <AvatarProfile
                      avatarSize={32}
                      avatar={
                        (collaborator || user)?.talentProfile?.avatar ||
                        (collaborator || user)?.avatar ||
                        ""
                      }
                      name={`${(collaborator || user)?.talentProfile?.firstName ||
                        (collaborator || user)?.firstName
                        } ${(collaborator || user)?.talentProfile?.lastName ||
                        (collaborator || user)?.lastName
                        }`}
                    />
                  </div>
                </Dropdown>
              </div>
            ) : (
              mobile && (
                <Col className="d--flex align__items--center">
                  <Button
                    type="link"
                    onClick={() => {
                      copyToClipboard(komiLink);
                      notification.success({
                        message: `Your profile link has been copied successfully.`,
                      });
                    }}
                  >
                    <Icon name="share-link" />
                  </Button>
                </Col>
              )
            )}
          </React.Fragment>
        )}
      </Row>

      <RequestAProfileModal
        visible={requestNewProfileModalIsOpen}
        onCancel={() => setRequestNewProfileModalIsOpen(false)}
        onOk={() => setRequestNewProfileModalIsOpen(false)}
      />
    </>
  );
};

export default MainHeader;
