import { Response, ListQueryParams } from "../../redux/Common/types";
import { Api } from "../api";
import {
  Contents1To1Request,
  UpdateExperience1To1Request,
  GetExperienceByIdRequest,
  Experience,
  UpdateExperienceRequest,
} from "../../redux/Experience/types";
import {
  ExperienceSchduled,
  GetExperiencesScheduled,
} from "redux/ExperiencesScheduled/types";
import { CancelToken } from "axios";

export default abstract class ExperienceService {
  constructor(protected api: Api) {
    this.api = api;
  }

  getExperiencesScheduled = (
    params: GetExperiencesScheduled,
    cancelToken?: CancelToken
  ): Promise<Response<ExperienceSchduled[]>> => {
    return this.api.getExperiencesScheduled(params, cancelToken);
  };

  getAllExperiences = (
    params: ListQueryParams
  ): Promise<Response<Experience[]>> => {
    return this.api.getAllExperiences(params);
  };

  updateExperienceStatus = (
    payload: UpdateExperienceRequest
  ): Promise<Response<any>> => {
    return this.api.updateExperience(payload);
  };

  deleteExperienceInBundle = (
    bundleId: string,
    experienceId: string
  ): Promise<Response<any>> => {
    return this.api.deleteExperienceBundle(bundleId, experienceId);
  };

  abstract getExperiences(
    userId: string,
    params: ListQueryParams
  ): Promise<Response<any>>;

  abstract getExperienceById(
    payload: GetExperienceByIdRequest
  ): Promise<Response<any>>;

  abstract createExperience(
    payload: Contents1To1Request
  ): Promise<Response<any>>;

  abstract updateExperience(
    payload: UpdateExperience1To1Request
  ): Promise<Response<any>>;

  abstract publishExperience(
    payload: string,
    talentId?: number
  ): Promise<Response<any>>;
}
