import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { categoryService } from "services";
import { getCategoriesActions } from "./actions";

function* getCategories() {
  const result: any = yield* call(categoryService.getCategories);
  if (result.ok) {
    yield* put(getCategoriesActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoriesActions.FAILURE(result.message));
  }
}

export default function* bookingSagas(): SagaIterator {
  yield* takeLatest(getCategoriesActions.REQUEST, getCategories);
}
