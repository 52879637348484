import React, { ReactNode } from "react";
import message, { ConfigOptions } from "antd/lib/message";
import {
  NotificationMessage,
  NotificationIcon,
} from "components/NotificationMessage";
import { ErrorPublishNotification } from "components/ErrorPublishNotification";

export type NoticeType = "info" | "success" | "error" | "warning" | "loading";

const defaultConfig: ConfigOptions = {
  top: 5,
  duration: 5,
  maxCount: 5,
};

type MessageParams = {
  message: string | ReactNode;
  key?: string;
  description?: string;
  duration?: number;
  isOffDuration?: boolean;
};
type MessagesParams = {
  key: string;
  messages: any[];
  isOffDuration?: boolean;
};

class Notifiaction {
  constructor(config: ConfigOptions) {
    message.config(config);
  }

  private notify(type: NoticeType, params: MessageParams) {
    const key =
      typeof params.message === "string" ? params.message : params.key;

    message.open({
      key,
      type: type,
      className: "noti-message",
      icon: <NotificationIcon width={24} height={24} type={type} />,
      duration:
        params.duration === 0
          ? 0
          : params.duration || defaultConfig.duration || 2,
      content: (
        <NotificationMessage
          message={params.message}
          onClose={() => message.destroy(key)}
        />
      ),
    });
  }

  showErrorPublish(params: MessagesParams) {
    message.open({
      key: params.key,
      type: "error",
      className: "noti-message",
      icon: <NotificationIcon width={24} height={24} type={"error"} />,
      duration: undefined,
      content: (
        <ErrorPublishNotification
          messages={params.messages}
          onClose={() => message.destroy(params.key)}
        />
      ),
    });
  }

  destroy(key: string) {
    message.destroy(key);
  }

  error(params: MessageParams) {
    this.notify("error", params);
  }

  warn(params: MessageParams) {
    this.notify("warning", params);
  }

  success(params: MessageParams) {
    this.notify("success", params);
  }

  info(params: MessageParams) {
    this.notify("info", params);
  }
}

export default new Notifiaction(defaultConfig);
