import {
  AvailabilityRule,
  CreateAvailabilityRule,
  UpdateAvailabilityRule,
} from "redux/Availability/types";
import { Response } from "../redux/Common/types";
import { Api } from "./api";

interface ITalentService {
  createAvailabilityRule(payload: AvailabilityRule): Promise<Response<any>>;
}

export default class AvailabilityRuleService implements ITalentService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  createAvailabilityRule = (
    payload: CreateAvailabilityRule
  ): Promise<Response<any>> => {
    return this.api.createAvailability(payload);
  };

  updateAvailabilityRule = (
    payload: UpdateAvailabilityRule
  ): Promise<Response<any>> => {
    return this.api.updateAvailability(payload);
  };

  getAvailabilityRule = (): Promise<Response<any>> => {
    return this.api.getAvailableRules();
  };
}
