import { ApisauceInstance, create } from "apisauce";
import { getGeneralApiProblem } from "services/api/apiProblem";
import { ApiConfig, DEFAULT_SHOP_MY_SHELF_API_CONFIG } from "./api.config";

export class ShopMyShelfApi {
  api: ApisauceInstance;

  constructor(config: ApiConfig = DEFAULT_SHOP_MY_SHELF_API_CONFIG) {
    this.api = create({
      baseURL: config.url,
      timeout: config.timeout,
    });
  }

  async getCollectionById(id: number | string): Promise<any> {
    const response = await this.api.get(`/Collections/${id}`);
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }
    return { ok: response.ok, response: response.data };
  }
}

export default new ShopMyShelfApi();
