import { RoomToken } from "../../redux/Room/types";
import {
  GetBookingDetailRequest,
  RejectBookingRequest,
  CompleteBookingRequest,
  UpdateBookingAttachmentsRequest,
} from "../../redux/Booking/types";
import { Booking, GetBookingRequest } from "redux/Booking/types";
import { Pagination } from "redux/Common/types";
import { Response } from "../../redux/Common/types";
import BookingService from "./BookingService";
import { User } from "redux/User/types";

export default class BookingInteractiveLiveClassService extends BookingService {
  getBooking = (
    payload: GetBookingRequest
  ): Promise<Response<Pagination<Booking[]>>> => {
    return this.api.getBookings1To1(payload);
  };

  getBookingById = (
    payload: GetBookingDetailRequest
  ): Promise<Response<Booking>> => {
    return this.api.getBooking1To1ById(payload);
  };

  getRoomToken = (bookingId: number): Promise<Response<RoomToken>> => {
    return this.api.getLiveClassRoomToken(bookingId);
  };

  getRoomViewers = (roomId: number): Promise<Response<User[]>> => {
    return this.api.getRoomViewers(roomId);
  };

  rejectBooking = (
    payload: RejectBookingRequest
  ): Promise<Response<Booking>> => {
    return this.api.rejectBooking(payload);
  };

  completeBooking = (
    payload: CompleteBookingRequest
  ): Promise<Response<Booking>> => {
    return this.api.completeBooking(payload);
  };

  completeRoom = (roomId: number): Promise<Response<Booking>> => {
    return this.api.completeRoom(roomId);
  };

  updateBookingAttachments = (
    payload: UpdateBookingAttachmentsRequest
  ): Promise<Response<string[]>> => {
    return this.api.updateBookingAttachments(payload);
  };
}
