import { Api } from "./api";
import { Response } from "./../redux/Common/types";
import { ReorderShopifyStoreRequest } from "models/talent/talent-profile-module.model";
import Client from "shopify-buy";
export default class ShopifyServices {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getAuthUrl = (storeDomain: string): Promise<Response<any>> => {
    return this.api.getAuthShopifyUrl(storeDomain);
  };

  updateStoreName = (
    storeDomain: string,
    name: string
  ): Promise<Response<any>> => {
    return this.api.updateShopifyStoreName(storeDomain, name);
  };

  updateStoreStatus = (
    storeDomain: string,
    connected: boolean
  ): Promise<Response<any>> => {
    return this.api.updateShopifyStoreStatus(storeDomain, connected);
  };

  createClient = (accessToken: string, domain: string) => {
    const client = Client.buildClient({
      storefrontAccessToken: accessToken,
      domain: domain,
    });
    return client;
  };

  removeStore = (storeDomain: string): Promise<Response<any>> => {
    return this.api.removeShopifyStore(storeDomain);
  };

  reorderStore = (payload: ReorderShopifyStoreRequest[]) => {
    return this.api.reorderShopifyStore(payload);
  };
}
