import { Action, handleActions } from "redux-actions";
import { getExperiencesScheduledActions } from "./actions";
import { ExperiencesScheduledState } from "./types";

const handlers = {
  // get rules
  [getExperiencesScheduledActions.REQUEST]: (
    state: ExperiencesScheduledState
  ): ExperiencesScheduledState => ({
    ...state,
    loading: true,
    experiencesScheduled: null,
  }),
  [getExperiencesScheduledActions.FAILURE]: (
    state: ExperiencesScheduledState
  ): ExperiencesScheduledState => ({
    ...state,
    loading: false,
  }),
  [getExperiencesScheduledActions.SUCCESS]: (
    state: ExperiencesScheduledState,
    { payload }: Action<any>
  ): ExperiencesScheduledState => {
    return {
      ...state,
      loading: false,
      experiencesScheduled: payload,
    };
  },
};

const initialState: ExperiencesScheduledState = {
  error: null,
  loading: false,
  experiencesScheduled: null,
};

export const experiencesScheduledReducer =
  handleActions<ExperiencesScheduledState>(handlers, initialState);
