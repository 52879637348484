import {
  BandsintownItem,
  BaseItem,
  DataCaptureFormItem,
  EventItem,
  ExperienceItem,
  FanClubItem,
  GroupItem,
  LinkItem,
  MusicItem,
  OnDemandVideoItem,
  PodcastItem,
  ProductItem,
  ShopifyModuleItem,
  ShopifyProductItem,
  TalentModuleMixItem,
  TalentProfileModule,
  TalentProfileModuleGroup,
  TalentProfileModuleType,
  YoutubeVideoItem,
} from "models/talent/talent-profile-module.model";

// TODO: No `TalentProfileModuleType` enum value corresponds to `TalentModuleMixItem.DataCaptureFormItem`
// TODO: No `TalentModuleMixItem` enum value corresponds to `TalentProfileModuleType.SHOP_MY_SHELF` 
// TODO: No `TalentModuleMixItem` enum value corresponds to `TalentProfileModuleType.SHOPIFY_COLLECTION`
// TODO: No `TalentModuleMixItem` enum value corresponds to `TalentProfileModuleType.YOUTUBE`
// TODO: No `TalentModuleMixItem` enum value corresponds to `TalentProfileModuleType.YOUTUBE_COLLECTION`

export const isModuleGroup = (module: GroupItem): module is TalentProfileModuleGroup<TalentModuleMixItem> => {
  return module.type === TalentProfileModuleType.GROUP;
};

export const isProductItem = (module: GroupItem): module is TalentProfileModule<ProductItem> => {
  return module.type === TalentProfileModuleType.PRODUCT;
};
export const isLinkItem = (module: GroupItem): module is TalentProfileModule<LinkItem> => {
  return module.type === TalentProfileModuleType.LINK;
};
export const isMusicItem = (module: GroupItem): module is TalentProfileModule<MusicItem> => {
  return module.type === TalentProfileModuleType.MUSIC;
};
export const isOnDemandVideoItem = (module: GroupItem): module is TalentProfileModule<OnDemandVideoItem> => {
  return module.type === TalentProfileModuleType.ON_DEMAND_VIDEO;
};
export const isYoutubeVideoItem = (module: GroupItem): module is TalentProfileModule<YoutubeVideoItem> => {
  return module.type === TalentProfileModuleType.YOUTUBE_VIDEO;
};
export const isExperienceItem = (module: GroupItem): module is TalentProfileModule<ExperienceItem> => {
  return module.type === TalentProfileModuleType.EXPERIENCE;
};
export const isPodcastItem = (module: GroupItem): module is TalentProfileModule<PodcastItem> => {
  return module.type === TalentProfileModuleType.PODCAST;
};
export const isFanClubItem = (module: GroupItem): module is TalentProfileModule<FanClubItem> => {
  return module.type === TalentProfileModuleType.FAN_CLUB;
};
export const isBandsintownItem = (module: GroupItem): module is TalentProfileModule<BandsintownItem> => {
  return module.type === TalentProfileModuleType.BANDSINTOWN;
};
export const isShopifyProductItem = (module: GroupItem): module is TalentProfileModule<ShopifyProductItem> => {
  return module.type === TalentProfileModuleType.SHOPIFY_PRODUCT;
};
export const isShopifyModuleItem = (module: GroupItem): module is TalentProfileModule<ShopifyModuleItem> => {
  return module.type === TalentProfileModuleType.SHOPIFY;
};
export const isEventItem = (module: GroupItem): module is TalentProfileModule<EventItem> => {
  return module.type === TalentProfileModuleType.EVENTS;
};
export const isDataCaptureFormItem = (module: GroupItem): module is TalentProfileModule<DataCaptureFormItem> => {
  return module.type === TalentProfileModuleType.FORM_DATA;
};

export const flipVisibilityForItemAndCloneArray = (
  item: BaseItem,
  items: BaseItem[]
): any[] => {
  let index: number;
  if (item.order === undefined) index = items.findIndex((x) => x == item);
  else {
    index = item.order;
  }
  const updatedItem = Object.assign({}, items[index]);
  updatedItem.visible = !updatedItem.visible;
  updatedItem.isUpdate = true;
  const updatedItems = Array.from(items);
  updatedItems[index] = updatedItem;
  return updatedItems;
};

export const isEmptyModule = (module: TalentProfileModule<BaseItem>) => {
  return !module.items || !module.items.length;
};
