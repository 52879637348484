import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { payoutService } from "./../../services/index";
import { addPayoutMethodActions, getPayoutScheduleActions } from "./actions";
import { AddPayoutMethodRequest } from "./types";

function* addPayoutMethod({ payload }: Action<AddPayoutMethodRequest>) {
  const data: any = yield* call(payoutService.createPayoutMethod, payload);
  if (data.ok) {
    yield* put(addPayoutMethodActions.SUCCESS(data.response));
  } else {
    yield* put(addPayoutMethodActions.FAILURE(data?.message || null));
  }
}

function* getPayoutSchedule() {
  const data: any = yield* call(payoutService.getPayoutSchedule);
  if (data.ok) {
    yield* put(getPayoutScheduleActions.SUCCESS(data.response));
  } else {
    yield* put(getPayoutScheduleActions.FAILURE(data?.message || null));
  }
}

export default function* tagSagas(): SagaIterator {
  yield* takeLatest(addPayoutMethodActions.REQUEST, addPayoutMethod);
  yield* takeLatest(getPayoutScheduleActions.REQUEST, getPayoutSchedule);
}
