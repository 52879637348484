export const WeekdayTitle: { [string: string]: string } = {
  SU: "Sunday",
  MO: "Monday",
  TU: "Tuesday",
  WE: "Wednesday",
  TH: "Thursday",
  FR: "Friday",
  SA: "Saturday",
};

export const WEEKDAY_LIST = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const MINUTES: { [string: string]: number } = {
  "5": 300000,
  "15": 900000,
};
