import { User } from "redux/User/types";

export const userHasRole = (
  user: User | null | undefined | (User | null | undefined)[],
  role: string
): boolean => {
  const userObjects = Array.isArray(user) ? user : [user];

  return userObjects.some((user) =>
    Boolean(user?.roles?.find((userRole) => userRole.name === role))
  );
};

export function hasOwnership (user?: User | null) {
  return user && user.id === user.talentProfile?.user?.createdById;
}
