import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import {
  booking1To1Service,
  bookingLiveClassService,
} from "./../../services/index";
import {
  completeRoomActions,
  getRoomLiveClassTokenActions,
  getRoomTokenActions,
  getRoomViewersActions,
} from "./actions";

function* getRoomToken({ payload }: Action<number>) {
  const data: any = yield* call(booking1To1Service.getRoomToken, payload);
  if (data.ok) {
    yield* put(getRoomTokenActions.SUCCESS(data.response));
  } else {
    yield* put(getRoomTokenActions.FAILURE(data?.message || null));
  }
}

function* getRoomLiveClassToken({ payload }: Action<number>) {
  const data: any = yield* call(bookingLiveClassService.getRoomToken, payload);
  if (data.ok) {
    yield* put(getRoomLiveClassTokenActions.SUCCESS(data.response));
  } else {
    yield* put(getRoomLiveClassTokenActions.FAILURE(data?.message || null));
  }
}

function* getRoomViewers({ payload }: Action<number>) {
  const data: any = yield* call(
    bookingLiveClassService.getRoomViewers,
    payload
  );
  if (data.ok) {
    yield* put(getRoomViewersActions.SUCCESS(data.response));
  } else {
    yield* put(getRoomViewersActions.FAILURE(data?.message || null));
  }
}

function* completeRoom({ payload }: Action<number>) {
  const data: any = yield* call(bookingLiveClassService.completeRoom, payload);
  if (data.ok) {
    yield* put(completeRoomActions.SUCCESS(data.response));
  } else {
    yield* put(completeRoomActions.FAILURE(data?.message || null));
  }
}

export default function* roomSagas(): SagaIterator {
  yield* takeLatest(getRoomTokenActions.REQUEST, getRoomToken);
  yield* takeLatest(
    getRoomLiveClassTokenActions.REQUEST,
    getRoomLiveClassToken
  );
  yield* takeLatest(getRoomViewersActions.REQUEST, getRoomViewers);
  yield* takeLatest(completeRoomActions.REQUEST, completeRoom);
}
