import React from "react";
import { Experience, ExperienceTypeLabel } from "redux/Experience/types";
import "./ExperienceTypeTag.scss";

interface IProps {
  experience?: Experience | undefined;
  experienceType?: string;
  className?: string;
}

const ExperienceTypeTag = ({
  experience,
  className,
  experienceType,
}: IProps) => {
  return (
    <div
      className={`experience-type-tag ${
        experience?.type || experienceType
      } ${className}`}
    >
      {ExperienceTypeLabel.get(
        (experience?.type as string) || experienceType || ""
      )}
    </div>
  );
};

export default ExperienceTypeTag;
