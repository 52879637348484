import Cookies from "js-cookie";
import { User } from "./../redux/User/types";
import { Api } from "./api/komi.api";
import { Response, IToken } from "redux/Common/types";
import config from "config";

export const KOMI_AUTH = "KOMI_AUTH";
export const KOMI_SESSION = "KOMI_SESSION";

/**
 * NOTE: auth on the talent app is now "passive"
 *  - login occurs in client or admin (originator)
 *  - logout can be initiated here but redirects to the originator (originator)
 *  - the server now determines what to do rather than the other way around
 */

export default class AuthService {
  private _authenticated = false;
  private _id: string | null = null;

  constructor(private readonly api: Api) {
    this.api = api;
    this._authenticated = Cookies.get(KOMI_SESSION) === 'true';
  }

  get authenticated (): boolean {
    return this._authenticated;
  }

  private set authenticated (value: boolean) {
    this._authenticated = value;
    Cookies.set(KOMI_SESSION, `${value}`, {
      domain: `.${config.service.domain}`
    })
  }

  getUserProfile = (): Promise<Response<User>> => {
    return this.api.getUserProfile()
      .then(resp => {
        const value = resp as any;
        this.authenticated = true;
        this._id = value.ok && value.response?.user?.id;
        return resp;
      })
  }

  get id(): string | null {
    return this._id;
  }

  logout = (): Promise<Response<boolean>> => {
    return this.api.logout()
      .then(resp => {
        this._id = null;
        this.authenticated = false;
        return resp;
      });
  };
}
