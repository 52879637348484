import { Button, Spin, Tabs, Typography } from "antd";
import ExperienceContentCard from "components/ExperienceContentCard";
import Loading from "components/Loading";
import { ReactComponent as AddIcon } from "icons/add.svg";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination } from "redux/Common/types";
import {
  getExperiencesActions,
  updateExperienceActions,
} from "redux/Experience/actions";
import {
  selectExperienceLoading,
  selectExperiences,
  selectUpdateSuccessExperience,
} from "redux/Experience/selector";
import {
  Experience,
  EXPERIENCE_STATUS,
  EXPERIENCE_TYPE,
  StateExperienceLocation,
} from "redux/Experience/types";
import { useTypedSelector } from "redux/rootReducer";
import { selectUserData } from "redux/User/selector";
import "./ExperienceContent.scss";
import { SEGMENT_EVENTS } from "../../constants/segment";
import { isMobile } from "react-device-detect";
import { webviewMessage } from "../../utils/webviewMessage";
import { convertToUrl } from "utils/string";
import useScrollTop from "hooks/useScrollTop";
import StripeConnectButton from "components/StripeConnectButton/StripeConnectButton";
import { Text } from "components/Typography";
import { Row, Col } from "antd/lib/grid";
import { selectPayoutError, selectPayoutSchedule } from "redux/Payout/selector";
import { getPayoutScheduleActions } from "redux/Payout/actions";
import config from "config";

const { TabPane } = Tabs;
const { Title } = Typography;

const ExperienceContent = () => {
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true);
  const history = useHistory();
  const location = useLocation<StateExperienceLocation>();
  const loading = useTypedSelector(selectExperienceLoading);
  const updateSuccess = useTypedSelector(selectUpdateSuccessExperience);
  const contents: Pagination<Experience[]> | null | undefined =
    useTypedSelector(selectExperiences);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTab, selectCurrentTab] = useState<EXPERIENCE_TYPE | "ALL">(
    "ALL"
  );
  const [contentList, setContentList] = useState<Experience[]>([]);
  const [showingStripeConnectLayer, setShowingStripeConnectLayer] =
    useState(false);

  const user = useTypedSelector(selectUserData);
  const payoutSchedule = useTypedSelector(selectPayoutSchedule);
  const payoutScheduleError = useTypedSelector(selectPayoutError);

  const [scrollTop] = useScrollTop();

  const handleChangeTab = useCallback((key: string) => {
    scrollTop();
    selectCurrentTab(key as EXPERIENCE_TYPE);
  }, []);

  useEffect(() => {
    (window as any)?.analytics?.track(SEGMENT_EVENTS.VIEW_EXPERIENCES_PAGE, {
      Platform: webviewMessage.isWebView()
        ? "Webview"
        : isMobile
          ? "Responsive"
          : "Web",
      Id: user?.id,
      Name: `${user?.talentProfile?.firstName} ${user?.talentProfile?.lastName}`,
      Email: user?.email,
    });
  }, []);

  useEffect(() => {
    if (!user) return;

    if (
      user.isConnectedStripeAccount &&
      payoutSchedule &&
      payoutSchedule.payoutsEnabled &&
      payoutSchedule.payoutsEnabled
    ) {
      setShowingStripeConnectLayer(false);
    } else if (
      payoutScheduleError ||
      (user.isConnectedStripeAccount &&
        payoutSchedule &&
        !payoutSchedule.payoutsEnabled) ||
      (user.isConnectedStripeAccount &&
        payoutSchedule &&
        !payoutSchedule.paymentEnabled)
    ) {
      setShowingStripeConnectLayer(true);
    }
  }, [user, payoutSchedule, payoutScheduleError]);

  useEffect(() => {
    const locationContentType = location?.state?.contentType;

    if (locationContentType) selectCurrentTab(locationContentType);
  }, [location]);

  useEffect(() => {
    const nextPage = contents?.meta?.currentPage || 0;
    if (currentPage === 1 && contents) {
      setContentList(contents?.items || []);
    }
    if (currentPage < nextPage) {
      setCurrentPage(nextPage);
      setContentList([...contentList, ...(contents?.items || [])]);
      contents?.meta && setHasMore(nextPage < contents?.meta?.totalPages);
    }
  }, [contents, currentPage, contentList]);

  useEffect(() => {
    if (user) {
      dispatch(
        getExperiencesActions.REQUEST({
          type: currentTab,
          params: {
            limit: 10,
            page: 1,
            sort: `-createdAt`,
          },
        })
      );
      dispatch(getPayoutScheduleActions.REQUEST());
    }
  }, [dispatch, user]);

  useEffect(() => {
    setHasMore(true);
    setCurrentPage(1);
    dispatch(
      getExperiencesActions.REQUEST({
        type: currentTab,
        params: {
          limit: 10,
          page: 1,
          sort: `-createdAt`,
        },
      })
    );
  }, [currentTab, dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(
        getExperiencesActions.REQUEST({
          type: currentTab,
          params: {
            limit: 10,
            page: 1,
            sort: "-createdAt",
          },
        })
      );
      setCurrentPage(1);
    }
  }, [updateSuccess, dispatch]);

  const handleInfiniteOnLoad = useCallback(() => {
    if (hasMore && contents?.meta) {
      dispatch(
        getExperiencesActions.REQUEST({
          type: currentTab,
          params: {
            page: contents.meta?.currentPage + 1,
            limit: contents.meta?.itemCount,
            sort: `-createdAt`,
          },
        })
      );
    }
  }, [dispatch, contents, hasMore, currentTab]);

  const handleEditExp = useCallback(
    (content: Experience) => {
      history.push({
        pathname: `/experience/content/${content.type}/${content.id}`,
        state: {
          data: content,
        } as StateExperienceLocation,
      });
    },
    [history]
  );

  const handlePreviewExp = useCallback((content: Experience) => {
    const category = content?.creator?.talentProfile?.categories?.[0]?.name;
    window.open(
      `${config.client.url}/${content.type === EXPERIENCE_TYPE.BUNDLE ? "bundle" : "experience"
      }/${convertToUrl(category)}/${convertToUrl(
        content.creator?.username
      )}/${convertToUrl(content.name)}?id=${content?.id}`,
      "_blank"
    );
  }, []);

  const handleUpdateExpStatus = useCallback(
    (content: Experience, status: string) => {
      dispatch(
        updateExperienceActions.REQUEST({
          requestBody: { status },
          id: content.id,
        })
      );
    },
    []
  );

  const handleDeleteExp = useCallback((content: Experience) => {
    dispatch(
      updateExperienceActions.REQUEST({
        requestBody: { status: EXPERIENCE_STATUS.ARCHIVED },
        id: content.id,
      })
    );
  }, []);

  const handleCreateExp = useCallback(() => {
    history.push("/experience/content/create");
  }, [history]);

  const renderContent = useCallback(() => {
    return (
      <React.Fragment>
        {loading && !hasMore && <Loading />}
        <InfiniteScroll
          dataLength={contents?.meta?.totalItems || 0}
          next={handleInfiniteOnLoad}
          hasMore={hasMore}
          loader={null}
        // scrollableTarget="root"
        >
          {contentList.map((content, index) => (
            <ExperienceContentCard
              key={index}
              content={content}
              onEdit={(item: Experience) => handleEditExp(item)}
              onPreview={(item: Experience) => handlePreviewExp(item)}
              onUpdateStatus={(item: Experience, status: string) =>
                handleUpdateExpStatus(item, status)
              }
              onDelete={(item: Experience) => handleDeleteExp(item)}
            />
          ))}
          {loading && hasMore && (
            <Row justify="center" align="middle">
              <Spin />
            </Row>
          )}
        </InfiniteScroll>
      </React.Fragment>
    );
  }, [
    contents,
    currentTab,
    handleInfiniteOnLoad,
    handleEditExp,
    handlePreviewExp,
    handleUpdateExpStatus,
    hasMore,
    loading,
    contentList,
  ]);

  return (
    <React.Fragment>
      {showingStripeConnectLayer && (
        <div className="stripe-connect-layer">
          <div className="stripe-connect-layer__content">
            {payoutSchedule &&
              (!payoutSchedule?.payoutsEnabled ||
                !payoutSchedule?.paymentEnabled) && (
                <Row justify="center">
                  <Text preset="semibold16" className="text__align--center">
                    More information is required to process payments.
                  </Text>
                </Row>
              )}
            <Row justify="center">
              <Text preset="semibold16" className="text__align--center">
                Please set up your payout account to continue to create new
                experiences.
              </Text>
            </Row>
            <Row
              align="middle"
              justify="center"
              gutter={24}
              className="m__t--24"
            >
              <Col>
                <StripeConnectButton tooltipSupport={false} />
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div className="experience-content">
        <Row
          align="middle"
          justify="space-between"
          className="experience-content__header p__b--32"
        >
          <Title level={4} className="font__size--medium2 m--0">
            Experiences
          </Title>
          <div>
            <Button
              type="primary"
              className="btn-create-experience desktop__only"
              onClick={handleCreateExp}
            >
              Create New Experience
            </Button>
            <Button
              className="mobile__only p--0"
              type="text"
              onClick={handleCreateExp}
            >
              <AddIcon />
            </Button>
          </div>
        </Row>
        <Tabs activeKey={currentTab} onChange={handleChangeTab}>
          <TabPane tab="All" key={"ALL"}>
            <div className="experience-content__wrapper">
              <Title className="font__size--medium m__t--40">
                All Experiences
              </Title>
              {renderContent()}
            </div>
          </TabPane>
          <TabPane tab="Bundles" key={EXPERIENCE_TYPE.BUNDLE}>
            <div className="experience-content__wrapper">
              <Title className="font__size--medium m__t--40">
                Your Bundles
              </Title>
              {renderContent()}
            </div>
          </TabPane>
          <TabPane tab="1-to-1" key={EXPERIENCE_TYPE.ONE_TO_ONE}>
            <div className="experience-content__wrapper">
              <Title className="font__size--medium m__t--40">
                Your 1-to-1 Video Sessions
              </Title>
              {renderContent()}
            </div>
          </TabPane>
          <TabPane
            tab="Live Class"
            key={EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS}
          >
            <Title className="font__size--medium m__t--40">
              Your Live Classes
            </Title>
            {renderContent()}
          </TabPane>
          <TabPane tab="Live Broadcasts" key={EXPERIENCE_TYPE.LIVE_CLASS}>
            <Title className="font__size--medium m__t--40">
              Your Live Broadcasts
            </Title>
            {renderContent()}
          </TabPane>
          <TabPane
            tab="On-Demand Content"
            key={EXPERIENCE_TYPE.EXCLUSIVE_CONTENT}
          >
            <Title className="font__size--medium m__t--40">
              On-demand Videos
            </Title>
            {renderContent()}
          </TabPane>
          <TabPane tab="Courses" key={EXPERIENCE_TYPE.COURSE}>
            <Title className="font__size--medium m__t--40">Your course</Title>
            {renderContent()}
          </TabPane>
        </Tabs>
      </div>
    </React.Fragment>
  );
};

export default ExperienceContent;
