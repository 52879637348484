import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Icon } from "components/Icon";
import { Paragraph } from "components/Typography";
import * as React from "react";
import { useCallback } from "react";
import "./NotSupportSizeBrowser.scss";

interface NotSupportSizeBrowserProps {
  isNotSetup?: boolean;
}

const NotSupportSizeBrowser = ({ isNotSetup }: NotSupportSizeBrowserProps) => {
  const screens = useBreakpoint();
  const isMobile = screens["xs"];
  const renderTitle = useCallback(() => {
    return (
      <Paragraph
        className="text__align--center m__t--24 m__b--8 letter__spacing-05"
        preset={screens["xs"] ? "semibold20" : "semibold24"}
      >
        {isNotSetup
          ? "Your account is set up!"
          : "Please Switch to a Larger Device to Access This Site"}
      </Paragraph>
    );
  }, [isNotSetup]);
  const renderDescription = useCallback(() => {
    if (isNotSetup) {
      return (
        <>
          <Paragraph
            className="text__align--center opacity--60 letter__spacing-02 m__b--12"
            preset="regular16"
          >
            Your profile has been created.
          </Paragraph>
          <Paragraph
            className="text__align--center opacity--60 letter__spacing-02"
            preset="regular16"
          >
            {isMobile
              ? `Unfortunately, Komi’s profile editor does not currently support
            mobile devices. To start adding your content, please login at
            www.komi.io on a desktop device and access your invited talent
            profile`
              : `Unfortunately, Komi’s profile editor does not currently support this screen size. To start adding your content, please increase the size of your browser window.`}
          </Paragraph>
        </>
      );
    }
    return (
      <Paragraph
        className="text__align--center opacity--60 letter__spacing-02"
        preset="regular16"
      >
        {isMobile
          ? "Komi does not currently support this device. Please use a desktop or laptop computer to continue"
          : "Komi does not currently support this screen size. Please switch to a larger device or increase the size of your browser window to continue"}
      </Paragraph>
    );
  }, [isNotSetup, isMobile]);

  return (
    <div className="not-support-size-browser">
      <div className="not-support-size-browser__container">
        <Icon
          name={isNotSetup ? "desktop-illustration-2" : "desktop-illustration"}
        />
        {renderTitle()}
        {renderDescription()}
      </div>
    </div>
  );
};

export default NotSupportSizeBrowser;
