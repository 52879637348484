import { useEffect } from "react";

import { useTypedSelector } from "redux/rootReducer";
import {
  selectResponseType,
  selectResponseData,
} from "redux/LogRequest/selector";
import { RESPONSE_TYPE } from "redux/LogRequest/types";
import notification from "utils/notification";

export const useNotificationRequest = (): void => {
  const responseType = useTypedSelector(selectResponseType);
  const responseData = useTypedSelector(selectResponseData);

  useEffect(() => {
    const { message, code, type, statusCode } = responseData;

    if (!message && !type) return;

    const notificationData = {
      message: type?.toUpperCase() || "Error",
    };

    if (responseType === RESPONSE_TYPE.SUCCESS) {
      notification.success({
        ...notificationData,
        description: "",
      });
    }
    if (responseType === RESPONSE_TYPE.ERROR) {
      notification.error({
        ...notificationData,
        description: `${statusCode || code}: ${message}`,
      });
    }
  }, [responseType, responseData]);
};
