export enum DspType {
  WEBSITE = "WEBSITE",
  AMAZON_MUSIC = "AMAZON_MUSIC",
  APPLE_MUSIC = "APPLE_MUSIC",
  DEEZER = "DEEZER",
  SOUNDCLOUD = "SOUNDCLOUD",
  YOUTUBE_MUSIC = "YOUTUBE_MUSIC",
  SPOTIFY = "SPOTIFY",
  TIDAL = "TIDAL",
  AUDIOMACK = "AUDIOMACK",
  NAPSTER = "NAPSTER",
  BANDCAMP = "BANDCAMP",
  QOBUZ = "QOBUZ",
  SIRIUSXM = "SIRIUSXM",
  GAANA = "GAANA",
  BEATPORT = "BEATPORT",
  TIKTOK = "TIKTOK",
  IHEARTRADIO = "IHEARTRADIO",
  ANGHAMMI = "ANGHAMMI",
  MIXCLOUD = "MIXCLOUD",
  PANDORA = "PANDORA",
  // digital download
  ITUNES_DIGITAL_DOWNLOAD = "ITUNES_DIGITAL_DOWNLOAD",
  AMAZONE_DIGITAL_DOWNLOAD = "AMAZONE_DIGITAL_DOWNLOAD",
  // physical purchase
  HMV = "HMV",
  TARGET = "TARGET",
  WALMART = "WALMART",
  URBAN_OUTFITTERS = "URBAN_OUTFITTERS",
  RECORD_STORE = "RECORD_STORE",
  AMAZONE_PHYSICAL_PURCHASE = "AMAZONE_PHYSICAL_PURCHASE",
  OFFICIAL_STORE = "OFFICIAL_STORE",
  BANQUET_RECORDS = "BANQUET_RECORDS",
  BEAR_TREE_RECORDS = "BEAR_TREE_RECORDS",
  BLEEP = "BLEEP",
  CRASH_RECORDS = "CRASH_RECORDS",
  DEEJAY_DE = "DEEJAY_DE",
  JUNO = "JUNO",
  DRIFT = "DRIFT",
  NORMAN_RECORDS = "NORMAN_RECORDS",
  PICCADILLY_RECORDS = "PICCADILLY_RECORDS",
  REDEYE = "REDEYE",
  RESIDENT = "RESIDENT",
  ROUGH_TRADE = "ROUGH_TRADE",
  VINILO = "VINILO"
}

export interface DspMetadata {
  dsp: DspType;
  smallLogoUrl: string;
  bigLogoUrl: string;
}

export enum DspStatus {
  Error = 'Error',
  Success = 'Success'
}

export interface DspLinkMap {
  resourceUrl: string;
  previewUrl?: string;
}

export interface DspLinkPayload {
  type: DspType;
  status: DspStatus;
  message?: string;
  items?: DspLinkMap[];
}

export interface DspLink {
  type: DspType;
  url: string;
  previewUrl?: string;
  isVisible: boolean;
}

export enum DspResourceType {
  ALBUM = "ALBUM",
  TRACK = "TRACK",
  //Supporting Legacy Types only
  SHOW = "show",
  USER = "user",
  PLAYLIST = "playlist",
  ARTIST = "artist",
  EPISODE = "episode"
}

export interface DspResource {
  name: string;
  albumName?: string;
  type: DspResourceType;
  upc?: string;
  isrc?: string;
  location?: string;
  artists?: string;
}