import {
  AutoSaveContext,
  IContext,
} from "components/AutoSaveProvider/AutoSaveProvider";
import { useContext } from "react";

export const useAutoSave = (): IContext => {
  const context = useContext<IContext>(AutoSaveContext);
  if (!context) {
    throw new Error(
      "useAutoSaveContext must be used within a AutoSaveProvider"
    );
  }
  return context;
};
