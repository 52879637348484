import React from "react";
import "./ExpertTerms.scss";

const ExpertTerms = () => {
  return (
    <div className="expert-terms">
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "center",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi Terms for Experts
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "center",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Last updated: 18 November 2020
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "center",
          marginTop: "18pt",
          marginBottom: "18pt",
        }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Welcome to Komi. Join our expert community!
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          These terms (“
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Expert Terms
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”), and the documents we refer to in them, apply to your use of the
          Komi platform and to transactions involving the platform, including
          our website and our mobile and tablet applications (“
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”).&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Please read through our Expert Terms carefully, to understand what we
          can do for you, how you can use Komi and how transactions through Komi
          work.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We suggest that you also read through the Komi Terms of Service (”
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          User Terms
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”), as they govern how Komi (and content, subscriptions and services
          you make available through Komi) is made available to consumers.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          When you use Komi, and when you create an account with us, you are
          agreeing to these Expert Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Who we are
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi App Limited (“
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          we
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”, “
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          us
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”) is a company registered in England and Wales, with company number
          12268875 and registered address: 21 Bedford Square, London, United
          Kingdom, WC1B 3HH. Email address:&nbsp;
        </span>
        <a href="mailto:am@komiapp.co" style={{ textDecoration: "none" }}>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Verdana",
              color: "#0000ff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            am@komiapp.co
          </span>
        </a>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          .
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi works for experts
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi is a platform to connect you together with your audience and
          consumers at large so that you can focus on doing what you do best:
          providing great expert content and services directly to them.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We don’t want to get in your way. When a consumer purchases your
          content, subscription offering or services, such as to attend a
          live-streamed class, a 1-to-1 tutorial or other content or services
          you choose to make available through Komi, their agreement is with
          you. We act as your vendor, such as for managing payment from
          customers and so we can help manage their experience in Komi.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your Komi Expert account
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Before you can begin to make your expert content, subscriptions and
          services available through Komi, you will first need to create a Komi
          Expert account. To be able to create a Komi Expert account, you must:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Be at least 18 years old.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              If requested by us, provide information about the type of category
              of content and services you intend to offer to consumers through
              Komi.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Provide us with any other accurate information that we might
              reasonably request in writing from you, such as during the Komi
              Expert account setup process, so that we can understand your
              expert skills and experience and check that we think the Komi
              expert community is the right place for you and your content and
              services.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We will need you to select an account password and other security
          settings, to help us keep your Komi Expert account safe. You must not
          share your Komi Expert account details with others. You are
          responsible for control of your Komi Expert account, such as keeping
          your password and account details safe and secure, and making sure
          that the content and services you make available to consumers are what
          you intend and are appropriate for the platform.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          To the extent necessary for us to be able to provide Komi, you approve
          the content of the User Terms, authorise us to act in accordance with
          them and confirm you will abide by them (to the extent they directly
          or indirectly refer or relate to obligations from you to consumers).
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          What to do when making content or services available through Komi
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Describe your content and services accurately
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We want to make sure Komi consumer users understand what they are
          paying you for. To help make that happen, you must ensure that you
          take care to describe your content and services accurately before
          making them available for consumers to consider and purchase through
          Komi (and before making available any information about your skills,
          biographic information, accreditations, qualifications and experiences
          (your “
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Relevant Descriptions
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”)). You are solely responsible for your content and services and, in
          particular but without limitation, ensuring that your Relevant
          Descriptions are accurate, truthful and not misleading. Failure to
          provide accurate Relevant Descriptions may result in loss of access to
          Komi (including without limitation the suspension or termination of
          your Komi Expert account).
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Make sure you have the necessary rights first
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          For content and services you make available through Komi, it is our
          expectation that all content and services you provide will be original
          creations and works by you only. It is your responsibility to ensure
          you have the necessary rights and clearances in order to be able to
          provide your content and services, to grant us the rights and licences
          that you grant us under these Expert Terms and, in particular, all
          necessary rights and licences so that we are able to grant the rights
          and licences to consumers that we do regarding your content and
          services under the User Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Checking your materials
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We give Komi Expert account holders control over your content and
          services. We don’t moderate your posts / messages, content, services
          or offerings before they are made live to consumers. You acknowledge
          and accept that you are solely responsible for your content,
          subscriptions, services, materials, posts and offerings including
          without limitation the legality, accuracy and compliance of them with
          all applicable laws and regulations. Failure to comply with the
          foregoing may result, at our discretion, in us suspending or
          terminating your Komi Expert account.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Identify 18+ content appropriately
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We require that you identify appropriately to consumers, such as in
          descriptions and titles you make available, which of your content,
          subscriptions or services are only appropriate for those aged 18 or
          over. You are responsible for making this clear to consumers and
          exercising best efforts so that your services, subscriptions or
          content are not made available to an inappropriate audience.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Take control of your pricing
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You can decide how much to charge consumers for your content and
          services through Komi. Notwithstanding the foregoing, we reserve the
          right to change, amend or reject pricing in our discretion (without
          obligation to notify you). We may apply dynamic pricing, our
          processing overheads or similar costs and measures. We reserve the
          right to remove listed content or services you try to offer through
          Komi and/or to cancel purchases of content or services you have
          offered, where in our discretion it appears to us that your pricing is
          grossly exploitative of consumers, is illegal or is in error.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Keep the Komi user experience in mind
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We want to make sure Komi is a great experience for everyone. To do
          that, we need your help and for you to apply your best efforts in
          ensuring: (i) the good quality and experience of all content,
          subscriptions and services you make available through Komi; and (ii)
          good standards of customer services that you provide to consumers,
          where applicable (such as where they ask you a question or ask for
          support); and (iii) bookings / purchases made by Komi consumer users
          are honoured and fulfilled. You acknowledge and agree that failure to
          comply with the foregoing is a breach of these Expert Terms and may
          result in, without limitation, us suspending or terminating your Komi
          Expert account access.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          How payments through Komi work
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You can make your chosen content, subscriptions and services
              available through Komi, using the tools and functionality we make
              available to you (which may vary from time to time in our
              discretion).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              When a consumer purchases content or services from you, we will
              act as your vendor with handling, managing and concluding the
              consumer’s purchase and experience. We would also help with
              payment-related issues such as suspected fraud, cancellations /
              chargebacks and helping to solve disputes with customers.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To maintain the quality and integrity of Komi, we reserve the
              right to reject to process (or to cancel, if processing has
              already happened) any purchases made through Komi that appear to
              us to be mistakes, exploitative, in breach of these Expert Terms
              or illegal. If we do so, we will refund the consumer on your
              behalf.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We may not pay you any relevant fees for a transaction otherwise
              due to you where a refund, or similar, is processed by us in
              accordance with these Expert Terms. For the avoidance of doubt,
              where a refund or similar occurs after the relevant sum is paid to
              you, we will then have the right to recoup that sum from you.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We require that consumers pay in full when making their purchase
              for any transaction through Komi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We will hold these funds (and/or our elected payment processor
              vendor will) until you have completed the relevant service and/or
              provided the relevant content and/or booking. After that date,
              reasonably promptly, we will make the funds available to you, for
              payment to your elected bank account. We are able to arrange such
              payments by electronic bank transfers only, unless otherwise
              agreed in writing by us.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              At our discretion and taking account of the nature of transactions
              relevant to your Komi Expert account, we may either make such
              payments to you on an ad hoc basis or on a recurring,
              date-specific basis.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We know you want to be paid quickly; please bear with us as,
              before paying you for the transaction, we need to deduct
              first-party platform fees and costs first and, in some cases,
              payment to you may take longer if we suspect, and need to
              investigate, whether a transaction is in error or is fraudulent or
              if it is subject to an ongoing customer complaint.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              If, because of the processing of refunds, cancellations or
              otherwise because of the processing of deductions from or
              liabilities regarding your Komi Expert account, you may owe us a
              correction payment. If that is the case, we reserve the right to
              recover the correction payment from future payments we would have
              otherwise made to you. We also reserve the right to require
              either: (i) that you make direct payment to the relevant consumers
              (such as of the refund); or (ii) that you pay such relevant
              correction payment amounts back to us.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Payment by a consumer to us satisfies their obligation to pay you
              for the content and services relevant to the transaction.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You are responsible for your own tax and tax reporting (including
              without limitation national insurance payments, income tax,
              corporation tax or similar or equivalent), such as in respect of
              income you receive from transactions relating to Komi and
              purchases by consumers in accordance with these Expert Terms.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Gratuity
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi may provide consumers with an option to provide a gratuity to
          you. Gratuities are voluntary for consumers and are additional to
          ordinary purchase prices for consumers. If a consumer chooses to
          provide a gratuity to you, the gratuity will be paid to us in the
          first instance but we will then make it available to you reasonably
          promptly (less payment processing costs (if any are applicable)).
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          What you pay to Komi
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We want to provide you with certainty over what our fees are.
          Regardless of how the consumer pays you or what they pay you for, the
          Komi Service Fee (defined below) is the same. We do not charge you a
          member subscription or overall platform fee for your Komi Expert
          account. Instead, we only charge you a 20% per-transaction service fee
          when consumers purchase your content, subscriptions (whether monthly,
          annual or otherwise) or services through Komi (“
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi Service Fee
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”).
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The Komi Service Fee is deducted from the sum paid to you by the
          consumer through Komi, which we collect on your behalf, only after
          first-party platform fees are deducted first (such as, for example, of
          the Apple App Store) and other reasonable costs. We then, after any
          applicable taxes, make payment onwards to you for the relevant
          transaction sum in accordance with these Expert Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi’s usage rules for experts&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Please use Komi safely and responsibly, and keeping in mind the safety
          of Komi&apos;s consumer users, following the usage rules for experts
          below. References to ‘posting’ or ‘post’ below include selling or
          making available for sale or purchase, communicating (including
          without limitation by audio-visual material, stream or other media),
          transmitting, conducting or otherwise making available in any content,
          services or promotion of them.
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not hold yourself out as acting for us, as having the right to
              bind us to any legal agreement, or that you are authorised to
              speak on our behalf or imply that you are entitled to do so.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Except through our Komi functionality that specifically allows for
              setting out your e-commerce links and references, do not use Komi
              to promote, market, advertise, endorse, support or feature in any
              way (including without limitation within your posts) any third
              party products or services. For the avoidance of doubt, you are
              permitted to promote your own content and services for which you
              have registered your Komi Expert account regarding their
              availability within Komi.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not offer or promote any regulated content, services or
              products through Komi including without limitation alcohol,
              gambling, tobacco products (including e-cigarettes), drugs,
              chemicals, financial advice or medical advice.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not post any content in Komi which seeks or makes any
              arrangement to meet any person under the age of 18.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not impersonate or attempt to impersonate any other persons or
              organisations whilst using Komi, including without limitation in
              your posts.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not post in Komi, or use or attempt to use in Komi, any
              content, services or materials which infringe the rights of any
              third party (including without limitation their intellectual
              property rights or privacy rights).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not post in Komi any materials or content which is
              pornographic, sexual or sexually explicit, offensive, immoral (in
              our view, acting reasonably), discriminatory, illegal (under the
              laws of England and Wales and the territory in which you are
              located), defamatory, threatening, violent or otherwise intended
              to harm or harass others. We reserve the right to decide at our
              sole discretion whether particular content or materials breach the
              foregoing restrictions or the spirit of them.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not post in Komi any content which is homophobic, abusive,
              hateful, seditious, encourages violation of these Expert Terms,
              involves unsolicited marketing or SPAM, or which is technically
              harmful or may be technically harmful such as viruses, logic
              bombs, harmful data or any other malicious software or process.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not sell, copy, loan, rent, scrape, index, crawl, data mine,
              publish, modify, adapt or reproduce any part of Komi. However,
              using tools we make available, you can post on your personal
              social media about Komi (including to promote the availability of
              your content and services on Komi).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not attempt to gain unauthorised access to or to disrupt Komi
              or any part of it or content within Komi, circumvent security
              measures, interfere with or damage any part of Komi or content
              within Komi or our systems, devices or servers.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Do not (or attempt to) hack, mimic, disable or obscure Komi
              software or content in Komi, or merge, disassemble, decompile or
              reverse-engineer Komi software or content.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You must only use Komi for lawful purposes (under the laws of
              England and Wales and the territory in which you are located). Do
              not use or attempt to use Komi in any way that breaches any
              national, local or international law or regulation. You are
              responsible for ensuring that your use of Komi and all of your
              posts, actions, activities, content and services provided through
              or in connection with your Komi Expert account are in full
              compliance with applicable law.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You must conduct yourself in a reasonably professional manner at
              all times and in such a way as to avoid reputational harm to us or
              the Komi platform.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            marginLeft: "-18pt",
            paddingLeft: "3.3000000000000007pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              From time to time we may make additional community, safety and
              security rules and guidelines available to you through Komi. This
              may include guidelines for the whole Komi community, or which
              apply specifically to Komi Expert account holders. Where we do so,
              you must read and comply with them.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your discounts and promotions
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You have the right to operate lawful promotions and discounts in
          regards to your offering of content and services through Komi to
          consumers. Where you choose to make a promotion or discount available,
          please be aware that it is your responsibility to ensure that the
          promotion or discount is in full compliance with all applicable laws
          and industry codes of good practice. You must make clear in your
          posts, marketing and promotion of your discounts and promotions that
          the discount or promotion is offered by you.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          From time to time in our discretion, we may assist you with driving
          audience and consumer engagement to your Komi Expert account and your
          content or services such as (whether specifically or as part of our
          holistic efforts for promoting Komi) by operating and offering to
          consumers on your behalf certain limited promotional offers, free
          copies or access, price discounting and/or similar activities which
          may involve your Komi Expert account and/or your content and services.
          You authorise us to do the foregoing and you also acknowledge and
          agree that no payments will be due to you (for example, without
          limitation, where a promotion involves free-of-charge access to
          consumers).
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Cancellations, charge-backs and refunds&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Where a cancellation, charge-back or refund applies regarding a
          purchase a consumer made regarding your content or services made
          available through Komi in accordance with applicable law and/or the
          User Terms, the relevant sum will be deducted from sums we would
          otherwise owe you under these Expert Terms. In the event neither of
          the above are possible, you shall remain directly liable to pay to us
          the shortfall arising from cancellations, charge-backs or refunds that
          arise for any cause.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Notwithstanding anything to the contrary, we shall have the right to
          (and you irrevocably authorise us on your behalf to) in our discretion
          cancel purchases, process refunds, rescind or reject transactions or
          to otherwise exercise our discretion over accepting or rejecting
          refunds.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "8pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Off-platform actions
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You agree that you will obtain our prior written (including email)
          consent (not to be unreasonably withheld or delayed by us) before
          taking any materials, assets or content from Komi, or which is
          released, made available or communicated in Komi (including content
          you submit into Komi as part of your content and services offering to
          consumers), outside of Komi (such as reposting the same of your
          (paid-for by consumers) content on third party services).
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In regards to the licences Komi grants you under these Terms, such
          licences apply only to use within Komi and use outside of Komi is
          prohibited unless expressly set out to the contrary.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your right to use Komi&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We grant you a limited, non-transferable, revocable, non-exclusive
          licence to use: (i) Komi for your use as a Komi expert (including our
          website and the apps we make available), including for the purposes of
          fulfilling your obligations and exercising your rights hereunder; and
          (ii) ‘Komi’ branding and intellectual property rights we make
          available in our discretion, including our logo and/or trade marks
          (subject in all cases to the usage restrictions under these Expert
          Terms). The foregoing licence includes the rights (through Komi in
          each case) for you to transact for, fulfil, market and promote the
          availability of your content and services in Komi for consumers to
          purchase and access.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You are granted a limited, non-transferable, revocable, non-exclusive
          licence to use Komi consumers’ user-generated content (such as
          consumers’ messages and reviews) we make available to you through
          Komi, for the purposes of fulfilling your obligations under these
          Expert Terms.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          From time to time we may provide you with certain promotional
          materials and assets which we identify as being for use by you for the
          promotion of the availability to consumers of your Komi Expert account
          (and the content, subscriptions and/or services you make available in
          Komi) through your personal social media accounts, websites and blogs.
          In regards to such materials and assets, we grant you a limited,
          revocable, non-transferable, non-exclusive licence to use them for
          those purposes only. We may require that you cease using them and/or
          remove them from your social media accounts, websites and/or blogs at
          our discretion on written notice to you. &nbsp;&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Our rights to use your content
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          When you create a Komi Expert account, you are appointing us as your
          vendor. You retain ownership of the content you submit into Komi.
          However, we do need you to grant us a licence, as follows, so that we
          are able to use your works, content and intellectual property rights
          in accordance with these Expert Terms.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You grant us an exclusive, irrevocable, worldwide, royalty-free right
          and licence to use content, assets and materials (of any kind or
          nature including without limitation copy, audio, audio-visual
          materials) that you submit into Komi, such as through your posts,
          messages and through your offerings to consumers of your content,
          subscriptions and services (and your fulfilment and promotion of
          them), but only for the following permitted purposes:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To provide the Komi platforms, content and services and fulfil
              relevant transactions and in order for us to exercise and fulfil
              our rights, duties and obligations under these Expert Terms and
              under the User Terms; and
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To promote and market Komi, including without limitation the
              availability of your content and/or services through Komi.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The foregoing licence that you grant to us survives termination or
          expiry of these Expert Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Our warranties to you and our liability&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We warrant to you that:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We are entitled to enter into these Expert Terms and fulfil our
              obligations under them;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We have and will have all necessary rights and licences to be able
              to grant the rights and licences we grant to you under these
              Expert Terms; and
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Our services to you, in relation to Komi, will be provided with
              reasonable care and skill.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Aside from the above, we provide Komi and its services to you&nbsp;
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          “as is
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”; meaning that we make no other representations, warranties or
          guarantees to you and that, to the maximum extent not prohibited by
          applicable law, we exclude any and all implied warranties,
          representations or guarantees. We do not limit our liability for
          personal injury or death caused by our negligence, or for our fraud or
          our fraudulent representation.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Except where otherwise expressly provided by these Expert Terms, and
          to the maximum extent permitted by law:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We do not accept any liability for: (i) any unforeseeable,
              indirect or consequential losses or costs (including without
              limitation loss of opportunities, potential savings, profits,
              revenues, customers, sponsors, damage to reputation or losses of
              data); or (ii) personal injury or death not caused by our
              negligence; and
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To the maximum extent permitted by law, our liability to you shall
              not exceed the greater of the following sums: (i) £500; or (ii)
              the total amount you paid to us in Komi Service Fees (for the
              avoidance of doubt, less first-party platform costs, distribution
              fees and less any applicable taxes) in the preceding 12 months
              prior to the relevant date of liability first incurring.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your warranties to us
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You warrant, represent and undertake to us that:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You are entitled to, and have all necessary rights to, enter into
              these Expert Terms and perform your obligations and grant all
              necessary rights that you grant hereunder.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You will abide at all times with these Expert Terms and also with
              the User Terms (to the extent the User Terms are relevant to your
              performance, duties and obligations).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You will abide at all times by all applicable laws (under the laws
              of England and Wales and also of the territory in which you are
              located).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your content, subscriptions and services made available through
              Komi to consumers will be for recreational and leisure services
              only and you will not hold out your content, subscriptions or
              services as being for other purposes, including without limitation
              as being medical advice, financial advice or advice on which any
              consumer should place reliance.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your content, subscriptions and services made available through
              Komi to consumers will be appropriate for the Komi platform,
              lawful and original works that you have created.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your content, subscriptions and services made available through
              Komi to consumers will be of the category that you disclosed to us
              in your Komi Expert account creation process (as may be amended
              with our written consent).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your content, subscriptions and services made available through
              Komi to consumers, and any assets, content and materials you
              provide to us, will not infringe the rights of any third party
              (including without limitation their intellectual property rights
              or privacy rights).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You will exercise all reasonable care and skill in providing your
              content, subscriptions and services through Komi to consumers.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You have all necessary skills, experience and qualifications
              (including without limitation those you set out in any content or
              descriptions you provide in Komi to consumers) for the purposes of
              the content, subscriptions and services you provide to consumers.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Without prejudice to the above, you will provide your content,
              subscriptions and services in such a manner so that all
              commitments provided to consumers in the User Terms are met.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Indemnity
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You indemnify us, and our directors, staff, suppliers, licensors and
          group companies, from any and all liabilities, penalties, losses and
          costs (including without limitation legal and professional expenses)
          that arise from any of the following:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your breach of these Expert Terms (including without limitation
              the warranties you grant to us in these Expert Terms and the
              commitments you make to us regarding our confidential and/or
              proprietary information);
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your use of Komi in any illegal (according to laws of England and
              Wales as well as the territory in which you are located) way, such
              as if you use Komi to access, share or post illegal content,
              subscriptions and services or promote them; and
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Third party claims against us (such as for, without limitation,
              infringement of intellectual property or privacy rights), arising
              from your use of Komi such as, without limitation, content you
              make available through Komi. You also indemnify us from third
              party claims against us arising from your failure to fulfil your
              obligations to Komi consumer users.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In such instances, we shall have the right to control the legal
          defence of any third party claim or regulatory investigation and you
          shall provide us with all assistance we request, acting reasonably.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Non-solicitation
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You shall not, without our prior written consent, at any date within
          the period commencing from your creation of an Expert Account up to
          the date which is six (6) months from the termination or expiry of
          these Expert Terms for any cause, solicit or entice away (or attempt
          to) from Komi to any alternative platform or service that a reasonable
          business person would regard as a competitor to Komi, either of:
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "lower-alpha",
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            paddingLeft: "18pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Any consumer users of Komi; or
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "lower-alpha",
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            paddingLeft: "18pt",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Any Komi Expert account holders.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Failure to comply with the foregoing can, without limitation, result
          in us suspending or terminating your Komi Expert account and,
          additionally, we would reserve our right to seek damages or other
          remedies.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Data protection and data ownership
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We process your personal data, and that of Komi consumer users, in
          accordance with our Komi Privacy and Cookie Policy{" "}
        </span>
        <a
          href="https://komiapp.co/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://komiapp.co/privacy-policy
        </a>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          .&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We do not share the personal data of Komi consumer users with Komi
          experts. However, we may from time to time provide you with anonymised
          usage reports at our discretion.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          For the purposes of these Expert Terms and the exercise of duties and
          obligations hereunder by you and us, we both acknowledge and agree
          that neither of us acts as a data processor of personal data on behalf
          of the other (as ‘data processor’ and ‘personal data’ are defined in
          the EU General Data Protection Regulation 2016/679). Both parties
          instead act as independent controllers only, at all times for the
          purposes of these Expert Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In regards to any intellectual property rights in Komi consumer user
          data and databases (including without limitation copyrights, database
          rights and any other intellectual property rights of any kind or
          nature or equivalent rights in any territory), such rights vest solely
          in us and are expressly reserved by us. On termination of these Expert
          Terms for any cause, you will not be entitled to receive a copy of or
          access to any Komi user data or databases (regardless of whether such
          data or databases include personal data).&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Confidentiality
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In the course of our working relationship with you, we may disclose to
          you (but without obligation to do so) certain information which is of
          a proprietary and/or confidential nature, such as regarding our
          business affairs, pre-release marketing materials, marketing plans,
          suppliers, business partners, viewing figures, engagement figures or
          other of our confidential and/or proprietary information. All such
          information, whether marked confidential or not and regardless of the
          medium through which it is provided, must be held in strictest
          confidentiality and not disclosed except: (i) with our prior written
          approval; (ii) to your professional advisors or staff, to the extent
          strictly necessary for the purposes of fulfilling your obligations
          under these Expert Terms or exercising your rights under these Expert
          Terms (and provided they are made subject to obligations of
          confidentiality at least as stringent as under these Expert Terms); or
          (iii) where required by a court or regulator of competent authority
          (but providing us with advance written notice).
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your rights to end the agreement
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You can close your Komi Expert account with us at any time, either by
          using settings within Komi or by contacting us. However, we may need a
          reasonable amount of time in order to process your closure request and
          update Komi.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Being fair to Komi’s consumer users is important to us. If you choose
          to close your Komi Expert account, there may still be consumers who
          have purchased previous content from you and who still want to access
          that content. Closure of your account does not mean we will take down
          or stop making available the relevant historical content.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Our rights to end the agreement
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may temporarily discontinue making available Komi or any part of it
          for any reason, including without limitation upgrades, maintenance or
          service administration reasons at our discretion. Updates we make to
          Komi do not terminate these Expert Terms. We will try to limit the
          downtime of the availability of Komi.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We reserve our right to take any action we deem reasonably necessary
          against you if you breach these Expert Terms, and that may include,
          without limitation, terminating these Expert Terms, suspending your
          Komi Expert account, limiting account access, deleting your Komi
          Expert account or your posts, materials, content, services or other
          assets within Komi. However, if what you have done can be put right,
          we will give you a reasonable opportunity to do so.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We, additionally, have the right to terminate these Expert Terms (in
          whole or in part) for convenience on notice to you (including without
          limitation in writing or by email), without penalty.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Termination effects
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          If these Expert Terms are terminated for any reason (such as if you
          delete your account, or if we terminate the Expert Terms because of
          your breach of them or we terminate at-will):
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The rights and licences granted from us to you shall immediately
              terminate, including without limitation your licence to use Komi
              or any part of it or our content therein;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The rights and licences you grant to us under these Expert Terms
              will survive and continue unaffected; and
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              All other rights and liabilities accrued up to the termination
              date shall be unaffected (including our obligation to pay you any
              outstanding, undisputed fees we owe to you regarding purchases by
              consumers of your services or content pursuant to these Expert
              Terms).
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Reserving our rights
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We, and our licensors, are the owners of all rights in Komi including
          without limitation intellectual property rights. Except for licences
          expressly granted to you under these Expert Terms, no other rights or
          licences are granted and all of our and our licensors’ other rights
          are reserved.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your equipment, production and music licensing
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You are responsible for your own devices, equipment, production of
          assets and content, overheads, development costs, hardware costs,
          internet connection costs and other costs regarding any content,
          subscriptions and services you provide as part of being able to fulfil
          your obligations and exercise your rights hereunder.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In no event shall we be responsible for paying royalties to you or any
          third party or collection society regarding third party intellectual
          property rights in any content you choose to input into Komi,
          including without limitation music you feature in your content.
          Clearance and licensing arrangements for music utilised in any and all
          content, assets and materials you provide to us or make available
          through Komi is solely your responsibility. You indemnify us from all
          costs, liabilities and expenses we incur from your breach of the
          foregoing.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi subscriptions
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may make available functionality in Komi that permits you to
          operate subscriptions with your audience, for example for them to
          subscribe to access content and/or services from your Komi Expert
          account that you choose to make available to them in exchange for
          their paid-for, recurring subscription. You acknowledge and agree
          that:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We shall have the right to amend, update or remove subscription
              functionality from Komi in our discretion, or to amend
              subscription pricing;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The subscription functionality is provided by us in accordance
              with the relevant terms in the User Terms and you acknowledge
              those terms and authorise us to manage the subscriptions relevant
              to your Komi Expert account in accordance with them;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              For the avoidance of doubt and without prejudice to their
              application in other areas, these Expert Terms apply to the
              subscriptions offered to Komi consumer users by you (including
              regarding all relevant warranties, undertakings, representations,
              obligations and restrictions in these Terms which are relevant to
              your offering of the subscriptions and/or the content or services
              offered by you as part of the subscriptions). For example and
              without limitation, you are responsible for and must ensure that
              what is made available to consumers by you in exchange for
              subscriptions is accurately described to the consumers and not
              misleading;&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We reserve the right in our discretion to limit the Komi Expert
              accounts who are permitted to offer subscriptions to consumers
              and/or to withdraw that functionality from your account; and
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              If we make a refund of a subscription purchase to a consumer in
              our discretion, without prejudice to our other rights, we may
              recoup the refund from you.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your interactions with Komi consumer users
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In all of your interactions with Komi consumer users you must comply
          with these Expert Terms and any relevant provisions of the User Terms.
          Similarly, we include protections and restrictions in the User Terms
          regarding how Komi consumer users are required to interact with you.
          However, to the maximum extent permissible by law, we are not
          responsible for Komi consumer user-generated content (and we do not
          moderate content from Komi consumer users), including without
          limitation messages, images, audio, videos, video streams or similar
          content Komi consumer users may provide to you. If you believe a Komi
          consumer user has breached the User Terms, you must notify us
          immediately.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Application store terms
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          For our Komi mobile / tablet applications (including iOS and, where we
          make it available, Android or other platform versions), the
          application stores (such as Apple App Store and Google Play) (each a “
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          store
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          ”) require us to let you know that:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To use Komi applications, you acknowledge you have agreed to the
              relevant store’s terms of use and service.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              These Expert Terms are between you and us and not with any store
              provider, including without limitation Apple Inc. (“
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Apple
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ”) nor its subsidiaries or affiliates.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We are responsible for Komi and its content (except for the
              content you provide to us) in accordance with these Expert Terms -
              not Apple. We are the party responsible for providing application
              support and maintenance. Apple has no obligation to provide
              maintenance or support services for the application.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Licences for your use of Komi and content within Komi (except for
              the content you provide to us) granted under these Expert Terms
              are for use via the devices you own or control (e.g. your iOS
              device), and as permitted by the store&apos;s usage rules.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We are solely responsible for providing support and maintenance
              for the app. You acknowledge that Apple has no obligation
              whatsoever to furnish any maintenance and support services with
              respect to the Komi apps.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We (not Apple) are responsible for addressing claims you may have
              relating to the Komi iOS application and its possession or use.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In the event of a third party claim that the Komi iOS application
              itself infringes a third party’s intellectual property rights, we
              are the party responsible for the review, investigation, defence
              and (where appropriate) settlement or discharge of such matter –
              not Apple.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To be able to use Komi and create your Komi Expert account, you
              confirm it is legal for you to access Komi in the territory you
              are in and that you are not located in a country which is subject
              to US Government embargoes or which is designated by the US
              Government as a “terrorist-supporting country”. You also confirm
              you are not listed on any US Government prohibited or restricted
              parties lists.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You agree that Apple is entitled to enforce these Expert Terms as
              a third party beneficiary, including against you if you were to
              breach them.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To the extent of a conflict between store terms and our own, the
              store terms prevail.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Komi Expert Addendum
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We appreciate that what you do may be unique and we may need to agree
          some further, more tailored terms between us. For those purposes, we
          may agree a separate Komi Expert Addendum with you in writing
          (including email), to set out any special terms that are needed. To
          the extent of any conflict or ambiguity between these Expert Terms and
          the Komi Expert Addendum, the Komi Expert Addendum prevails.
          &nbsp;&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: 2,
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt",
        }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Verdana",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          General
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We may make changes to these Expert Terms from time to time in our
              discretion. When we do so, we will post them here. Please check
              back here from time to time for updates. Each time you use Komi,
              or access or provide any content or services through Komi, you are
              agreeing to the latest version of these Expert Terms. Until any
              updated Expert Terms are agreed to by you in accordance with the
              foregoing, the existing set shall apply.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              These Expert Terms are the entire agreement between you and us in
              respect of your use of Komi. Any representations not set out in
              these Expert Terms are superseded and replaced by these Expert
              Terms.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Even if we delay in enforcing our rights, including under these
              Expert Terms, we still have the right to do so later. Failure to
              enforce any particular rights or obligations under these Expert
              Terms does not prejudice our ability to enforce any other rights
              or obligations under these Expert Terms.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Our remedies set out in these Expert Terms, including without
              limitation regarding suspending or terminating your Komi Expert
              account, are without limitation to any rights and remedies
              available to us under these Expert Terms or otherwise at law.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We have the right to subcontract, sublicense, assign, novate or
              otherwise transfer our rights and obligations (in whole or part)
              under these Expert Terms. For example, without limitation, we may
              do so if we are acquired or restructured. If this happens, we will
              notify you.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You are not permitted to subcontract, sublicense, assign or
              otherwise transfer your rights or obligations under these Expert
              Terms to a third party, unless we agree in writing.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You agree that the character of these Expert Terms is unique and
              of a special value to Komi and that, if you breach the Expert
              Terms, damages alone may not be a sufficient remedy and that Komi
              shall additionally have the rights (without bond or security) to
              equitable remedies including without limitation injunctive relief
              and other equitable remedies as may be available at law.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Without prejudice to the express provisions of these Expert Terms,
              these Expert Terms do not give rise to rights under the Contracts
              (Rights of Third Parties) Act 1999 to enforce these terms.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In the event these Expert Terms or a part of them are held by any
              court or tribunal of competent authority to be unenforceable,
              these Expert Terms (or relevant part of them) will be interpreted
              in such a way that the rest of the terms are unaffected and such
              unenforceable terms shall be deemed replaced by terms which are
              amended to the minimum extent possible so as to be enforceable and
              reflect the parties intentions with full force and effect.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              These Expert Terms will be governed and construed by the laws of
              England and Wales.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: 2,
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Verdana",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              If you have a dispute about these Expert Terms or arising in
              connection with them, please contact us first to discuss it. The
              courts of England and Wales shall have exclusive jurisdiction to
              settle disputes or claims arising from or in connection with these
              Expert Terms.
            </span>
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ExpertTerms;
