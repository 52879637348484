import { signupTalentTypes } from "redux/Talent/actions";
import {
  deleteLocalizationActions,
  updatePasswordTypes,
  updateUserProfileTypes,
} from "redux/User/actions";
import { RootState } from "./../rootReducer";
import {
  checkUserExistedTypes,
  getUserProfileTypes,
  reorderTalentProfileModuleTypes,
  sendEmailOTPTypes,
  sendOTPTypes,
  updateCollaboratorProfileTypes,
  updateTalentProfileModuleTypes,
  updateTalentProfileTypes,
} from "./actions";
import { TalentImageTypes } from "./types";
import { ProductKey, Subscription } from "types/sales";
import { username } from "utils/upcoming";

export const selectUserData = (rootState: RootState) =>
  rootState.userState?.user;

export const selectUserId = (rootState: RootState) =>
  rootState.userState?.user?.id;

export const selectUserLoading = (rootState: RootState) =>
  rootState.userState?.loading &&
  rootState.userState.type !== updatePasswordTypes.REQUEST;

export const selectLoadingChangePassword = (rootState: RootState) =>
  rootState.userState?.loading &&
  rootState.userState.type === updatePasswordTypes.REQUEST;

export const selectUserError = (rootState: RootState) =>
  rootState.userState?.error;

export const selectUserErrorConnectCalendar = (rootState: RootState) =>
  rootState.userState?.errorConnectCalendar;

export const selectGetUserProfileError = (rootState: RootState) =>
  rootState.userState?.type === getUserProfileTypes.FAILURE &&
  rootState.userState?.error;

export const selectSignUpTalentError = (rootState: RootState) =>
  rootState.userState?.type === signupTalentTypes.FAILURE &&
  rootState.userState?.error;

export const selectUpdateTalentProfileError = (rootState: RootState) =>
  rootState.userState?.type === updateTalentProfileTypes.FAILURE &&
  rootState.userState?.error;

export const selectUpdateTalentProfileSuccess = (rootState: RootState) =>
  rootState.userState?.type === updateTalentProfileTypes.SUCCESS
    ? rootState.userState?.user
    : undefined;

export const selectUpdateProfileError = (rootState: RootState) =>
  rootState.userState?.type === updateUserProfileTypes.FAILURE &&
  rootState.userState?.error;

export const selectUpdateProfileSuccess = (rootState: RootState) =>
  rootState.userState?.type === updateUserProfileTypes.SUCCESS
    ? rootState.userState?.user
    : undefined;

export const selectUpdateCollaboratorProfileError = (rootState: RootState) =>
  rootState.userState?.type === updateCollaboratorProfileTypes.FAILURE &&
  rootState.userState?.error;

export const selectUpdateCollaboratorProfileSuccess = (rootState: RootState) =>
  rootState.userState?.type === updateCollaboratorProfileTypes.SUCCESS
    ? rootState.userState?.collaborator
    : undefined;

export const selectUpdateTalentProfileModuleSuccess = (rootState: RootState) =>
  rootState.userState?.type === updateTalentProfileModuleTypes.SUCCESS &&
  rootState.userState?.user;

export const selectUpdateTalentProfileModuleError = (rootState: RootState) =>
  rootState.userState?.type === updateTalentProfileModuleTypes.FAILURE &&
  rootState.userState?.error;

export const selectReOrderTalentProfileModuleSuccess = (rootState: RootState) =>
  rootState.userState?.type === reorderTalentProfileModuleTypes.SUCCESS &&
  rootState.userState?.user;

export const selectReOrderTalentProfileModuleError = (rootState: RootState) =>
  rootState.userState?.type === reorderTalentProfileModuleTypes.FAILURE &&
  rootState.userState?.error;

export const selectProfileGalleryItem =
  (itemIndex: any) => (rootState: RootState) =>
    rootState?.userState?.gallery?.[itemIndex];

export const selectProfileGallery = (rootState: RootState) => {
  const covers = rootState?.userState?.gallery;

  if (typeof covers === "object")
    return Object.keys(covers).map((key) => covers[key]);
  else return covers;
};

export const selectProfileGalleryObject = (rootState: RootState) => {
  return rootState?.userState?.gallery;
};

export const selectTalentTempImages = (rootState: RootState) =>
  rootState?.userState?.tempTalentImages;

export const selectTalentProfile = (rootState: RootState) =>
  rootState?.userState?.user?.talentProfile;

export const selectTalentProfileOrigin = (rootState: RootState) =>
  rootState?.userState?.user?.talentProfileOrigin;

export const selectUpdatePassword = (rootState: RootState) =>
  rootState?.userState.password;

export const selectUpdatePasswordError = (rootState: RootState) =>
  rootState?.userState.error;

export const selectTalentAnalytic = (rootState: RootState) =>
  rootState.userState.analytic;

export const selectTalentAnalyticMonthly = (rootState: RootState) =>
  rootState.userState.analyticMonthly;

export const selectTalentCredential = (rootState: RootState) =>
  rootState.userState.talentCredential;

export const selectConsumerCredential = (rootState: RootState) =>
  rootState.userState.consumerCredential;

export const selectTalentProfileAvatar = (rootState: RootState) =>
  rootState?.userState?.tempTalentImages?.[TalentImageTypes.AVATAR];

export const selectTalentProfileCover = (rootState: RootState) =>
  rootState?.userState?.tempTalentImages?.[TalentImageTypes.COVERS];

export const selectAvalabilityCalendarvai = (rootState: RootState) =>
  rootState?.userState?.avalabilityCalendar;

export const selectPhoneOTP = (rootState: RootState) =>
  rootState.userState?.phoneOTP;

export const selectSendPhoneOTPError = (rootState: RootState) =>
  rootState.userState?.type === sendOTPTypes.FAILURE &&
  rootState.userState?.error;

export const selectSendPhoneOTPSuccess = (rootState: RootState) =>
  rootState.userState?.type === sendOTPTypes.SUCCESS &&
  rootState.userState?.phoneOTP;

export const selectEmailOTP = (rootState: RootState) =>
  rootState.userState?.emailOTP;

export const selectSendEmailOTPError = (rootState: RootState) =>
  rootState.userState?.type === sendEmailOTPTypes.FAILURE &&
  rootState.userState?.error;

export const selectSendEmailOTPSuccess = (rootState: RootState) =>
  rootState.userState?.type === sendEmailOTPTypes.SUCCESS &&
  rootState.userState?.emailOTP;

export const selectUserExisted = (rootState: RootState) =>
  rootState.userState?.type === checkUserExistedTypes.SUCCESS &&
  rootState.userState?.isUserExisted;

export const selectExchangeRates = (rootState: RootState) =>
  rootState.userState?.exchangeRates;

export const selectExchangeRatesUSD = (rootState: RootState) =>
  rootState.userState?.exchangeRatesUSD;

export const selectIsCheckLocalCurrency = (rootState: RootState) =>
  rootState.userState?.isCheckLocalCurrency;

export const selectTalentProfileModules = (rootState: RootState) =>
  rootState.userState?.talentProfileModules;

export const selectAnalyticsProfileModules = (rootState: RootState) =>
  rootState.userState?.analyticsProfileModules;

export const selectTalentProfileModulesOrigin = (rootState: RootState) =>
  rootState.userState?.talentProfileModulesOrigin;

export const selectPreviousModulesVersion = (rootState: RootState) =>
  rootState.userState?.previousModulesVersion;

export const selectPreviousProfileVersion = (rootState: RootState) =>
  rootState.userState?.user?.previousVersionTalentProfile;

export const selectTalentCollaborator = (rootState: RootState) =>
  rootState.userState?.talentCollaborators;

export const selectCollaborator = (rootState: RootState) =>
  rootState.userState?.collaborator;
export const selectTalentRevert = (rootState: RootState) =>
  rootState.userState?.talentRevert;

export const selectCollaboratorList = (rootState: RootState) =>
  rootState.userState?.collaborator?.collaborationTalentProfiles;

export const selectIsPublished = (rootState: RootState) =>
  rootState.userState?.isPublished;

export const selectIsCollaborator = (rootState: RootState) =>
  rootState.userState?.isCollaborator;

export const selectLoadingPublish = (rootState: RootState) =>
  rootState.userState?.loadingPublish;

export const selectIsBlockingEdit = (rootState: RootState) =>
  rootState.userState?.isBlockingEdit;

export const selectLoadingAutoSave = (rootState: RootState) =>
  rootState.userState?.loadingAutoSave;

export const selectIsAccountSettingCollaborator = (rootState: RootState) =>
  !!rootState.userState?.user?.collaborationTalentProfiles?.find(
    (p) => p.talentProfile.user?.username === username
  );
export const selectIsAutoSaveSuccess = (rootState: RootState) =>
  rootState.userState?.isAutoSaved;

export const selectUserType = (rootState: RootState) =>
  rootState.userState?.type;

export const selectIsAdminLogin = (rootState: RootState) =>
  rootState.userState?.isAdminLogin;

export const selectError = (rootState: RootState) => rootState.userState?.error;

export const selectLocalizations = (rootState: RootState) =>
  rootState.userState?.user?.talentProfile?.localizations;

export const selectLocalizationActive = (rootState: RootState) =>
  rootState.userState?.user?.talentProfile?.localizationActive;

export const selectLocalizationUnpublishIds = (rootState: RootState) =>
  rootState.userState?.localizationUnpublishIds;

export const selectModuleLoading = (rootState: RootState) =>
  rootState.userState?.moduleLoading;

export const selectLocalizationSelected = (rootState: RootState) =>
  rootState.userState?.localizationSelected;
export const selectLoadingDeleteLocalization = (rootState: RootState) =>
  rootState.userState?.loading &&
  rootState.userState?.type === deleteLocalizationActions.REQUEST;

export const selectProfileBuilderSubscription = (
  rootState: RootState
): Subscription | null => {
  const user = rootState.userState.user;
  if (!user) {
    return null;
  }

  if (!user || !user.talentProfile || !user.talentProfile.subscriptions) {
    return null;
  }

  return user.talentProfile.subscriptions[ProductKey.PROFILE_BUILDER] ?? null;
};

export const selectPaymentMethod = (rootState: RootState) =>
  rootState.userState?.user?.paymentMethod;
