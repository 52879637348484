export const TYPE_MESSAGE_CREATE_EXPERIENCE = {
  NOTIFICATION: "NOTIFICATION",
  DONE: "DONE",
  EXIT: "EXIT",
};

export class IframeMessage {
  static unbindEvent(
    element: Window,
    eventName: string,
    eventHandler: (event: any) => void
  ) {
    if (!!element.addEventListener) {
      element.removeEventListener(eventName, eventHandler, false);
    } else {
      (<any>element).detachEvent("on" + eventName, eventHandler);
    }
  }

  static bindEvent(
    element: Window,
    eventName: string,
    eventHandler: (event: any) => void
  ) {
    if (!!element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    } else {
      (<any>element).attachEvent("on" + eventName, eventHandler);
    }
  }

  static inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static postMessage(msg: { type: string; data?: any }) {
    try {
      window.parent.postMessage(msg, "*");
    } catch (error) {}
  }

  static notification(message: string) {
    IframeMessage.postMessage({
      type: TYPE_MESSAGE_CREATE_EXPERIENCE.NOTIFICATION,
      data: message,
    });
  }

  static done() {
    IframeMessage.postMessage({ type: TYPE_MESSAGE_CREATE_EXPERIENCE.DONE });
  }

  static exit() {
    IframeMessage.postMessage({ type: TYPE_MESSAGE_CREATE_EXPERIENCE.EXIT });
  }
}
