import {
  format,
  isAfter,
  isBefore,
  differenceInMinutes,
  add,
  getTime,
  getMonth,
  getYear,
} from "date-fns";
import {
  SSXSubscriptionStage,
  SUBSCRIPTION_TRIAL_LENGTH_DAYS,
} from "constants/trial";
import {
  PaymentCard,
  OverviewStatus,
  Product,
  Subscription,
  PaymentMethodAction,
  SessionStorageKeys,
  Language,
  FLOW_UPDATE,
  SubscriptionStatus,
} from "types/sales";
import { salesService } from "services";

export interface SubscriptionDetails {
  stage: SSXSubscriptionStage;
  trialRemainingDaysFormatted?: string;
  subscriptionTrialEndAtFormatted?: string;
}

export const getPriceForSubscription = (
  subscription?: Subscription,
  product?: Product
) => {
  if (!subscription || !product) return;

  const [price] = product.prices[subscription.interval];

  if (!price) {
    return;
  }

  return price;
};

export const getSubscriptionOverviewData = (
  subscription: Subscription | null | undefined,
  now: Date = new Date()
): {
  description?: string;
  status?: OverviewStatus;
  trialRemainingDays?: number;
} => {
  if (!subscription) {
    return {};
  }

  if (subscription.cancel_date) {
    if (
      subscription.trial_end &&
      isAfter(new Date(subscription.trial_end), now)
    ) {
      const remainingDays = getRemainingTrialDays(
        new Date(subscription.trial_end),
        now
      );

      return {
        description: `Your free trial expires in ${remainingDays} days on ${formatStripeDate(
          subscription.trial_end
        )}`,
        status: OverviewStatus.FREE_TRIAL,
        trialRemainingDays: remainingDays,
      };
    }

    if (
      subscription.current_period_end &&
      isAfter(new Date(subscription.current_period_end), now)
    ) {
      return {
        description: `Your subscription was canceled on ${formatStripeDate(
          subscription.cancel_date
        )}. Your profile URL will remain active until it expires on ${formatStripeDate(
          subscription.current_period_end
        )}.`,
        status: OverviewStatus.CANCELLED,
      };
    }

    return {
      description: `Your subscription was canceled on ${formatStripeDate(
        subscription.cancel_date
      )}`,
      status: OverviewStatus.CANCELLED,
    };
  }

  if (
    subscription.current_period_end &&
    isAfter(new Date(subscription.current_period_end), now)
  ) {
    return {
      description: `Your next billing date is ${formatStripeDate(
        subscription.current_period_end
      )}.`,
      status: OverviewStatus.ACTIVE,
    };
  }

  if (
    subscription.trial_end &&
    isAfter(new Date(subscription.trial_end), now)
  ) {
    const remainingDays = getRemainingTrialDays(
      new Date(subscription.trial_end),
      now
    );

    return {
      description: `Your free trial expires in ${remainingDays} ${remainingDays === 1 ? "day" : "days"
        } on ${formatStripeDate(subscription.trial_end)}`,
      status: OverviewStatus.FREE_TRIAL,
      trialRemainingDays: remainingDays,
    };
  }

  // in the context of profile_builder, trial_end will always be set
  if (
    subscription.trial_end &&
    isBefore(new Date(subscription.trial_end), now)
  ) {
    return {
      description: `Your free trial expired on ${formatStripeDate(
        subscription.trial_end
      )}`,
      status: OverviewStatus.FREE_TRIAL_EXPIRED,
    };
  }

  return {};
};

export const cardBrandNameMap: { [x: string]: string } = {
  amex: "American Express",
  diners: "Diners Club International",
  discover: "Discover Card",
  jcb: "JCB Co., Ltd.",
  mastercard: "Mastercard",
  unionpay: "UnionPay",
  visa: "Visa",
  unknown: "Unknown",
};

export const isPaymentMethodExpired = (
  paymentCard: PaymentCard,
  now: Date = new Date()
): boolean => {
  return isBefore(
    new Date(Number(paymentCard.exp_year), Number(paymentCard.exp_month), 0), // month is zero-index however card expiry dates include the month of expiry
    new Date(getYear(now), getMonth(now)) // year/month/1
  );
};

export const formatStripeDate = (dateMs: number): string =>
  format(new Date(dateMs), "MMM dd, yyyy");

export const getRemainingTrialDays = (endDate: Date, now: Date): number => {
  const clamp = (num: number, min: number, max: number): number =>
    Math.min(Math.max(num, min), max);

  const remainingMinutes = differenceInMinutes(endDate, now);
  const remainingDays = remainingMinutes / (24 * 60);

  return clamp(Math.ceil(remainingDays), 0, SUBSCRIPTION_TRIAL_LENGTH_DAYS);
};

export const subscriptionInTrial = (
  subscription?: Subscription,
  now: Date = new Date()
): boolean => {
  const { stage } = subscriptionTrialForUser(subscription, now);

  return stage === SSXSubscriptionStage.Early || stage === SSXSubscriptionStage.Late;
}

export const subscriptionTrialForUser = (
  subscription?: Subscription,
  now: Date = new Date()
): SubscriptionDetails => {
  if (!subscription) {
    return {
      stage: SSXSubscriptionStage.None,
    };
  }

  if (!subscription.trial_end) {
    return {
      stage: SSXSubscriptionStage.None,
    };
  }

  // subscription has been taken out, do not display trial banner
  if (subscription.current_period_end && subscription.status !== SubscriptionStatus.CANCELED) {
    return {
      stage: SSXSubscriptionStage.None,
    };
  }
  
  if (isBefore(new Date(subscription.trial_end), now)) {
    return {
      stage: SSXSubscriptionStage.Inactive,
    };
  }

  const trialRemainingDays = getRemainingTrialDays(
    new Date(subscription.trial_end),
    now
  );

  return {
    stage: ((trialRemainingDays: number): SSXSubscriptionStage => {
      if (trialRemainingDays > 7) {
        return SSXSubscriptionStage.Early;
      }

      if (trialRemainingDays > 0) {
        return SSXSubscriptionStage.Late;
      }

      return SSXSubscriptionStage.Expired;
    })(trialRemainingDays),
    trialRemainingDaysFormatted: `in ${trialRemainingDays} ${trialRemainingDays === 1 ? "day" : "days"
      }`,
    subscriptionTrialEndAtFormatted: formatStripeDate(subscription.trial_end),
  };
};

export enum NextBillingDateOptions {
  REVIEW = "Review",
}

export const getNextBillingDate = (
  subscription?: Subscription,
  now: Date = new Date(),
  options?: NextBillingDateOptions
): string | null => {
  if (!subscription) {
    return formatStripeDate(
      getTime(add(now, { days: SUBSCRIPTION_TRIAL_LENGTH_DAYS }))
    );
  }

  if (subscription.cancel_date && options !== NextBillingDateOptions.REVIEW) {
    return null;
  }

  if (subscription.current_period_end) {
    return formatStripeDate(subscription.current_period_end);
  }

  // in the context of profile_builder, trial_end will always be set
  if (subscription.trial_end) {
    return formatStripeDate(subscription.trial_end);
  }

  return null;
};

export const getSubscriptionFlowLanguage = (): Language => {
  if (sessionStorage.getItem(SessionStorageKeys.FLOW) === FLOW_UPDATE) {
    return {
      Global: {
        Title: "Update Your Komi Subscription",
      },
      SubscriptionSelection: {
        CTAButtonLabel: "Confirm Selection"
      },
      SubscriptionConfirmation: {
        Title: "Your Subscription Has Been Updated",
      },
    };
  }

  return {
    Global: {
      Title: "Set Up Your Komi Subscription",
    },
    SubscriptionSelection: {
      CTAButtonLabel: "Review Subscription"
    },
    SubscriptionConfirmation: {
      Title: "Thank you for subscribing!",
    },
  };
}

export const getSubscriptionConfirmationMessage = (
  subscription: Subscription | null | undefined,
  now: Date = new Date()
): string | null => {
  if (!subscription) {
    return null;
  }

  const nextBillingDate = getNextBillingDate(subscription, now);

  if (!nextBillingDate) {
    return null;
  }

  if (subscription.cancel_date) {
    if (
      subscription.trial_end &&
      isAfter(new Date(subscription.trial_end), now)
    ) {
      return `You have successfully activated your Komi subscription. A receipt will be sent to your email. You will not be charged until your free trial expires on ${nextBillingDate}. If you change your mind, you can cancel your subscription any time in Settings.`;
    }

    if (
      subscription.current_period_end &&
      isAfter(new Date(subscription.current_period_end), now)
    ) {
      return `You have successfully activated your Komi subscription. A receipt will be sent to your email. You will not be charged until your existing subscription ends on ${nextBillingDate}. If you change your mind, you can cancel your subscription any time in Settings.`;
    }

    return `You have successfully activated your Komi subscription. A receipt will be sent to your email. If you change your mind, you can cancel your subscription any time in Settings.`;
  }

  if (
    subscription.current_period_end &&
    isAfter(new Date(subscription.current_period_end), now)
  ) {
    // still in trial
    if (
      subscription.trial_end &&
      isAfter(new Date(subscription.trial_end), now)
    ) {
      return `You have successfully updated your Komi subscription. A receipt will be sent to your email. You will not be charged until your free trial ends on ${nextBillingDate}. If you change your mind, you can cancel your subscription any time in Settings.`;
    }

    // out of trial
    return `You have successfully updated your Komi subscription. A receipt will be sent to your email. You will not be charged until your current plan ends on ${nextBillingDate}. If you change your mind, you can cancel your subscription any time in Settings.`;
  }

  if (
    subscription.trial_end &&
    isAfter(new Date(subscription.trial_end), now)
  ) {
    return `You have successfully activated your Komi subscription. A receipt will be sent to your email. You will not be charged until your free trial expires on ${nextBillingDate}. If you change your mind, you can cancel your subscription any time in Settings.`;
  }

  // in the context of profile_builder, trial_end will always be set
  if (
    subscription.trial_end &&
    isBefore(new Date(subscription.trial_end), now)
  ) {
    return `You have successfully activated your Komi subscription. A receipt will be sent to your email. If you change your mind, you can cancel your subscription any time in Settings.`;
  }

  return null;
}
