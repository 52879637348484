import { Action, handleActions } from "redux-actions";
import {
  createPurchasingOptionsActions,
  createPurchasingOptionsTypes,
  getPurchasingOptionsActions,
  getPurchasingOptionsByIdActions,
  getPurchasingOptionsByIdTypes,
  getPurchasingOptionsTypes,
  updatePurchasingOptionsActions,
  updatePurchasingOptionsTypes,
} from "./actions";
import { PurchasingOptionsState } from "./types";

const handlers = {
  // create purchasing options
  [createPurchasingOptionsActions.REQUEST]: (
    state: PurchasingOptionsState
  ): PurchasingOptionsState => ({
    ...state,
    purchasingOptionData: null,
    error: null,
    loading: true,
    type: createPurchasingOptionsTypes.REQUEST,
  }),
  [createPurchasingOptionsActions.SUCCESS]: (
    state: PurchasingOptionsState,
    { payload }: Action<any>
  ): PurchasingOptionsState => ({
    ...state,
    purchasingOptionData: payload,
    loading: false,
    type: createPurchasingOptionsTypes.SUCCESS,
  }),
  [createPurchasingOptionsActions.FAILURE]: (
    state: PurchasingOptionsState,
    { payload }: Action<any>
  ): PurchasingOptionsState => ({
    ...state,
    error: payload,
    loading: false,
    type: createPurchasingOptionsTypes.FAILURE,
  }),

  // get purchasing options
  [getPurchasingOptionsActions.REQUEST]: (
    state: PurchasingOptionsState
  ): PurchasingOptionsState => ({
    ...state,
    purchasingOptions: null,
    error: null,
    loading: true,
    type: getPurchasingOptionsTypes.REQUEST,
  }),
  [getPurchasingOptionsActions.SUCCESS]: (
    state: PurchasingOptionsState,
    { payload }: Action<any>
  ): PurchasingOptionsState => ({
    ...state,
    purchasingOptions: payload,
    loading: false,
    type: getPurchasingOptionsTypes.SUCCESS,
  }),
  [getPurchasingOptionsActions.FAILURE]: (
    state: PurchasingOptionsState,
    { payload }: Action<any>
  ): PurchasingOptionsState => ({
    ...state,
    error: payload,
    loading: false,
    type: getPurchasingOptionsTypes.FAILURE,
  }),

  // update purchasing options
  [updatePurchasingOptionsActions.REQUEST]: (
    state: PurchasingOptionsState
  ): PurchasingOptionsState => ({
    ...state,
    purchasingOptionData: null,
    error: null,
    type: updatePurchasingOptionsTypes.REQUEST,
  }),
  [updatePurchasingOptionsActions.SUCCESS]: (
    state: PurchasingOptionsState,
    { payload }: Action<any>
  ): PurchasingOptionsState => ({
    ...state,
    purchasingOptionData: payload,
    type: updatePurchasingOptionsTypes.SUCCESS,
  }),
  [updatePurchasingOptionsActions.FAILURE]: (
    state: PurchasingOptionsState,
    { payload }: Action<any>
  ): PurchasingOptionsState => ({
    ...state,
    error: payload,
    type: updatePurchasingOptionsTypes.FAILURE,
  }),

  // get purchasing options by id
  [getPurchasingOptionsByIdActions.REQUEST]: (
    state: PurchasingOptionsState
  ): PurchasingOptionsState => ({
    ...state,
    purchasingOptionData: null,
    error: null,
    loading: false,
    type: getPurchasingOptionsByIdActions.REQUEST,
  }),
  [getPurchasingOptionsByIdActions.SUCCESS]: (
    state: PurchasingOptionsState,
    { payload }: Action<any>
  ): PurchasingOptionsState => ({
    ...state,
    purchasingOptionData: payload,
    loading: false,
    type: getPurchasingOptionsByIdTypes.SUCCESS,
  }),
  [getPurchasingOptionsByIdActions.FAILURE]: (
    state: PurchasingOptionsState,
    { payload }: Action<any>
  ): PurchasingOptionsState => ({
    ...state,
    error: payload,
    loading: false,
    type: getPurchasingOptionsByIdTypes.FAILURE,
  }),
};

const initialState: PurchasingOptionsState = {
  error: null,
  loading: false,
  purchasingOptionData: null,
  purchasingOptions: null,
  type: null,
};

export const purchasingOptionsReducer = handleActions<PurchasingOptionsState>(
  handlers,
  initialState
);
