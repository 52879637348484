import { RoomToken } from "./../../redux/Room/types";
import {
  Booking,
  GetBookingRequest,
  GetBookingDetailRequest,
  RejectBookingRequest,
  CompleteBookingRequest,
  UpdateBookingAttachmentsRequest,
} from "redux/Booking/types";
import { Pagination, Response } from "../../redux/Common/types";
import { Api } from "../api";
import { User } from "redux/User/types";

export default abstract class BookingService {
  constructor(protected api: Api) {
    this.api = api;
  }
  abstract getBooking(
    payload: GetBookingRequest
  ): Promise<Response<Pagination<Booking[]>>>;

  abstract getBookingById(
    payload: GetBookingDetailRequest
  ): Promise<Response<Booking>>;

  abstract getRoomToken(bookingId: number): Promise<Response<RoomToken>>;

  abstract rejectBooking(
    payload: RejectBookingRequest
  ): Promise<Response<Booking>>;

  abstract completeBooking(
    payload: CompleteBookingRequest
  ): Promise<Response<Booking>>;

  abstract completeRoom(roomId: number): Promise<Response<Booking>>;

  abstract getRoomViewers(roomId: number): Promise<Response<User[]>>;

  abstract updateBookingAttachments(
    payload: UpdateBookingAttachmentsRequest
  ): Promise<Response<string[]>>;
}
