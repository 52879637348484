import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { availabilityRuleService } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import {
  createAvailabilityRuleActions,
  getAvailabilityRuleActions,
  updateAvailabilityRuleActions,
} from "./actions";
import { CreateAvailabilityRule, UpdateAvailabilityRule } from "./types";

function* createAvailabilityRule({ payload }: Action<CreateAvailabilityRule>) {
  const result: any = yield* call(
    availabilityRuleService.createAvailabilityRule,
    { ...payload }
  );

  if (result.ok) {
    yield* put(createAvailabilityRuleActions.SUCCESS(result.response));
  } else {
    yield* put(createAvailabilityRuleActions.FAILURE(result.message));
  }
}

function* updateAvailabilityRule({ payload }: Action<UpdateAvailabilityRule>) {
  const result: any = yield* call(
    availabilityRuleService.updateAvailabilityRule,
    { ...payload }
  );

  if (result.ok) {
    yield* put(updateAvailabilityRuleActions.SUCCESS(result.response));
  } else {
    yield* put(updateAvailabilityRuleActions.FAILURE(result.message));
  }
}

function* getAvailabilityRule() {
  const result: any = yield* call(availabilityRuleService.getAvailabilityRule);
  if (result.ok) {
    yield* put(getAvailabilityRuleActions.SUCCESS(result.response));
  } else {
    yield* put(getAvailabilityRuleActions.FAILURE(result.message));
  }
}

export default function* availabilitySagas(): SagaIterator {
  yield* takeLatest(
    createAvailabilityRuleActions.REQUEST,
    createAvailabilityRule
  );
  yield* takeLatest(
    updateAvailabilityRuleActions.REQUEST,
    updateAvailabilityRule
  );
  yield* takeLatest(getAvailabilityRuleActions.REQUEST, getAvailabilityRule);
}
