import { Icon } from "components/Icon";
import { Text } from "components/Typography";
import React, { ReactNode } from "react";
import { NoticeType } from "utils/notification";

interface Props {
  message: string | ReactNode;
  type?: NoticeType;
  onClose?: () => void;
}

const NotificationMessage: React.FC<Props> = ({ message, onClose }) => {
  return (
    <React.Fragment>
      <div className="notification-content">
        {typeof message === "string" ? (
          <Text className="d--block">{message}</Text>
        ) : (
          message
        )}
        <span className="notification-content__close" onClick={onClose}>
          <Icon name="close-outline" width={24} height={24} />
        </span>
      </div>
    </React.Fragment>
  );
};

export default NotificationMessage;
